import React, { useRef } from "react";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getLocalizedString } from "@icarius-localization/strings";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { TextField, Grid, Select, MenuItem, FormControl, InputLabel, Typography } from "@material-ui/core";
import { VisibilityIcon } from "@icarius-icons/";
import useAutomaticProcesses from "@icarius-common/automaticProcesses/useAutomaticProcesses";
import ResultGridDialog from "@icarius-common/automaticProcesses/components/resultGridDialog";
import PickTemplateDialog from "@icarius-pages/employeesProcesses/components/dialogs/pickTemplateDialog";
import SelectProcessDialog from "@icarius-common/automaticProcesses/components/selectProcessDialog";
import MyPeopleGridDialog from "./dialogs/myPeopleGridDialog";
import NumberItem from "./numberItem";
import useEntryPerConcept from "./useEntryPerConcept";
import CreateEditDialog from "./dialogs/createEditDialog";

const gridStyle = { height: 50, padding: "0px 10px" };

const EntryPerConcept = () => {
  const ownGridRef = useRef();

  const {
    entryStructures,
    quantityPerUsedEntryStructure,
    dateFormat,
    societyPeople,
    gridData,
    columnDefs,
    fields,
    locale,
    isLoading,
    state,
    handlers,
    colaborators,
    selects,
    concepts,
    conceptsTypes,
    months,
    decimals,
  } = useEntryPerConcept();

  const {
    isExecution,
    automaticProcessesIsLoading,
    automaticProcessesIsEnabled,
    processList,
    resultData,
    resultDialogIsOpen,
    pickTemplateDialogIsOpen,
    processSelectionDialogIsOpen,
    executeProcess,
    handleExecute,
    viewResult,
    handleViewResult,
    handleCloseProcessDialogSelection,
    handleCloseResultDialog,
    handleOpenPickTemplateDialog,
    handleClosePickTemplateDialog,
    handleGenerateDocumentsGivenTemplate,
  } = useAutomaticProcesses();

  const dispatch = useDispatch();

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handlers.handleGoBack}
      type={"goBack"}
    />
  )
  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const duplicateButton = (gridRef) => (
    <CustomIconButton
      title={"Replicar"}
      onClick={() => handlers.handleOpenMyPeopleGridDialog(gridRef)}
      type={"duplicate"}
    />
  )

  const structureButton = () => (
    <CustomIconButton
      title={"Exportar estructura de importación"}
      onClick={handlers.handleExportStructure}
      type={"download"}
    />
  )

  // Procesos automaticos
  const handleValidateAndExecute = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    const employeeCodes = selectedRows.map(item => item["Código de empleado"]);
    handleExecute(employeeCodes);
  }

  const executeItem = (gridRef) => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={`Calcular${processList.length === 1 ? `: ${processList[0].value}` : ' proceso'}`}
      onClick={() => handleValidateAndExecute(gridRef)}
      type={"execute"}
    />
  )

  const handleValidateAndViewResult = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    handleViewResult(selectedRows[0], selectedRows[0]["Código de empleado"]);
  }

  const viewResultItem = (gridRef) => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={"Ver resultado"}
      onClick={() => handleValidateAndViewResult(gridRef)}
    >
      <VisibilityIcon />
    </CustomIconButton>
  )
  // Fin Procesos automaticos

  const deleteItem = (gridRef) => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )
  let customItems = [];

  if (state.selectedEntryStructureCode !== "") {
    customItems = [goBackButton, createButton, modifyButton, duplicateButton, structureButton, executeItem, viewResultItem, deleteItem]
  }

  const usedEntryStructures = quantityPerUsedEntryStructure.map(structure => structure.code);
  return (
    <CommonPage
      title={`Haberes y descuentos - Por concepto`}
      isLoading={isLoading || automaticProcessesIsLoading}
      isNotGridPage
      menuItems={state.selectedEntryStructureCode === "" && [goBackButton]}
    >
      <>
        <Grid container item alignItems="center" xs={12} style={{ paddingLeft: 24, paddingBottom: 10 }}>
          <Grid container item alignItems="center" xs={12}>
            <Grid container item alignItems="center" style={gridStyle} xs={3} >
              <FormControl style={{ width: "100%" }}>
                <InputLabel>{"Estructura de ingreso"}</InputLabel>
                <Select
                  value={state.selectedEntryStructureCode}
                  onChange={(e) => handlers.handleChangeEntryStructure(e.target.value)}
                  margin={"none"}
                >
                  {
                    entryStructures && entryStructures.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.code}
                        disabled={usedEntryStructures.includes(item.code)}
                        value={item.code}>
                        {item.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" style={gridStyle} xs={3}>
              <TextField
                disabled
                fullWidth
                margin={"none"}
                label={"Periodicidad"}
                value={state.selectedEntryStructurePeriodicity}
                inputProps={{ maxLength: 8 }}
              />
            </Grid>
          </Grid>
        </Grid>
        {
          quantityPerUsedEntryStructure.length > 0 &&
          <Grid container item xs={12} style={{ margin: 10, marginTop: 0 }}>
            {
              quantityPerUsedEntryStructure.map((el, index) => (
                <NumberItem
                  key={index}
                  name={el.name}
                  value={el.cant}
                  selected={el.code === state.selectedEntryStructureCode}
                  onClick={() => handlers.handleChangeEntryStructure(el.code)}
                />
              ))
            }
          </Grid>
        }
        {
          state.selectedEntryStructureCode === "" &&
          <Grid container item xs={12} style={{ margin: "10px 0px" }}>
            <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
              <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
                {"Seleccione una estructura de ingreso"}
              </Typography>
            </Grid>
          </Grid>
        }
        {
          state.myPeopleGridDialogIsOpen && !isLoading &&
          <MyPeopleGridDialog
            open={state.myPeopleGridDialogIsOpen}
            data={colaborators}
            dateFormat={dateFormat}
            locale={locale}
            handleConfirm={handlers.handleSetUsers}
            handleClose={handlers.handleCloseMyPeopleGridDialog}
          />
        }
        {
          processSelectionDialogIsOpen &&
          <SelectProcessDialog
            open={processSelectionDialogIsOpen}
            title={isExecution ? "Selección de proceso a ejecutar" : "Selección de proceso a consultar"}
            processList={processList}
            handleClose={handleCloseProcessDialogSelection}
            handleExecute={isExecution ? executeProcess : viewResult}
          />
        }
        {
          resultDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
          <ResultGridDialog
            open={resultDialogIsOpen}
            templates={resultData.templates}
            process={resultData.process}
            employeeName={resultData.employee['Apellido y nombres']}
            handleClose={handleCloseResultDialog}
            handleOpenPickTemplateDialog={handleOpenPickTemplateDialog}
            handleGenerateDocumentsGivenTemplate={handleGenerateDocumentsGivenTemplate}
          />
        }
        {
          resultData?.templates?.length > 0 && pickTemplateDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
          <PickTemplateDialog
            open={pickTemplateDialogIsOpen}
            templates={resultData.templates}
            handleConfirm={handleGenerateDocumentsGivenTemplate}
            handleClose={handleClosePickTemplateDialog}
          />
        }
        {
          state.createEditDialogIsOpen &&
          <CreateEditDialog
            open={state.createEditDialogIsOpen}
            loading={isLoading}
            data={state.selectedDocument}
            handleClose={handlers.handleCloseCreateEditDialog}
            concepts={concepts}
            conceptsTypes={conceptsTypes}
            selects={selects}
            fields={fields}
            months={months}
            societyPeople={societyPeople}
            create={handlers.handleCreateEntryPerConcept}
            modify={handlers.handleModifyEntryPerConcept}
            decimals={decimals}
          />
        }
      </>
      {
        state.selectedEntryStructureCode !== "" &&
        <CommonPage
          title={""}
          menuItems={customItems}
          isLoading={isLoading || automaticProcessesIsLoading}
          gridTitle={"Ingreso por concepto"}
          rowData={gridData}
          ownColumnDef={columnDefs}
          dateFormat={dateFormat}
          hasExpand
          hasHelp
          codeFieldName={"internalCode"}
          ownGridRef={ownGridRef}
          handleRowClick={handlers.handleRowClick}
        />
      }
    </CommonPage>
  );
}

export default EntryPerConcept;
