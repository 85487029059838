import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createAssetsDiscountsEntryByInstallmentAction, modifyAssetsDiscountsEntryByInstallmentAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import NumberFormatCustom from './numberFormatCustom';
import moment from "moment";
import { openDialogAction } from "@icarius-common/dialog/actions";
import ConfirmPrepaymentDialog from "./confirmPrepaymentDialog";
import useAutomaticProcesses from "@icarius-common/automaticProcesses/useAutomaticProcesses";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ResultGridDialog from "@icarius-common/automaticProcesses/components/resultGridDialog";
import PickTemplateDialog from "@icarius-pages/employeesProcesses/components/dialogs/pickTemplateDialog";
import { VisibilityIcon } from "@icarius-icons/";
import SelectProcessDialog from "@icarius-common/automaticProcesses/components/selectProcessDialog";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {
  let { data } = props;
  const { isLoading, handleClose, selects, entryStructures, selectedEntryStructureCode, colaborators } = props;
  const dispatch = useDispatch();

  const {
    isExecution,
    automaticProcessesIsLoading,
    automaticProcessesIsEnabled,
    processList,
    resultData,
    resultDialogIsOpen,
    pickTemplateDialogIsOpen,
    processSelectionDialogIsOpen,
    executeProcess,
    handleExecute,
    viewResult,
    handleViewResult,
    handleCloseProcessDialogSelection,
    handleCloseResultDialog,
    handleOpenPickTemplateDialog,
    handleClosePickTemplateDialog,
    handleGenerateDocumentsGivenTemplate,
  } = useAutomaticProcesses();

  const create = (dataToSend) => {
    dispatch(createAssetsDiscountsEntryByInstallmentAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyAssetsDiscountsEntryByInstallmentAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const getEntryStructure = useCallback(() => {
    const entryStructure = entryStructures.find(entry => entry.code === selectedEntryStructureCode);
    return entryStructure || { periodicity: '', thirdPartyPayment: '' };
  }, [entryStructures, selectedEntryStructureCode])

  const gridRef = useRef();
  const {
    isCreate,
    formData,
    setFormValue,
    submit,
    setCalculatedFieldsValues,
    setPaymenthMethodValue,
    setFormValueLoanDatePeriod,
    setConcept,
  } = useHandleForm(data, create, modify, selectedEntryStructureCode, openValidationError, gridRef, Boolean(getEntryStructure().thirdPartyPayment));

  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [confirmPrepaymentDialogIsOpen, setConfirmPrepaymentDialogIsOpen] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [unpaidInstallmentsDialog, setUnpaidInstallmentsDialog] = useState([]);
  const [hasStarted, setHasStarted] = useState(false);

  const getColaboratorData = employeeCode => { return colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === employeeCode) }

  const selectDataSources = (name, data) => {
    switch (name) {
      case "Estado":
        return [{ key: "C", value: "Pagada" }, { key: "P", value: "Pendiente" }];
      case "Concepto condicional 1":
      case "Concepto condicional 2":
      case "Concepto condicional 3":
        return selects.concepts;
      case "Condicional cuota":
        return selects.installmentsConditions;
      default:
        return [];
    }
  }

  const showConfirmPostponeDialog = () => {
    dispatch(openDialogAction({
      msg: "¿Está seguro que desea posponer las cuotas del préstamo?",
      title: "Confirmación",
      onConfirm: postponeInstallments,
      acceptOnly: false
    }))
  }

  const calculateGridData = useCallback(gridRef => {
    if (isCreate) {
      if (
        formData["Concepto"] &&
        parseFloat(formData["Total del préstamo"]) > 0 &&
        parseInt(formData["Cantidad de cuotas"]) > 0 &&
        (getEntryStructure().periodicity === "CP" || (getEntryStructure().periodicity === "C" && formData["Período de inicio del descuento"])) &&
        parseInt(formData["Número de cuota de referencia"]) > 0
      ) {
        // Limpio la grilla e inserto estos
        const loanTotal = parseFloat(formData["Total del préstamo"]);

        const installments = parseInt(formData["Cantidad de cuotas"]);

        const gridReference = gridRef.current.refs.dataGrid._instance;
        if (gridReference) {

          const dataSource = gridReference.getDataSource();

          dataSource.store().totalCount().then((resp) => {
            const installmentsInTable = resp;
            let filter = gridReference.getCombinedFilter();

            dataSource
              .store()
              .load({ sort: dataSource.sort(), filter: filter ? filter : null })
              .done((allData) => {
                const paidInstallmentsValue = allData.reduce(function (acc, obj) {
                  return acc + parseFloat(obj["Valor cuota"].replace(/\./g, '').replace(/,/g, '.'))
                }, 0) || 0;

                if (installments > 0) {
                  let referenceSubstring = "";

                  if (allData.length > 0) {
                    referenceSubstring = allData[0]["Referencia"];
                    referenceSubstring = referenceSubstring.substring(0, referenceSubstring.lastIndexOf("Cuota")).trim();
                  }

                  if (
                    (allData.length > 0 && (loanTotal.toFixed(2) !== paidInstallmentsValue.toFixed(2)))
                    || installmentsInTable !== installments
                    || formData["Nombre del concepto"] !== referenceSubstring
                  ) {
                    setCalculatedFieldsValues(["0", loanTotal, "0", installments]);

                    const loanPerInstallment = loanTotal / installments;
                    const installmentReference = parseInt(formData["Número de cuota de referencia"]) || 1;

                    let rows = [];

                    for (let i = 0; i < installments; i++) {
                      let rowData = {};
                      rowData["Cuota"] = i + 1;

                      if (i === installments - 1) {
                        rowData["Valor cuota"] = formatNumberOrReturnUndefined(loanTotal - (loanPerInstallment.toFixed(3) * (installments - 1)), 0, 3);
                      } else {
                        rowData["Valor cuota"] = formatNumberOrReturnUndefined(loanPerInstallment, 0, 3);
                      }

                      rowData["Estado"] = "P";
                      rowData["Referencia"] = `${formData["Nombre del concepto"]} Cuota ${installmentReference + i}/${installmentReference + installments - 1}`;

                      if (getEntryStructure().periodicity === "C") {
                        rowData["Período de descuento"] = moment(formData["Período de inicio del descuento"]).add(i, 'M').format("YYYY/MM");
                      } else {
                        rowData["Concepto condicional 1"] = null;
                        rowData["Concepto condicional 2"] = null;
                        rowData["Concepto condicional 3"] = null;
                        rowData["Condicional cuota"] = null;
                      }

                      rows.push(rowData);
                    }
                    setGridData(rows);
                  }
                }
              });
          });
        }
      }
    } else {
      let rowData = [];

      if (data && data.detail && data.detail.length > 0) {
        rowData = data.detail.map(originalRow => {
          let el = { ...originalRow };

          if (el["Valor cuota"]) {
            el["Valor cuota"] = formatNumberOrReturnUndefined(parseFloat(el["Valor cuota"]), 0, 3)
          }

          return el
        })
      }
      setGridData(rowData)
    }
  }, [data, formData, getEntryStructure, isCreate, setCalculatedFieldsValues]);

  useEffect(() => {
    setShouldReloadGrid(true)
  }, [gridData]);

  useEffect(() => {
    if (!hasStarted && !isCreate && data && data.detail) {
      const gridReference = gridRef.current.refs.dataGrid._instance;
      getRows(gridReference).then(rows => {
        if (rows.length === 0) {
          calculateGridData(gridReference);
          setHasStarted(true);
        }
      })
    }
  }, [hasStarted, calculateGridData, isCreate, data]);

  const getRows = async gridReference => {
    return new Promise(function (resolve, reject) {
      const dataSource = gridReference.getDataSource();
      dataSource.store().totalCount().then(resp => {
        let filter = gridReference.getCombinedFilter();
        dataSource.store().load({ sort: dataSource.sort(), filter: filter ? filter : null })
          .done((allData) => {
            return resolve(allData);
          })
      });
    });
  }

  const openPrePaymentDialog = () => {
    getUnpaidInstallments().then(rows => {
      setUnpaidInstallmentsDialog(rows);
      if (rows) {
        setConfirmPrepaymentDialogIsOpen(true);
      }
    })
  }
  const prepayInstallments = async quantity => {
    const gridReference = gridRef.current.refs.dataGrid._instance;
    const dataSource = gridReference.getDataSource();
    getRows(gridReference).then(rows => {

      getUnpaidInstallments().then(pendingInstallments => {
        if (quantity <= pendingInstallments.length) {
          for (let i = 0; i < quantity; i++) {
            const index = pendingInstallments.length - i - 1;
            if (dataSource) {
              dataSource.store().update(
                pendingInstallments[index],
                {
                  ...pendingInstallments[index], "Estado": "C", "Referencia": `${pendingInstallments[index]["Referencia"]} (Prepagada)`
                }
              ).then(function () {
                dataSource.reload();
              })
            }
          }

          const pendingInstallmentsValue = rows.reduce(function (acc, obj) {
            return obj["Estado"] === "P" ? acc + parseFloat(obj["Valor cuota"].replace(/\./g, '').replace(/,/g, '.')) : acc
          }, 0) || 0;

          const paidInstallmentsValue = rows.reduce(function (acc, obj) {
            return obj["Estado"] === "C" ? acc + parseFloat(obj["Valor cuota"].replace(/\./g, '').replace(/,/g, '.')) : acc
          }, 0) || 0;

          const pendingInstallmentsQuantity = rows.reduce(function (acc, obj) {
            return obj["Estado"] === "P" ? acc + 1 : acc
          }, 0) || 0;

          const paidInstallmentsQuantity = rows.reduce(function (acc, obj) {
            return obj["Estado"] === "C" ? acc + 1 : acc
          }, 0) || 0;

          setCalculatedFieldsValues([paidInstallmentsValue, pendingInstallmentsValue, paidInstallmentsQuantity, pendingInstallmentsQuantity]);
        }

        setConfirmPrepaymentDialogIsOpen(false);
      })
    });
  }

  const modifyDataInDataGrid = async (e, name) => {
    const gridReference = gridRef.current.refs.dataGrid._instance;
    const dataSource = gridReference.getDataSource();
    getRows(gridReference).then(installments => {
      const value = e.length > 0 ? e.replace(/-/g, "") : "";

      if (name === "Período de inicio del descuento") {
        for (let i = 0; i < installments.length; i++) {
          if (dataSource) {
            dataSource.store().update(
              installments[i],
              {
                ...installments[i],
                "Período de descuento": moment(e).add(i, 'M').format("YYYY/MM")
              }
            ).then(function () {
              dataSource.reload();
            })
          }
        }
      } else if (name === "Número de cuota de referencia") {
        const installmentReference = parseInt(value) || 1;
        for (let i = 0; i < installments.length; i++) {
          if (dataSource) {
            dataSource.store().update(
              installments[i],
              {
                ...installments[i],
                "Referencia": `${formData["Nombre del concepto"]} Cuota ${installmentReference + i}/${installmentReference + installments.length - 1}`
              }
            ).then(function () {
              dataSource.reload();
            })
          }
        }
      }

      setFormValue(e, name);
    });
  }

  const getUnpaidInstallments = async () => {
    return new Promise(function (resolve, reject) {
      const gridReference = gridRef?.current?.refs?.dataGrid?._instance;
      if (gridReference) {
        getRows(gridReference).then(rows => {
          resolve(rows.filter(row => row["Estado"] === "P"))
        });
      }
    });
  }

  const setLoanDate = e => {
    const loanPeriod = moment(e).format("YYYY/MM");
    setFormValueLoanDatePeriod(e, loanPeriod);
  }

  const postponeInstallments = () => {
    const gridReference = gridRef.current.refs.dataGrid._instance;
    const dataSource = gridReference.getDataSource();
    getUnpaidInstallments().then(pendingInstallments => {
      for (let i = 0; i < pendingInstallments.length; i++) {
        if (dataSource) {
          dataSource.store().update(
            pendingInstallments[i],
            {
              ...pendingInstallments[i],
              "Período de descuento": moment(pendingInstallments[i]["Período de descuento"]).add(1, 'M').format("YYYY/MM")
            }
          ).then(function () {
            dataSource.reload();
          })
        }
      }
    })
  }

  const columnsData = useMemo(() => {
    let columns = [];

    // Aca arranco a poner lo que hace falta
    const entryStructure = getEntryStructure();

    columns.push({
      headerName: "Cuota",
      field: "Cuota",
      isObligatory: true,
      maxLength: 254,
      minLength: 0,
      isNumeric: true,
      validationUnrequired: true,
      disabled: true
    });
    columns.push({
      headerName: "Estado",
      field: "Estado",
      isObligatory: true,
      isSelect: true,
      disabled: true
    });

    columns.push({
      headerName: "Valor cuota",
      field: "Valor cuota",
      isObligatory: true,
      maxLength: 254,
      minLength: 0,
      isNumeric: true,
      validationUnrequired: true
    });

    columns.push({
      headerName: "Referencia",
      field: "Referencia",
      isObligatory: false,
      maxLength: 254,
      minLength: 0,
      disabled: true
    });

    if (entryStructure.periodicity === "C") {
      columns.push({
        headerName: "Período de descuento",
        field: "Período de descuento",
        isObligatory: true,
        maxLength: 254,
        minLength: 0,
        disabled: true
      });
    } else {
      columns.push({
        headerName: "Concepto condicional 1",
        field: "Concepto condicional 1",
        isObligatory: false,
        isSelect: true,
      })

      columns.push({
        headerName: "Concepto condicional 2",
        field: "Concepto condicional 2",
        isObligatory: false,
        isSelect: true,
      })

      columns.push({
        headerName: "Concepto condicional 3",
        field: "Concepto condicional 3",
        isObligatory: false,
        isSelect: true,
      })

      columns.push({
        headerName: "Condicional cuota",
        field: "Condicional cuota",
        isObligatory: false,
        isSelect: true,
      })
    }
    return columns;
  }, [getEntryStructure]);

  const handleValidateAndExecute = () => {
    handleExecute([formData["Código de empleado"]]);
  }

  const handleValidateAndViewResult = () => {
    handleViewResult({ "Código de empleado": formData["Código de empleado"] }, formData["Código de empleado"]);
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* codigo */}
            <TextField
              disabled={true}
              required
              fullWidth
              margin={"none"}
              label={"Código de empleado"}
              onChange={(e) => setFormValue(e.target.value, "Código de empleado")}
              value={formData["Código de empleado"]}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
            <TextField
              disabled={true}
              required
              fullWidth
              margin={"none"}
              label={"Apellido y nombres"}
              value={formData["Apellido y nombres"]}
              onChange={(e) => setFormValue(e.target.value, "Apellido y nombres")}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* Fecha de ejecucion específica */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                fullWidth
                disabled={!isCreate}
                label={'Fecha del préstamo'}
                cancelLabel={getLocalizedString("cancel")}
                okLabel={getLocalizedString("ok")}
                invalidDateMessage=''
                format="dd/MM/yyyy"
                required
                margin={'none'}
                value={formData['Fecha del préstamo']}
                onChange={(e) => setLoanDate(e)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-concepto`}>{"Concepto"}</InputLabel>
              <Select
                disabled={!isCreate}
                required
                value={formData["Concepto"]}
                labelId={`label-concepto`}
                id={`select-concepto`}
                onChange={(e) => setConcept(e.target.value, selects.conceptsAssociatedWithEntryStructure)}
                margin={"none"}
              >
                {
                  selects && selects.conceptsAssociatedWithEntryStructure && selects.conceptsAssociatedWithEntryStructure.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
            <TextField
              fullWidth
              disabled={!isCreate}
              required
              margin={"none"}
              label={"Nombre del concepto"}
              value={formData["Nombre del concepto"]}
              onChange={(e) => setFormValue(e.target.value, "Nombre del concepto")}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-moneda`}>{"Moneda"}</InputLabel>
              <Select
                disabled={!isCreate}
                required
                value={formData["Moneda"]}
                onChange={(e) => setFormValue(e.target.value, "Moneda")}
                margin={"none"}
                labelId={`label-moneda`}
                id={`select-moneda`}
              >
                {
                  selects && selects.codeRefs && selects.codeRefs.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
            <TextField
              fullWidth
              disabled={!isCreate}
              required
              margin={"none"}
              label={"Total del préstamo"}
              value={formData["Total del préstamo"]}
              onChange={(e) => setFormValue(e.target.value, "Total del préstamo")}
              InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
            <TextField
              fullWidth
              type='number'
              disabled={!isCreate}
              margin={"none"}
              required
              label={"Cantidad de cuotas"}
              value={formData["Cantidad de cuotas"]}
              onChange={(e) => setFormValue(e.target.value, "Cantidad de cuotas")}
              inputProps={{ min: "1", step: "1" }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-frecuencia-descuento`}>{"Frecuencia del descuento"}</InputLabel>
              <Select
                disabled
                required
                value={formData["Frecuencia del descuento"]}
                onChange={(e) => setFormValue(e.target.value, "Frecuencia del descuento")}
                margin={"none"}
                labelId={`label-frecuencia-descuento`}
                id={`select-frecuencia-descuento`}
              >
                {
                  selects && selects.periodicity && selects.periodicity.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
              <TextField
                fullWidth
                disabled={true}
                margin={"none"}
                required
                label={"Valor pagado"}
                value={formData["Valor pagado"]}
                onChange={(e) => setFormValue(e.target.value, "Valor pagado")}
                InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
              <TextField
                fullWidth
                disabled={true}
                required
                type='number'
                margin={"none"}
                label={"Cuotas pagadas"}
                value={formData["Cuotas pagadas"]}
                onChange={(e) => setFormValue(e.target.value, "Cuotas pagadas")}
                inputProps={{ min: "0", step: "1" }}
              />
            </Grid>
            {
              getEntryStructure().periodicity === "C" &&
              <Grid container item alignItems="center" xs={4} style={gridStyle}> {/* Fecha de ejecucion específica */}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker
                    fullWidth
                    disabled={!isCreate}
                    required
                    label={'Período de inicio del descuento'}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    invalidDateMessage=''
                    format="yyyy/MM"
                    margin={'none'}
                    value={formData['Período de inicio del descuento']}
                    views={["month", "year"]}
                    onChange={(e) => modifyDataInDataGrid(e, "Período de inicio del descuento")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
          </Grid>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
              <TextField
                fullWidth
                disabled={true}
                required
                margin={"none"}
                label={"Saldo pendiente"}
                value={formData["Saldo pendiente"]}
                InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
                onChange={(e) => setFormValue(e.target.value, "Saldo pendiente")}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
              <TextField
                fullWidth
                disabled={true}
                required
                type='number'
                margin={"none"}
                label={"Cuotas pendientes"}
                value={formData["Cuotas pendientes"]}
                onChange={(e) => setFormValue(e.target.value, "Cuotas pendientes")}
                inputProps={{ min: "0", step: "1" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
              <TextField
                fullWidth
                required
                type='number'
                margin={"none"}
                label={"Número de cuota de referencia"}
                value={formData["Número de cuota de referencia"]}
                onChange={(e) => modifyDataInDataGrid(e.target.value, "Número de cuota de referencia")}
                inputProps={{ min: "0", step: "1" }}
              />
            </Grid>
          </Grid>
          {getEntryStructure().thirdPartyPayment && <> <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-tercero`}>{"Tercero"}</InputLabel>
                <Select
                  disabled={!isCreate}
                  required
                  value={formData["Tercero"]}
                  onChange={(e) => setFormValue(e.target.value, "Tercero")}
                  margin={"none"}
                  labelId={`label-tercero`}
                  id={`select-tercero`}
                >
                  {
                    selects && selects.thirdParties && selects.thirdParties.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-forma-de-pago`}>{"Forma de pago"}</InputLabel>
                <Select
                  disabled={!isCreate}
                  required
                  value={formData["Forma de pago"]}
                  onChange={(e) => setPaymenthMethodValue(e.target.value)}
                  margin={"none"}
                  labelId={`label-forma-de-pago`}
                  id={`select-forma-de-pago`}
                >
                  {
                    selects && selects.paymentMethods && selects.paymentMethods.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-tipo-de-pago`}>{"Tipo de pago"}</InputLabel>
                <Select
                  disabled={!isCreate}
                  required
                  value={formData["Tipo de pago"]}
                  onChange={(e) => setFormValue(e.target.value, "Tipo de pago")}
                  margin={"none"}
                  labelId={`label-tipo-de-pago`}
                  id={`select-tipo-de-pago`}
                >
                  {
                    selects && selects.paymentTypes && selects.paymentTypes.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
            <Grid container item xs={12}>
              <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required={formData["Forma de pago"] === 'D'} id={`label-banco`}>{"Banco"}</InputLabel>
                  <Select
                    disabled={!isCreate || (isCreate && formData["Forma de pago"] !== 'D')}
                    value={formData["Banco"]}
                    onChange={(e) => setFormValue(e.target.value, "Banco")}
                    margin={"none"}
                    labelId={`label-banco`}
                    id={`select-banco`}
                  >
                    {
                      selects && selects.banks && selects.banks.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={8} style={gridStyle}> {/* nombre */}
                <TextField
                  disabled={!isCreate || (isCreate && formData["Forma de pago"] !== 'D')}
                  required={formData["Forma de pago"] === 'D'}
                  fullWidth
                  margin={"none"}
                  label={"Cuenta bancaria"}
                  value={formData["Cuenta bancaria"]}
                  onChange={(e) => setFormValue(e.target.value, "Cuenta bancaria")}
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* nombre */}
                <TextField
                  disabled={!isCreate}
                  fullWidth
                  margin={"none"}
                  label={"Comentarios del pago al tercero"}
                  value={formData["Comentarios del pago al tercero"]}
                  onChange={(e) => setFormValue(e.target.value, "Comentarios del pago al tercero")}
                  inputProps={{ maxLength: 60 }}
                  multiline={true}
                  rows={1}
                  rowsMax={3}
                />
              </Grid>
            </Grid>
          </>}
          <Grid container item justify="flex-end" alignItems="center" xs={12} style={{ marginTop: 10 }}>
            {!isCreate && <div>
              <ButtonDialogAction hasMarginRight isAccept onClick={openPrePaymentDialog} text={"Prepagar"} />
            </div>}
            {!isCreate && getEntryStructure().periodicity === "C" && <div
              style={{ marginLeft: 10 }}>
              <ButtonDialogAction isAccept onClick={showConfirmPostponeDialog} text={"Postergar"} />
            </div>}
            {isCreate && <div
              style={{ marginLeft: 10 }}>
              <ButtonDialogAction isAccept onClick={() => calculateGridData(gridRef)} text={"Generar cuotas"} />
            </div>}
          </Grid>
          <Grid container item xs={12}>
            <EditableGridv2
              nestedGrid
              batchMode={true}
              selectDataSources={selectDataSources}
              setShouldReloadGrid={setShouldReloadGrid}
              shouldReloadGrid={shouldReloadGrid}
              ref={gridRef}
              gridName={"entryByInstallments"}
              rowData={gridData}
              columns={columnsData}
              getColaboratorData={getColaboratorData}
            />
          </Grid>
        </Grid>
        {
          !isCreate && automaticProcessesIsEnabled &&
          <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 10 }}>
            <Grid item style={{ paddingRight: 10 }}>
              <CustomIconButton
                title={`Calcular${processList.length === 1 ? `: ${processList[0].value}` : ' proceso'}`}
                onClick={handleValidateAndExecute}
                type={"execute"}
              />
            </Grid>
            <Grid item>
              <CustomIconButton
                title={"Ver resultado"}
                onClick={handleValidateAndViewResult}
              >
                <VisibilityIcon />
              </CustomIconButton>
            </Grid>
          </Grid>
        }
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
      {
        confirmPrepaymentDialogIsOpen && <ConfirmPrepaymentDialog
          handleConfirm={prepayInstallments}
          open={confirmPrepaymentDialogIsOpen}
          availableInstallments={unpaidInstallmentsDialog}
          handleClose={() => setConfirmPrepaymentDialogIsOpen(false)}
        />
      }
      {
        processSelectionDialogIsOpen &&
        <SelectProcessDialog
          open={processSelectionDialogIsOpen}
          title={isExecution ? "Selección de proceso a ejecutar" : "Selección de proceso a consultar"}
          processList={processList}
          handleClose={handleCloseProcessDialogSelection}
          handleExecute={isExecution ? executeProcess : viewResult}
        />
      }
      {
        resultDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
        <ResultGridDialog
          open={resultDialogIsOpen}
          templates={resultData.templates}
          process={resultData.process}
          employeeName={formData['Apellido y nombres']}
          handleClose={handleCloseResultDialog}
          handleOpenPickTemplateDialog={handleOpenPickTemplateDialog}
          handleGenerateDocumentsGivenTemplate={handleGenerateDocumentsGivenTemplate}
        />
      }
      {
        resultData?.templates?.length > 0 && pickTemplateDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
        <PickTemplateDialog
          open={pickTemplateDialogIsOpen}
          templates={resultData.templates}
          handleConfirm={handleGenerateDocumentsGivenTemplate}
          handleClose={handleClosePickTemplateDialog}
        />
      }
    </>
  );
}

export default Form;
