import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";
import { deleteAssetsDiscountsEntryByInstallmentAction } from "../../actions";
import Form from "./form";

const DataItem = (props) => {

  const {
    isLoading,
    data,
    color,
    closeNew,
    selects,
    entryStructures,
    selectedEntryStructureCode,
    colaborators,
  } = props;

  const dispatch = useDispatch();

  const isCreate = !Boolean(data["Total del préstamo"]);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      const { detail } = data;
      if (data["Cuotas pendientes"] !== 0 && detail?.length > 0 && detail.find((item) => item["Estado"] !== "P")) {
        dispatch(openSnackbarAction({
          msg: "No es posible eliminar el registro porque ya existen cuotas pagadas en el detalle de cuotas. Solo se permite prepagarlo.",
          severity: "error",
          duration: null,
        }));
      } else {
        dispatch(deleteAssetsDiscountsEntryByInstallmentAction({ internalCode: data["Código interno del registro"], entryStructure: selectedEntryStructureCode }));
      }
    }
  }

  const handleClosePublication = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: 'Eliminar préstamo',
      msg: '¿Desea eliminar el préstamo?',
      onConfirm: handleDeletePublication,
    }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
            <>
              <DataContainer sm={12} lg={2} md={2} text={data["Código de empleado"]} />
              <DataContainer sm={12} lg={4} md={2} text={data["Apellido y nombres"]} />
              <DataContainer sm={12} lg={4} md={4} text={`Saldo: ${formatNumberOrReturnUndefined(data["Saldo pendiente"] || 0, 2, 2)}`} />
              <DataContainer sm={12} lg={2} md={2} text={`Cuotas pendientes: ${data["Cuotas pendientes"] || 0}`} />
            </>
          </Grid>
          <Grid container direction="row" item xs={1} justify="center">
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          data={data}
          selects={selects}
          handleClose={isCreate ? closeNew : handleClosePublication}
          entryStructures={entryStructures}
          selectedEntryStructureCode={selectedEntryStructureCode}
          colaborators={colaborators}
          isCreate={isCreate}
          isLoading={isLoading}
        />
      }
    </>
  );
}

export default DataItem;
