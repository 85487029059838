import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET_PAYROLL = NAME + "/GET_PAYROLL";
export const GET_PAYROLL_REJECTED = NAME + "/GET_PAYROLL_REJECTED";
export const GET_PAYROLL_FULFILLED = NAME + "/GET_PAYROLL_FULFILLED";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const DELETE = NAME + "/DELETE";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";

export const DUPLICATE = NAME + "/DUPLICATE";
export const DUPLICATE_REJECTED = NAME + "/DUPLICATE_REJECTED";
export const DUPLICATE_FULFILLED = NAME + "/DUPLICATE_FULFILLED";

export const MODIFY = NAME + "/MODIFY";
export const MODIFY_REJECTED = NAME + "/MODIFY_REJECTED";
export const MODIFY_FULFILLED = NAME + "/MODIFY_FULFILLED";

export const ORDER = NAME + "/ORDER";
export const ORDER_REJECTED = NAME + "/ORDER_REJECTED";
export const ORDER_FULFILLED = NAME + "/ORDER_FULFILLED";