import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getAssetDiscountsBasicElementsConceptSetAPI,
    getAssetDiscountsGivenConceptSetAPI,
    setAssetDiscountsEntryPerConceptsSetAPI,
    downloadImportStructureApi,
} from "@icarius-connection/api";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getAssetDiscountsBasicElementsConceptSetFromServer = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_BASIC_ELEMENTS });
    try {
        let response = await getAssetDiscountsBasicElementsConceptSetAPI();
        const {
            conceptSets,
            societyPeople,
            colaborators,
            currency_localization,
            date_format,
            informedValueTypeTypes,
            columnTypesConceptSet,
            quantityPerUsedConceptSet
        } = response.data;

        dispatch({
            type: actionTypes.GET_BASIC_ELEMENTS_FULFILLED,
            payload: {
                conceptSets,
                societyPeople,
                colaborators,
                currency_localization,
                date_format,
                informedValueTypeTypes,
                columnTypesConceptSet,
                quantityPerUsedConceptSet
            },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_BASIC_ELEMENTS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const getAssetDiscountsGivenConceptSetFromServer = (code) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await getAssetDiscountsGivenConceptSetAPI(`?conceptSet=${code}`);
        const { result, associatedConcepts, quantityPerUsedConceptSet } = response.data;
        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows: result, associatedConcepts, quantityPerUsedConceptSet },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const setAssetDiscountsEntryPerConceptsSetToServer = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await setAssetDiscountsEntryPerConceptsSetAPI(data);
        const { result, associatedConcepts, quantityPerUsedConceptSet } = response.data;
        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows: result, associatedConcepts, quantityPerUsedConceptSet },
        });
        dispatch(openSnackbarAction({ msg: 'Valores actualizados con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo Tipo, Antiguedad Desde y Antiguedad Hasta", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const downloadImportStructureAction = (dataToSend) => async (dispatch) => {
    dispatch({ type: actionTypes.DOWNLOAD });
    try {
        let response = await downloadImportStructureApi(dataToSend);
        let title = response.headers["x-content-namefile"];

        if (response.status !== 200) {
            dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.DOWNLOAD_FULFILLED,
        });

        downloadBlob(response.data, getFileExtension(title), getFileName(title));

        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};
