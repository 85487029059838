import React, { useEffect } from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import { getQuantityPerScreenAction } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { RESET_STATE } from "../actionTypes";
import { getQuantityPerScreen } from "../selectors";
import {
  AssetsAndDiscountsCreationIcon,
  AssetsAndDiscountsEntryPerConceptIcon,
  AssetsAndDiscountsEntryPerConceptSetIcon,
  AssetsAndDiscountsSummaryIcon,
  AssetsAndDiscountsEntryPerSocietyIcon,
  AssetsAndDiscountsEntryInInstallmentsIcon,
  MassEntryIcon,
  ImportIcon,
  DataCaptureIcon
} from "@icarius-icons/";

const AssetsAndDiscountsScreenSelector = () => {

  const dispatch = useDispatch();
  const quantityPerScreen = useSelector(getQuantityPerScreen)

  useEffect(() => {
    dispatch(getQuantityPerScreenAction());

    return () => {
      dispatch({ type: RESET_STATE });
    }
  }, [dispatch]);

  const data = [
    {
      name: "Ingreso por concepto",
      icon: <AssetsAndDiscountsEntryPerConceptIcon />,
      path: paths.entryPerConcept,
      amount: quantityPerScreen["Ingreso por concepto"]
    },
    {
      name: "Ingreso por conjunto de conceptos",
      icon: <AssetsAndDiscountsEntryPerConceptSetIcon />,
      path: paths.entryPerConceptSet,
      amount: quantityPerScreen["Ingreso por conjunto de conceptos"],
    },
    {
      name: "Ingreso por sociedad",
      icon: <AssetsAndDiscountsEntryPerSocietyIcon />,
      path: paths.entryPerSociety,
      amount: quantityPerScreen["Ingreso por sociedad"],
    },
    {
      name: "Ingreso en cuotas",
      icon: <AssetsAndDiscountsEntryInInstallmentsIcon />,
      path: paths.entryInInstallments,
      amount: quantityPerScreen["Ingreso en cuotas"],
    },

    {
      name: "Ingreso masivo",
      icon: <MassEntryIcon />,
      path: paths.massEntry,
      amount: quantityPerScreen["Masivo"],
    },
    {
      name: "Ingreso via importación",
      icon: <ImportIcon />,
      path: paths.entryByImport,
    },
    {
      name: "Ingreso de datos via API",
      icon: <DataCaptureIcon />,
      path: paths.dataCapture,
      amount: quantityPerScreen["Vía captura desde otro sistema (API)"],
    },
    {
      name: "Resumen de haberes y descuentos",
      icon: <AssetsAndDiscountsSummaryIcon />,
      path: paths.assetsDiscountsSummary,
      amount: quantityPerScreen["Visualización de resumen"],
    },
    {
      name: "Creación de haberes y descuentos",
      icon: <AssetsAndDiscountsCreationIcon />,
      path: paths.createAssetsDiscounts,
      amount: quantityPerScreen["Creación de haberes y descuentos"],
    },
  ];

  return (
    <ScreenSelector
      title={'Haberes y descuentos'}
      data={data}
    />
  );
}

export default AssetsAndDiscountsScreenSelector;
