import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
    numberFormatterMin5,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: 'agSetColumnFilter',
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Apellido y nombres',
            field: 'Apellido y nombres',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Número interno',
            field: 'Número interno',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Fecha de contratación',
            field: 'Fecha de contratación',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Fecha de egreso',
            field: 'Fecha de egreso',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Cargo',
            field: 'Cargo',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Función',
            field: 'Función',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Tipo de funcionario',
            field: 'Tipo de funcionario',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Lugar de trabajo',
            field: 'Lugar de trabajo',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Obra o Faena',
            field: 'Obra o Faena',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Categoría',
            field: 'Categoría',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Especialidad',
            field: 'Especialidad',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Sexo',
            field: 'Sexo',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Nacionalidad',
            field: 'Nacionalidad',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Tipo de contrato',
            field: 'Tipo de contrato',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Gerencia',
            field: 'Gerencia',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Departamento',
            field: 'Departamento',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Sindicato',
            field: 'Sindicato',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Tipo de ingreso',
            field: 'Tipo de ingreso',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle2',
            headerClass: 'color2',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Tipo de estructura o Tipo de conjunto',
            field: 'Tipo de estructura o Tipo de conjunto',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle2',
            headerClass: 'color2',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Modalidad de ingreso',
            field: 'Modalidad de ingreso',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle2',
            headerClass: 'color2',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Origen',
            field: 'Origen',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle2',
            headerClass: 'color2',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Concepto',
            field: 'Concepto',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle2',
            headerClass: 'color2',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Nombre del concepto',
            field: 'Nombre del concepto',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle2',
            headerClass: 'color2',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Periodicidad',
            field: 'Periodicidad',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle2',
            headerClass: 'color2',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Valor informado bruto',
            field: 'Valor informado bruto',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: ["currencyValue", 'assetsDiscountsSummaryStyle3'],
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerClass: 'color3',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Valor informado líquido',
            field: 'Valor informado líquido',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: ["currencyValue", 'assetsDiscountsSummaryStyle3'],
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerClass: 'color3',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Horas y Minutos',
            field: 'Horas y Minutos',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle3',
            headerClass: 'color3',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Cantidad',
            field: 'Cantidad',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: ["currencyValue", 'assetsDiscountsSummaryStyle3'],
            valueFormatter: numberFormatterMin5,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerClass: 'color3',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Porcentaje',
            field: 'Porcentaje',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: ["currencyValue", 'assetsDiscountsSummaryStyle3'],
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerClass: 'color3',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Base de cálculo',
            field: 'Base de cálculo',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: ["currencyValue", 'assetsDiscountsSummaryStyle3'],
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerClass: 'color3',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'En fecha',
            field: 'En fecha',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: 'assetsDiscountsSummaryStyle5',
            headerClass: 'color45',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Período desde',
            field: 'Período desde',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle4',
            headerClass: 'color4',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Período hasta',
            field: 'Período hasta',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle4',
            headerClass: 'color4',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Fecha de descuento',
            field: 'Fecha de descuento',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: 'assetsDiscountsSummaryStyle5',
            headerClass: 'color5',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Período de reliquidación',
            field: 'Período de reliquidación',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle6',
            headerClass: 'color6',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Meses a reliquidar',
            field: 'Meses a reliquidar',
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: ["currencyValue", 'assetsDiscountsSummaryStyle6'],
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            headerClass: 'color6',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Código de referencia',
            field: 'Código de referencia',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle7',
            headerClass: 'color7',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Indicador de cálculo',
            field: 'Indicador de cálculo',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle8',
            headerClass: 'color8',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Tercero',
            field: 'Tercero',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle9',
            headerClass: 'color9',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Tipo de pago',
            field: 'Tipo de pago',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle9',
            headerClass: 'color9',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Forma de pago',
            field: 'Forma de pago',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle9',
            headerClass: 'color9',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Banco',
            field: 'Banco',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle9',
            headerClass: 'color9',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Cuenta bancaria',
            field: 'Cuenta bancaria',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle9',
            headerClass: 'color9',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Centro de costo',
            field: 'Centro de costo',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle10',
            headerClass: 'color10',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Proyecto',
            field: 'Proyecto',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle10',
            headerClass: 'color10',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Período de imputación',
            field: 'Período de imputación',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle11',
            headerClass: 'color11',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Ene',
            field: 'Ene',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Feb',
            field: 'Feb',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Mar',
            field: 'Mar',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Abr',
            field: 'Abr',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'May',
            field: 'May',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Jun',
            field: 'Jun',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Jul',
            field: 'Jul',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Ago',
            field: 'Ago',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Sep',
            field: 'Sep',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Oct',
            field: 'Oct',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Nov',
            field: 'Nov',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Dic',
            field: 'Dic',
            filter: 'agSetColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle12',
            headerClass: 'color12',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Serie',
            field: 'Serie',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle13',
            headerClass: 'color13',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Número de comprobante',
            field: 'Número de comprobante',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle13',
            headerClass: 'color13',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Usuario de ingreso',
            field: 'Usuario de ingreso',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle14',
            headerClass: 'color14',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Fecha de ingreso o modificación',
            field: 'Fecha de ingreso o modificación',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: 'assetsDiscountsSummaryStyle14',
            headerClass: 'color14',
        }
    },
    {
        pages: [paths.assetsDiscountsSummary],
        config: {
            headerName: 'Comentarios',
            field: 'Comentarios',
            filter: 'agTextColumnFilter',
            cellClass: 'assetsDiscountsSummaryStyle14',
            headerClass: 'color14',
        }
    },
]