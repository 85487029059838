import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import Form from "../data/form";

const DuplicateDialog = (props) => {

  const { open, data, color, payrollSelected, handleCloseForm, handleClose } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"md"}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Duplicar concepto"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Form
          isDuplicate
          color={color}
          data={data}
          payrollSelected={payrollSelected}
          handleClose={handleCloseForm}
          handleCloseDuplicate={handleClose}
        />
      </DialogContent>      
    </Dialog>
  );
}

export default DuplicateDialog;
