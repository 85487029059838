import NAME from "./constants";

export const getConceptSets = store => store[NAME].conceptSets;
export const getSocietyPeople = store => store[NAME].societyPeople;
export const getColaborators = store => store[NAME].colaborators;
export const getDateFormat = store => store[NAME].dateFormat;
export const getCurrencyLocalization = store => store[NAME].currencyLocalization;
export const getLoading = store => store[NAME].loading || store[NAME].loadingBasicElements;
export const getColumnTypesConceptSet = store => store[NAME].columnTypesConceptSet;
export const getInformedValueTypeTypes = store => store[NAME].informedValueTypeTypes;
export const getRows = store => store[NAME].rows;
export const getAssociatedConcepts = store => store[NAME].associatedConcepts;
export const getQuantityPerUsedConceptSet = store => store[NAME].quantityPerUsedConceptSet;