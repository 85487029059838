import React, { useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getIsLoadingFileUpload } from "@icarius-pages/systemAndUserTables/selectors";
import Loader from "@icarius-common/loader";
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormatCustom from "./numberFormatCustom";
import NumberFormatCustomFiveDecimal from "./numberFormatCustomFiveDecimal";
import NumberFormatCustomFourDecimal from "./numberFormatCustomFourDecimal";
import NumberFormatCustomZeroDecimal from "./numberFormatCustomZeroDecimal";
import NumberFormatCustomThreeDecimal from "./numberFormatCustomThreeDecimal";
import NumberFormatCustomOneDecimal from "./numberFormatCustomOneDecimal";

const gridStyle = { padding: "0px 10px", height: 75 };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const HelperTextComponent = ({ children }) => {
  return (
    <Grid container item xs={12} style={{ minHeight: 20 }}>
      <Typography className="whiteText" variant="caption">
        {children}
      </Typography>
    </Grid>
  )
}

const CreateEditDialog = (props) => {

  const {
    open,
    fields,
    isLoading,
    data,
    handleClose,
    create,
    modify,
    selects,
    concepts,
    conceptsTypes,
    months,
    societyPeople,
    decimals,
  } = props;

  const colaborators = useMemo(() => {
    return societyPeople.map(el => ({ key: el.key, value: el.name }))
  }, [societyPeople]);

  const dispatch = useDispatch();
  const isLoadingFileUpload = useSelector(getIsLoadingFileUpload);

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, fields, create, modify, openValidationError, months, selects, concepts, conceptsTypes, societyPeople, colaborators, decimals);


  const getDialogActions = () => {
    const cancelAcceptButtons = (
      <>
        <ButtonDialogAction onClick={handleClose} text={'Cancelar'} />
        <ButtonDialogAction onClick={submit} isAccept text={'Aceptar'} />
      </>
    );

    return cancelAcceptButtons;
  }

  const getComponent = (field) => {
    const getOptions = () => {
      if (months.includes(field.headerName)) {
        return [{ key: "N", value: "No" }, { key: "Y", value: "Si" }]
      }
      switch (field.headerName) {
        case "Meses a reliquidar":
          const mesesReliquidacion = [];
          for (let i = 1; i <= 36; i++) {
            mesesReliquidacion.push({ key: `${i}`, value: `${i}` })
          }
          return mesesReliquidacion;
        case "Tipo de concepto":
          return conceptsTypes;
        case "Indicador de cálculo":
          return selects.calculationIndicators;
        case "Código de referencia":
          return selects.codeRefs;
        case "Proyecto":
          return selects.projects;
        case "Banco":
          return selects.banks;
        case "Forma de pago":
          return selects.paymentMethods;
        case "Tercero":
          return selects.thirdParties;
        case "Tipo de pago":
          return selects.paymentTypes;
        case "Centro de costo":
          return selects.benefitCenters;
        case "Concepto":
          const element = conceptsTypes.find(el => el.key === formData["Tipo de concepto"]);
          if (element && element.value) {
            return concepts[element.value];
          }
          return [];
        case "all-concepts":
          let testArr = []
          conceptsTypes.forEach(el => testArr.push(...concepts[el.value].map(concept => ({ ...concept, "Tipo de concepto": el.key }))));
          return testArr;
        default:
          return [];
      }
    }

    const getLabel = () => {
      return field.headerName;
    }

    const getIsDisabled = () => {
      if (field.headerName === 'Banco' || field.headerName === "Cuenta bancaria") {
        const paymentTypeIsDeposit = formData["Forma de pago"] === "D";
        const element = selects.paymentMethods.find(el => el.key === "D");
        if (paymentTypeIsDeposit && element && element.requiresBank === "Y") {
          return false;
        } else {
          return true;
        }
      }

      if (!isCreate && field.headerName === 'Apellido y nombres') return true;
      return false;
    }

    // "A": "Autocomplete"
    // "E": "Edit alfanumérico"
    // "L": "Lista desplegable"
    // "D": "Fecha con picker"
    // "T": "Hora con picker"
    // "P": "Período (YYYY/MM) con picker"
    // "N": "Edit numérico"
    // "N2": "Edit numérico con 2 decimales"
    // "N5": "Edit numérico con 5 decimales"
    switch (field.component) {
      case 'A': return (
        <>
          <Autocomplete
            disabled={!isCreate}
            style={{ width: '100%' }}
            options={colaborators}
            value={formData[field.field]}
            onChange={(event, newUser) => {
              setFormValue(newUser, field.field);
            }}
            noOptionsText={''}
            getOptionLabel={(user) => user.value}
            renderInput={(params) => <TextField required {...params} label={'Colaborador'} />}
          />
        </>
      );
      case 'E': return (
        <>
          <TextField
            required={field.isObligatory}
            disabled={getIsDisabled()}
            label={getLabel()}
            onChange={(e) => setFormValue(e.target.value, field.field)}
            value={formData[field.field]}
            inputProps={{ maxLength: field.size }}
            fullWidth
            margin={"none"}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case 'L':
        return (
          <>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required={field.isObligatory} id={`label-${field.field}`}>{getLabel()}</InputLabel>
              <Select
                disabled={getIsDisabled()}
                value={formData[field.field]}
                labelId={`label-${field.field}`}
                id={`select-${field.field}`}
                onChange={(e) => setFormValue(e.target.value, field.field)}
                margin={"none"}
              >
                {
                  getOptions().map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </>
        );
      case 'D': return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            disabled={getIsDisabled()}
            required={field.isObligatory}
            label={getLabel()}
            value={formData[field.field]}
            onChange={(e) => setFormValue(e, field.field)}
            fullWidth
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            format="dd/MM/yyyy"
            margin="none"
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case 'DS': return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            disabled={getIsDisabled()}
            required={field.isObligatory}
            label={getLabel()}
            value={formData[field.field]}
            onChange={(e) => setFormValue(e, field.field)}
            fullWidth
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            format="dd/MM/yyyy"
            margin="none"
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case 'T': return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <TimePicker
            disabled={getIsDisabled()}
            required={field.isObligatory}
            label={getLabel()}
            value={formData[field.field]}
            onChange={(e) => setFormValue(e, field.field)}
            minutesStep={1}
            fullWidth
            clearable
            clearLabel={getLocalizedString("clear")}
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            format="HH:mm"
            margin="none"
            ampm={false}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case 'P': return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            disabled={getIsDisabled()}
            required={field.isObligatory}
            label={getLabel()}
            value={formData[field.field]}
            onChange={(e) => setFormValue(e, field.field)}
            fullWidth
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            views={["month", "year"]}
            format="yyyy/MM"
            margin="none"
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case 'N0': return (
        <>
          <TextField
            required={field.isObligatory}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.field]}
            fullWidth
            margin={"none"}
            onChange={(e) => setFormValue(e.target.value, field.field)}
            InputProps={{ inputComponent: NumberFormatCustomZeroDecimal, min: "1", step: "1" }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case 'N1': return (
        <>
          <TextField
            required={field.isObligatory}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.field]}
            fullWidth
            margin={"none"}
            onChange={(e) => setFormValue(e.target.value, field.field)}
            InputProps={{ inputComponent: NumberFormatCustomOneDecimal, min: "1", step: "0.1" }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case 'N2': return (
        <>
          <TextField
            required={field.isObligatory}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.field]}
            fullWidth
            margin={"none"}
            onChange={(e) => setFormValue(e.target.value, field.field)}
            InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.01" }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case 'N3': return (
        <>
          <TextField
            required={field.isObligatory}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.field]}
            fullWidth
            margin={"none"}
            onChange={(e) => setFormValue(e.target.value, field.field)}
            InputProps={{ inputComponent: NumberFormatCustomThreeDecimal, min: "1", step: "0.001" }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case 'N4': return (
        <>
          <TextField
            required={field.isObligatory}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.field]}
            fullWidth
            margin={"none"}
            onChange={(e) => setFormValue(e.target.value, field.field)}
            InputProps={{ inputComponent: NumberFormatCustomFourDecimal, min: "1", step: "0.0001" }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case 'N5': return (
        <>
          <TextField
            required={field.isObligatory}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.field]}
            fullWidth
            margin={"none"}
            onChange={(e) => setFormValue(e.target.value, field.field)}
            InputProps={{ inputComponent: NumberFormatCustomFiveDecimal, min: "1", step: "0.00001" }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      default: return <></>;
    }
  }

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear registro" : "Editar registro"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Loader open={isLoadingFileUpload} />
          <Grid container item xs={12} style={{ maxHeight: 500 }}>
            {
              fields.map((el) => {
                return (
                  <Grid key={el.field} item xs={12} sm={6} style={gridStyle}>
                    {
                      getComponent(el)
                    }
                  </Grid>
                )
              })
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          {getDialogActions()}
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(CreateEditDialog);