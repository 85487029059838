import { createDateFromDDMMYYYY, createDateFromYYYYMM } from "@icarius-utils/date";
import { useState } from "react";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import moment from "moment";

const useHandleForm = (data, createCallback, modifyCallback, entryStructureCode, invalidDataCallback, gridRef, hasThirdPartyPayment) => {
  const dispatch = useDispatch();

  const isCreate = !Boolean(data["Total del préstamo"]);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'Fecha del préstamo') {
        return (isCreate || !data[fieldName]) ? new Date() : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'Período de inicio del descuento') {
        return (isCreate || !data[fieldName]) ? new Date() : createDateFromYYYYMM(data[fieldName]);
      }

      return isCreate &&
        fieldName !== "Código de empleado" &&
        fieldName !== "Apellido y nombres" &&
        fieldName !== "Frecuencia del descuento" &&
        fieldName !== "Número de cuota de referencia" &&
        fieldName !== "Cuotas pagadas" &&
        fieldName !== "Valor pagado" &&
        fieldName !== "Cuotas pendientes" &&
        fieldName !== "Saldo pendiente"
        ? "" : data[fieldName];
    }

    const commonFieldNames = [
      "Código de empleado",
      "Apellido y nombres",
      "Concepto",
      "Nombre del concepto",
      "Fecha del préstamo",
      "Total del préstamo",
      "Moneda",
      "Cantidad de cuotas",
      "Frecuencia del descuento",
      "Período de inicio del descuento",
      "Número de cuota de referencia",
      "Valor pagado",
      "Saldo pendiente",
      "Cuotas pagadas",
      "Cuotas pendientes"
    ];

    const thirdPartyFieldNames = [
      "Tercero",
      "Forma de pago",
      "Tipo de pago",
      "Banco",
      "Cuenta bancaria",
      "Comentarios del pago al tercero"
    ];

    const fieldNames = hasThirdPartyPayment ? [...commonFieldNames, ...thirdPartyFieldNames] : commonFieldNames;

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    let commonFields = {
      "Código de empleado": yup.string().required(),
      "Apellido y nombres": yup.string().required(),
      "Concepto": yup.string().required(),
      "Nombre del concepto": yup.string().required(),
      "Fecha del préstamo": yup.string().required(),
      "Total del préstamo": yup.string().required(),
      "Moneda": yup.string().required(),
      "Cantidad de cuotas": yup.string().min(1).max(3).required(),
      "Frecuencia del descuento": yup.string().required(),
      "Período de inicio del descuento": yup.string().required(),
      "Número de cuota de referencia": yup.string().min(1).max(3).required(),
      "Valor pagado": yup.string().required(),
      "Saldo pendiente": yup.string().required(),
      "Cuotas pagadas": yup.string().required(),
      "Cuotas pendientes": yup.string().required(),
    };

    let thirdPartyFields = {
      "Tercero": yup.string().required(),
      "Forma de pago": yup.string().required(),
      "Tipo de pago": yup.string().required(),
      "Banco": yup.string().optional(),
      "Cuenta bancaria": yup.string().optional(),
      "Comentarios del pago al tercero": yup.string().optional(),
    };

    const fields = hasThirdPartyPayment ? { ...commonFields, ...thirdPartyFields } : commonFields;
    let schema = yup.object().shape(fields);
    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (isNaN(formData["Cantidad de cuotas"])) {
      dispatch(openSnackbarAction({ msg: "La cantidad de cuotas no es válida", severity: "error" }));
      return;
    }

    if (isNaN(formData["Número de cuota de referencia"])) {
      dispatch(openSnackbarAction({ msg: "El número de cuota de referencia no es válido", severity: "error" }));
      return;
    }

    const gridReference = gridRef.current.refs.dataGrid._instance;

    let ds = gridReference.getDataSource();
    let filter = gridReference.getCombinedFilter();

    gridReference.saveEditData().done(resp => {
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          if (formData["Forma de pago"] === "D" && (formData["Banco"] === "" || formData["Cuenta bancaria"] === "")) {
            dispatch(openSnackbarAction({ msg: "Los campos Banco y Cuenta bancaria no pueden estar vacios", severity: "error" }));
            return;
          }
          if (allData.length === 0) {
            dispatch(openSnackbarAction({ msg: 'La tabla se encuentra vacía. En caso de haber llenado los datos de cabecera, presione "Generar cuotas"', severity: "error" }));
            return;
          }
          gridReference.getController("validating").validate(true).then(async isValid => {
            if (isValid) {
              // Me tengo que fijar si esta bien la sumatoria
              const pendingInstallmentsValue = allData.reduce(function (acc, obj) {
                return obj["Estado"] === "P" ? acc + parseFloat(obj["Valor cuota"].replace(/\./g, '').replace(/,/g, '.')) : acc
              }, 0) || 0;
              const pending = parseFloat(formData["Saldo pendiente"] || 0);

              if (Math.round(pendingInstallmentsValue) !== Math.round(pending)) {
                dispatch(openSnackbarAction({ msg: "La sumatoria de cuotas pendientes no coincide con el saldo pendiente", severity: "error" }));
                return;
              }

              if (await dataIsValid()) {
                let dataToSend = { ...formData, detail: allData, entryStructure: entryStructureCode };

                if (!isCreate) {
                  dataToSend = { ...dataToSend, internalCode: data["Código interno del registro"] };
                }
                dataToSend["Frecuencia del descuento"] = dataToSend["Frecuencia del descuento"] === "CP" ? "O" : "C";
                dataToSend["Número de cuota de referencia"] = String(dataToSend["Número de cuota de referencia"]);
                if (dataToSend["Número de cuota de referencia"] === "0") {
                  dataToSend["Número de cuota de referencia"] = "1";
                }
                dataToSend["Fecha del préstamo"] = moment(dataToSend["Fecha del préstamo"]).format("YYYY/MM/DD");
                dataToSend["Período de inicio del descuento"] = dataToSend["Período de inicio del descuento"] instanceof Date ?
                  moment(dataToSend["Período de inicio del descuento"]).format("YYYY/MM") : dataToSend["Período de inicio del descuento"];
                dataToSend.detail = dataToSend.detail.map(row => {
                  const stringInstallmentNumber = String(row["Cuota"])
                  const loan = row["Valor cuota"].replace(/\./g, '').replace(/,/g, '.');
                  return { ...row, "Cuota": stringInstallmentNumber, "Valor cuota": loan }
                })
                isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
              } else {
                invalidDataCallback();
                return false;
              }
            } else {
              dispatch(openSnackbarAction({ msg: "Verifique que las celdas tengan valores válidos", severity: "error" }));
            }
          })
        });
    });


  }

  const formatValue = (value, fieldName) => {
    if (
      fieldName === "Total del préstamo"
    ) {
      return value.length > 0 ? value.replace(/-/g, "") : "";
    }

    if (fieldName === "Cantidad de cuotas" ||
      fieldName === "Número de cuota de referencia") {
      return value.length > 0 ? value.substring(0, 3) : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }
  const setCalculatedFieldsValues = (data) => {
    setFormData({
      ...formData,
      "Valor pagado": data[0],
      "Saldo pendiente": data[1],
      "Cuotas pagadas": data[2],
      "Cuotas pendientes": data[3]
    })
  }

  const setFormValueLoanDatePeriod = (date, period) => {
    setFormData({
      ...formData,
      "Fecha del préstamo": date,
      "Período de inicio del descuento": period
    })
  }

  const setConcept = (concept, conceptsArray) => {
    const element = conceptsArray.find(el => el.key === concept);
    setFormData({
      ...formData,
      "Concepto": concept,
      "Nombre del concepto": element.value
    })
  }

  const setPaymenthMethodValue = (value) => {
    if (value === "D") {
      setFormData({
        ...formData,
        "Forma de pago": value
      })
    } else {
      setFormData({
        ...formData,
        "Forma de pago": value,
        "Banco": "",
        "Cuenta bancaria": "",
      })
    }
  }


  return { isCreate, formData, setFormValue, setCalculatedFieldsValues, setConcept, setFormValueLoanDatePeriod, setPaymenthMethodValue, submit };
}

export default useHandleForm;
