import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, isDuplicate, payrollSelected, modifyCallback, duplicateCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "originalCode") {
        return data.code;
      }

      return data[fieldName];
    }

    const fieldNames = [
      "originalCode",
      "code",
      "name",
      "conceptID",
      "dataEntryHelp"
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(() => createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      originalCode: yup.string().max(8).required(),
      code: yup.string().max(8).required(),
      name: yup.string().max(60).required(),
      conceptID: yup.string().max(8),
      dataEntryHelp: yup.string().max(250),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        payrollType: payrollSelected
      };

      if (!isDuplicate) {
        delete dataToSend.originalCode;
      }

      isDuplicate ? duplicateCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
