import React, { useRef, useEffect, useState, useCallback } from "react";
import CommonPage from "@icarius-common/commonPage";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  getAssetDiscountsBasicElementsFromServer,
  getAssetDiscountsGivenEntryStructureFromServer,
  setAssetDiscountsEntryPerConceptsToServer,
} from "../actions";
import {
  getEntryStructures,
  getLoading,
  getColumnTypes,
  getRows,
  getConceptsTypes,
  getConcepts,
  getSelects,
  getColaborators,
  getQuantityPerUsedEntryStructure
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "./confirmationDialog";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter"
import {
  TextField,
  Grid,
  Typography
} from "@material-ui/core";
import { formatDate } from "@icarius-utils/date";
import NumberItem from "./numberItem";
import { useLocation } from "react-router-dom";
import { RESET_STATE } from "../actionTypes";
import { VisibilityIcon } from "@icarius-icons/";
import useAutomaticProcesses from "@icarius-common/automaticProcesses/useAutomaticProcesses";
import ResultGridDialog from "@icarius-common/automaticProcesses/components/resultGridDialog";
import PickTemplateDialog from "@icarius-pages/employeesProcesses/components/dialogs/pickTemplateDialog";
import SelectProcessDialog from "@icarius-common/automaticProcesses/components/selectProcessDialog";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { formatNumberExactDecimals, formatNumberOrReturnUndefined } from "@icarius-utils/format";

const mesesKeyValue = [
  { key: "1", value: "Enero" },
  { key: "2", value: "Febrero" },
  { key: "3", value: "Marzo" },
  { key: "4", value: "Abril" },
  { key: "5", value: "Mayo" },
  { key: "6", value: "Junio" },
  { key: "7", value: "Julio" },
  { key: "8", value: "Agosto" },
  { key: "9", value: "Septiembre" },
  { key: "10", value: "Octubre" },
  { key: "11", value: "Noviembre" },
  { key: "12", value: "Diciembre" }
];

const arrayIsNumber = [
  'Valor informado bruto',
  'Valor informado líquido',
  'Cantidad',
  'Porcentaje',
  'Base de cálculo',
];

const meses = mesesKeyValue.map(mes => mes.value);

const isObligatory = value => { return value === "B" };

const gridStyle = { height: 100, padding: "0px 10px" };

const EntryPerConcept = ({ history }) => {

  const location = useLocation();

  const isEntryPerConcept = useCallback(() => {
    return location.pathname === paths.entryPerConcept
  }, [location.pathname]);

  const [selectedEntryStructureCode, setSelectedEntryStructureCode] = useState(location?.state?.structureKey || "");
  const [dataChanged, setDataChanged] = useState(false);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [gridReference, setGridReference] = useState(null);
  const rowData = useSelector(getRows);
  const entryStructures = useSelector(getEntryStructures);
  const columnTypes = useSelector(getColumnTypes);
  const conceptsTypes = useSelector(getConceptsTypes);
  const concepts = useSelector(getConcepts);
  const selects = useSelector(getSelects);
  const isLoading = useSelector(getLoading);
  const colaborators = useSelector(getColaborators);
  const quantityPerUsedEntryStructure = useSelector(getQuantityPerUsedEntryStructure);

  const {
    isExecution,
    automaticProcessesIsLoading,
    automaticProcessesIsEnabled,
    processList,
    resultData,
    resultDialogIsOpen,
    pickTemplateDialogIsOpen,
    processSelectionDialogIsOpen,
    executeProcess,
    handleExecute,
    viewResult,
    handleViewResult,
    handleCloseProcessDialogSelection,
    handleCloseResultDialog,
    handleOpenPickTemplateDialog,
    handleClosePickTemplateDialog,
    handleGenerateDocumentsGivenTemplate,
  } = useAutomaticProcesses();

  const gridRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssetDiscountsBasicElementsFromServer("API"));
  }, [dispatch])

  useEffect(() => {
    if (selectedEntryStructureCode !== "") {
      dispatch(getAssetDiscountsGivenEntryStructureFromServer("API", selectedEntryStructureCode)).then(resp => {
        if (resp.statusText === "OK") {
          setShouldReloadGrid(true);
        }
      });
    }
  }, [dispatch, selectedEntryStructureCode, isEntryPerConcept, gridReference])

  useEffect(() => {
    if (selectedEntryStructureCode !== "" && gridRef) {
      setGridReference(gridRef.current.refs.dataGrid._instance);
    }
  }, [gridRef, selectedEntryStructureCode])

  const handleSave = () => {
    if (isEntryPerConcept()) {
      const rowData = gridReference.getVisibleRows().map(item => item.data);
      formatDataBeforeServerRequest(rowData);
    } else {
      let ds = gridReference.getDataSource();
      let filter = gridReference.getCombinedFilter();
      gridReference.saveEditData().done(resp => {
        ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
          .done((allData) => {
            const rowData = allData;

            gridReference.getController("validating").validate(true).then(isValid => {
              if (isValid) {
                let formattedData = rowData.map(row => {
                  let modifiedRow = { ...row };

                  for (let [key, value] of Object.entries(modifiedRow)) {
                    if (key.includes("(hhmm)")) {
                      let newKey = key.replace("(hhmm)", "(hh:mm)");
                      delete modifiedRow[key];
                      modifiedRow[newKey] = value;

                      let arr = value.split(':');
                      let dec = parseInt((arr[1] / 6) * 10, 10);

                      let newValue = parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);

                      modifiedRow["Cantidad"] = formatNumberExactDecimals(newValue, 5);
                    }
                  }
                  return modifiedRow;
                });

                formatDataBeforeServerRequest(formattedData);
              } else {
                dispatch(openSnackbarAction({ msg: "Verifique que las celdas tengan valores válidos", severity: "error" }));
              }
            })
          });
      });
    }

  };

  const formatDataBeforeServerRequest = data => {
    // Aca arranco a poner lo que hace falta
    const entryStructure = getEntryStructure();

    let filteredData = data.filter(row => row["Concepto"]);
    let formattedData = filteredData.map(row => {
      let modifiedRow = { ...row };

      // Periodicidad
      if (entryStructure.periodicity === "U") {
        modifiedRow["En fecha"] = formatDate(row["En fecha"]);
      }

      if (entryStructure.advancePayment) {
        modifiedRow["Fecha de descuento"] = formatDate(row["Fecha de descuento"]);
      }

      delete modifiedRow["Fecha de contratación"];
      delete modifiedRow["Fecha de egreso"];

      // Tengo que formatear los valores numericos con punto en vez de coma
      if (row["Valor informado líquido"]) {
        modifiedRow["Valor informado líquido"] = row["Valor informado líquido"].replace(/\./g, '').replace(/,/g, '.')
      }

      if (row["Valor informado bruto"]) {
        modifiedRow["Valor informado bruto"] = row["Valor informado bruto"].replace(/\./g, '').replace(/,/g, '.')
      }

      if (row["Cantidad"]) {
        modifiedRow["Cantidad"] = row["Cantidad"].replace(/\./g, '').replace(/,/g, '.')
      }

      if (row["Porcentaje"]) {
        modifiedRow["Porcentaje"] = row["Porcentaje"].replace(/\./g, '').replace(/,/g, '.')
      }

      if (row["Base de cálculo"]) {
        modifiedRow["Base de cálculo"] = row["Base de cálculo"].replace(/\./g, '').replace(/,/g, '.')
      }

      modifiedRow["Periodicidad"] = entryStructure.periodicity;

      for (let [key, value] of Object.entries(modifiedRow)) {
        if (key.includes("(hhmm)")) {
          delete modifiedRow[key];
          let newKey = key.replace("(hhmm)", "(hh:mm)");
          modifiedRow[newKey] = value;
        }
      }

      return modifiedRow;
    });

    sendDataToServer(selectedEntryStructureCode, formattedData);
  }

  const sendDataToServer = (selectedEntryStructureCode, formattedData) => {
    dispatch(setAssetDiscountsEntryPerConceptsToServer({
      type: "API",
      entryStructure: selectedEntryStructureCode,
      items: formattedData
    }))
      .then((resp) => {
        gridReference.getScrollable().scrollTo(0);

        //limpiar el seleccionado
        gridReference.deselectAll()

        if (resp?.response?.data?.status === 'DUPLICATED_DATA') {
          const rowsToSelect = [];
          resp.response.data.codes.forEach(element => {
            gridReference.getVisibleRows().forEach(node => {
              if (Number(node.data.type) === Number(element.type) &&
                Number(node.data.seniorityFrom) === Number(element.seniorityFrom) &&
                Number(node.data.seniorityTo) === Number(element.seniorityTo) &&
                Number(node.data.additionalDays) === Number(element.additionalDays) &&
                Number(node.data.lawDays) === Number(element.lawDays)) {
                rowsToSelect.push(node.key);
              }
            });

            gridReference.selectRows(rowsToSelect);
          })
        } else {
          setShouldReloadGrid(true);
        }

        if (resp?.status === 200) {
          setDataChanged(false);
        }
      })
  }

  const handleOpenDeleteDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Desea eliminar las filas seleccionadas?',
      onConfirm: () => deleteRowsLocally(selectedRows),
    }));
  }

  const deleteRowsLocally = (rowsToDelete) => {
    gridReference.cancelEditData();
    if (Array.isArray(rowsToDelete)) {
      const ds = gridReference.getDataSource();
      const filter = gridReference.getCombinedFilter();
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          rowsToDelete.forEach(row => {
            const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
            filteredRows.forEach(gridRow =>
              gridReference.getDataSource().store().remove(gridRow).then(() => {
                gridReference.refresh();
              })
            )
          });
        });
    }
    dispatch(openSnackbarAction({ msg: 'Valores eliminados con éxito', severity: "success" }));
  }

  const handleGoBack = () => {
    dispatch({ type: RESET_STATE });
    history.push(paths.assetsAndDiscountsScreenSelector);
  }

  const handleCheckGoBack = () => {
    if (dataChanged) {
      setConfirmationDialogIsOpen(true);
    } else {
      if (location?.state?.from) {
        history.push(paths.assetsDiscountsSummary);
      } else {
        handleGoBack();
      }
    }
  }

  const handleExportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Haberes y descuentos');

    exportDataGrid({
      component: gridReference,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.value) {
          if (gridCell.rowType === 'data') {
            if (arrayIsNumber.includes(gridCell.column.dataField) && Boolean(gridCell.value)) {
              excelCell.value = Number(gridCell.value.replace(/\./g, '').replace(/,/g, '.'))
              excelCell.numFmt = '_(###0.00_);_((###0.00)';
            }
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Haberes y descuentos - Ingreso de datos via API.xlsx`);
      });
    });
  }

  const handleValidateAndExecute = () => {
    let selectedRows = gridReference.getSelectedRowsData();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    const employeeCodes = selectedRows.map(item => item["Código de empleado"]);
    handleExecute(employeeCodes);
  }

  const executeItem = () => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={`Calcular${processList.length === 1 ? `: ${processList[0].value}` : ' proceso'}`}
      onClick={() => handleValidateAndExecute()}
      type={"execute"}
    />
  )

  const handleValidateAndViewResult = () => {
    let selectedRows = gridReference.getSelectedRowsData();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    handleViewResult(selectedRows[0], selectedRows[0]["Código de empleado"]);
  }

  const viewResultItem = () => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={"Ver resultado"}
      onClick={handleValidateAndViewResult}
    >
      <VisibilityIcon />
    </CustomIconButton>
  )

  const saveItem = () => (
    <CustomIconButton
      title={"Guardar"}
      onClick={handleSave}
      type={"save"}
    />
  )

  const deleteItem = () => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={handleOpenDeleteDialog}
      type={"delete"}
    />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleCheckGoBack}
      type={"goBack"}
    />
  )

  const exportGrid = () => (
    <CustomIconButton
      title={"Exportar datos"}
      onClick={handleExportGrid}
      type={"excel"}
    />
  )

  const selectDataSources = (name, data) => {
    if (meses.includes(name)) {
      return [{ key: "N", value: "No" }, { key: "Y", value: "Si" }]
    }
    switch (name) {
      case "Meses a reliquidar":
        const mesesReliquidacion = [];
        for (let i = 1; i <= 36; i++) {
          mesesReliquidacion.push({ key: `${i}`, value: `${i}` })
        }
        return mesesReliquidacion;
      case "Tipo de concepto":
        return conceptsTypes;
      case "Indicador de cálculo":
        return selects.calculationIndicators;
      case "Código de referencia":
        return selects.codeRefs;
      case "Proyecto":
        return selects.projects;
      case "Banco":
        return selects.banks;
      case "Forma de pago":
        return selects.paymentMethods;
      case "Tercero":
        return selects.thirdParties;
      case "Tipo de pago":
        return selects.paymentTypes;
      case "Centro de costo":
        return selects.benefitCenters;
      case "Concepto":
        const element = conceptsTypes.find(el => el.key === data);
        if (element && element.value) {
          return concepts[element.value];
        }
        return [];
      case "all-concepts":
        let testArr = []
        conceptsTypes.forEach(el => testArr.push(...concepts[el.value].map(concept => ({ ...concept, "Tipo de concepto": el.key }))));
        return testArr;
      default:
        return [];
    }
  }

  const formattedData = rowData.map(originalRow => {
    let row = { ...originalRow };

    if (row["Cantidad"]) {
      row["Cantidad"] = formatNumberOrReturnUndefined(row["Cantidad"], 0, 5)
    }

    if (row["Valor informado líquido"]) {
      row["Valor informado líquido"] = formatNumberOrReturnUndefined(row["Valor informado líquido"], 0, 2)
    }

    if (row["Valor informado bruto"]) {
      row["Valor informado bruto"] = formatNumberOrReturnUndefined(row["Valor informado bruto"], 0, 2)
    }

    if (row["Porcentaje"]) {
      row["Porcentaje"] = formatNumberOrReturnUndefined(row["Porcentaje"], 0, 2)
    }

    if (row["Base de cálculo"]) {
      row["Base de cálculo"] = formatNumberOrReturnUndefined(row["Base de cálculo"], 0, 2)
    }

    for (let [key, value] of Object.entries(row)) {
      if (key.includes("(hh:mm)")) {
        delete row[key];
        let newKey = key.replace("(hh:mm)", "(hhmm)");
        row[newKey] = value;
      }
    }

    return row;
  })

  const getEntryStructure = () => {
    const entryStructure = entryStructures.find(entry => entry.code === selectedEntryStructureCode);
    return entryStructure || { periodicity: '' };
  }

  const getColaboratorData = employeeCode => { return colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === employeeCode) }

  const generateColumns = () => {
    // Primero, tengo las columnas fijas
    let columns = [];
    columns.push({
      headerName: "Código de empleado",
      field: "Código de empleado",
      isObligatory: true,
      disabled: true,
      maxLength: 254,
      minLength: 0,
      isFixed: true,
    });

    columns.push({
      headerName: "Apellido y nombres",
      field: "Apellido y nombres",
      isObligatory: true,
      maxLength: 254,
      minLength: 0,
      isFixed: true,
      disabled: true
    });

    columns.push({
      headerName: "Fecha de contratación",
      field: "Fecha de contratación",
      cellEditor: "dateEditor",
      filter: "agTextColumnFilter",
      isDate: true,
      dataType: "date",
      disabled: true,
      visible: false,
    });

    columns.push({
      headerName: "Fecha de egreso",
      field: "Fecha de egreso",
      cellEditor: "dateEditor",
      filter: "agTextColumnFilter",
      isDate: true,
      dataType: "date",
      disabled: true,
      visible: false,
    });

    columns.push({
      headerName: "Tipo de concepto",
      field: "Tipo de concepto",
      isObligatory: true,
      isSelect: true,
    });

    columns.push({
      headerName: "Concepto",
      field: "Concepto",
      isObligatory: true,
      isSelect: true,
    });

    columns.push({
      headerName: "Nombre del concepto",
      field: "Nombre del concepto",
      isObligatory: true,
      maxLength: 254,
      minLength: 0,
    });


    // Aca arranco a poner lo que hace falta
    const entryStructure = getEntryStructure();

    // Periodicidad
    switch (entryStructure.periodicity) {
      case "U": // En fecha especifica
        columns.push({
          headerName: "En fecha",
          field: "En fecha",
          cellEditor: "dateEditor",
          filter: "agTextColumnFilter",
          isDate: true,
          dataType: "date",
          isObligatory: true,
        });
        break;
      case "T": // Temporal (Desde y hasta)
        columns.push({
          headerName: "Período desde",
          field: "Período desde",
          isObligatory: true,
        });
        columns.push({
          headerName: "Período hasta",
          field: "Período hasta",
          isObligatory: true,
        });
        break;
      case "M": // En meses específicos
        meses.forEach(mes => columns.push({
          headerName: mes,
          field: mes,
          isObligatory: true,
          isSelect: true,
        })
        );
        break;
      default: break;
    }

    // Horas y Minutos
    if (entryStructure.hoursMinutes) {
      columns.push({
        headerName: "Horas y Minutos (hh:mm)",
        field: "Horas y Minutos (hhmm)",
        isObligatory: isObligatory(entryStructure.hoursMinutes),
        maxLength: 254,
        minLength: 0,
      });
    }

    // Cantidad
    if (entryStructure.quantity) {
      columns.push({
        headerName: "Cantidad",
        field: "Cantidad",
        isObligatory: isObligatory(entryStructure.quantity),
        maxLength: 254,
        minLength: 0,
        isNumeric: true,
        validationUnrequired: true
      });
    }

    // Valor informado
    if (entryStructure.informedValue) {
      if (entryStructure.informedValueType === "L") {
        columns.push({
          headerName: "Valor informado líquido",
          field: "Valor informado líquido",
          isObligatory: isObligatory(entryStructure.informedValue),
          maxLength: 254,
          minLength: 0,
          isNumeric: true,
          validationUnrequired: true
        });
      } else {
        columns.push({
          headerName: "Valor informado bruto",
          field: "Valor informado bruto",
          isObligatory: isObligatory(entryStructure.informedValue),
          maxLength: 254,
          minLength: 0,
          isNumeric: true,
          validationUnrequired: true
        });
      }
    }

    // Porcentaje
    if (entryStructure.percentage) {
      columns.push({
        headerName: "Porcentaje",
        field: "Porcentaje",
        isObligatory: isObligatory(entryStructure.percentage),
        maxLength: 254,
        minLength: 0,
        isNumeric: true,
        validationUnrequired: true
      });
    }

    // Base de calculo
    if (entryStructure.calculationBase) {
      columns.push({
        headerName: "Base de cálculo",
        field: "Base de cálculo",
        isObligatory: isObligatory(entryStructure.calculationBase),
        maxLength: 254,
        minLength: 0,
        isNumeric: true,
        validationUnrequired: true
      });
    }

    // Datos para reliquidación - Período de reliquidación - Meses a reliquidar
    if (entryStructure.reliquidation) {
      columns.push({
        headerName: "Período de reliquidación",
        field: "Período de reliquidación",
        isObligatory: isObligatory(entryStructure.reliquidation),
        maxLength: 254,
        minLength: 0,
      });

      columns.push({
        headerName: "Meses a reliquidar",
        field: "Meses a reliquidar",
        isObligatory: isObligatory(entryStructure.reliquidation),
        isSelect: true,
      });
    }

    // Honorarios - Número de comprobante
    if (entryStructure.honoraries) {
      columns.push({
        headerName: "Número de comprobante",
        field: "Número de comprobante",
        isObligatory: isObligatory(entryStructure.honoraries),
        maxLength: 254,
        minLength: 0,
      });
    }

    // Pago a terceros
    if (entryStructure.thirdPartyPayment) {
      columns.push({
        headerName: "Tercero",
        field: "Tercero",
        isObligatory: isObligatory(entryStructure.thirdPartyPayment),
        isSelect: true,
      })
      columns.push({
        headerName: "Forma de pago",
        field: "Forma de pago",
        isObligatory: isObligatory(entryStructure.thirdPartyPayment),
        isSelect: true,
      })
      columns.push({
        headerName: "Tipo de pago",
        field: "Tipo de pago",
        isObligatory: isObligatory(entryStructure.thirdPartyPayment),
        isSelect: true,
      })
      columns.push({
        headerName: "Banco",
        field: "Banco",
        isObligatory: false,
        isSelect: true,
      })

      columns.push({
        headerName: "Cuenta bancaria",
        field: "Cuenta bancaria",
        isObligatory: false,
        maxLength: 254,
        minLength: 0,
      });

      columns.push({
        headerName: "Comentarios del pago al tercero",
        field: "Comentarios del pago al tercero",
        isObligatory: false,
        maxLength: 254,
        minLength: 0,
      });
    }

    // Periodo de imputacion
    if (entryStructure.imputationPeriod) {
      columns.push({
        headerName: "Periodo de imputación",
        field: "Periodo de imputación",
        isObligatory: isObligatory(entryStructure.imputationPeriod),
        maxLength: 254,
        minLength: 0,
      });
    }

    // Centro de costo
    if (entryStructure.benefitCenter) {
      columns.push({
        headerName: "Centro de costo",
        field: "Centro de costo",
        isObligatory: isObligatory(entryStructure.benefitCenter),
        isSelect: true,
      })
    }

    // Proyecto
    if (entryStructure.project) {
      columns.push({
        headerName: "Proyecto",
        field: "Proyecto",
        isObligatory: isObligatory(entryStructure.project),
        isSelect: true,
      })
    }

    // Código de referencia
    if (entryStructure.codeRef) {
      columns.push({
        headerName: "Código de referencia",
        field: "Código de referencia",
        isObligatory: Boolean(entryStructure.codeRef),
        isSelect: true,
      })
    }

    // Anticipo - Fecha de descuento
    if (entryStructure.advancePayment) {
      columns.push({
        headerName: "Fecha de descuento",
        field: "Fecha de descuento",
        cellEditor: "dateEditor",
        filter: "agTextColumnFilter",
        isDate: true,
        dataType: "date",
        isObligatory: isObligatory(entryStructure.advancePayment),
      });
    }

    // Indicador de cálculo
    if (entryStructure.calculationIndicator) {
      columns.push({
        headerName: "Indicador de cálculo",
        field: "Indicador de cálculo",
        isObligatory: Boolean(entryStructure.calculationIndicator),
        isSelect: true,
      })
    }

    columns.push({
      headerName: "Comentarios",
      field: "Comentarios",
      maxLength: 254,
      minLength: 0,
    });

    return columns;
  }

  const onChangeEntryStructure = (entryStructure) => {
    setSelectedEntryStructureCode(entryStructure)
    if (gridReference) {
      gridReference.getScrollable().scrollTo(0);
    }
  }

  let customItems = [goBackButton];

  if (selectedEntryStructureCode !== "") {
    customItems = [...customItems, saveItem, exportGrid, executeItem, viewResultItem, deleteItem]
  }

  useEffect(() => {
    rowData && setShouldReloadGrid(true);
  }, [rowData])

  return (
    <CommonPage
      title={`Haberes y descuentos - Ingreso de datos via API`}
      menuItems={customItems}
      isLoading={isLoading || automaticProcessesIsLoading}
      isNotGridPage
    >
      <Grid container item alignItems="center" xs={12} style={{ paddingLeft: 5 }}>
        <Grid container item alignItems="center" xs={12}>
          <Grid container item alignItems="center" style={gridStyle} xs={3}>
            <TextField
              disabled
              fullWidth
              margin={"none"}
              label={"Periodicidad"}
              value={(selectedEntryStructureCode && Boolean(columnTypes.length)) ? columnTypes?.find(el => el.key === getEntryStructure()?.periodicity).value : ""}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        quantityPerUsedEntryStructure.length > 0 &&
        <Grid container item xs={12} style={{ margin: 10, marginTop: 0 }}>
          {
            quantityPerUsedEntryStructure.map((el, index) => (
              <NumberItem
                key={index}
                name={el.name}
                value={el.cant}
                selected={el.code === selectedEntryStructureCode}
                onClick={() => onChangeEntryStructure(el.code)}
              />
            ))
          }
        </Grid>}
      {
        selectedEntryStructureCode !== "" &&
        <EditableGridv2
          selectDataSources={selectDataSources}
          setShouldReloadGrid={setShouldReloadGrid}
          shouldReloadGrid={shouldReloadGrid}
          setDataChanged={setDataChanged}
          ref={gridRef}
          gridName={"dataCapture"}
          rowData={formattedData}
          columns={generateColumns()}
          getColaboratorData={getColaboratorData}
        />
      }
      {
        selectedEntryStructureCode === "" &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
            <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
              {"Seleccione una estructura de ingreso"}
            </Typography>
          </Grid>
        </Grid>
      }
      {
        confirmationDialogIsOpen &&
        <ConfirmationDialog
          open={confirmationDialogIsOpen}
          handleConfirm={handleGoBack}
          handleClose={() => setConfirmationDialogIsOpen(false)}
        />
      }
      {
        processSelectionDialogIsOpen &&
        <SelectProcessDialog
          open={processSelectionDialogIsOpen}
          title={isExecution ? "Selección de proceso a ejecutar" : "Selección de proceso a consultar"}
          processList={processList}
          handleClose={handleCloseProcessDialogSelection}
          handleExecute={isExecution ? executeProcess : viewResult}
        />
      }
      {
        resultDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
        <ResultGridDialog
          open={resultDialogIsOpen}
          templates={resultData.templates}
          process={resultData.process}
          employeeName={resultData.employee['Apellido y nombres']}
          handleClose={handleCloseResultDialog}
          handleOpenPickTemplateDialog={handleOpenPickTemplateDialog}
          handleGenerateDocumentsGivenTemplate={handleGenerateDocumentsGivenTemplate}
        />
      }
      {
        resultData?.templates?.length > 0 && pickTemplateDialogIsOpen && !(isLoading || automaticProcessesIsLoading) &&
        <PickTemplateDialog
          open={pickTemplateDialogIsOpen}
          templates={resultData.templates}
          handleConfirm={handleGenerateDocumentsGivenTemplate}
          handleClose={handleClosePickTemplateDialog}
        />
      }
    </CommonPage>
  );
}

export default EntryPerConcept;
