import React, { useRef, useEffect, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  getAssetDiscountsBasicElementsFromServer,
  getAssetDiscountsGivenEntryStructureFromServer,
  setAssetDiscountsEntryPerSocietysToServer,
} from "../actions";
import {
  getEntryStructures,
  getLoading,
  getColumnTypes,
  getRows,
  getConceptsTypes,
  getConcepts,
  getSelects,
  getQuantityPerUsedEntryStructure
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "./confirmationDialog";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter"
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from "@material-ui/core";
import { formatDate } from "@icarius-utils/date";
import NumberItem from "./numberItem";
import AddRowStatusBar from '@icarius-common/editableGrid/Components/addRow/addRowButton';
import { useLocation } from "react-router-dom";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const mesesKeyValue = [
  { key: "1", value: "Enero" },
  { key: "2", value: "Febrero" },
  { key: "3", value: "Marzo" },
  { key: "4", value: "Abril" },
  { key: "5", value: "Mayo" },
  { key: "6", value: "Junio" },
  { key: "7", value: "Julio" },
  { key: "8", value: "Agosto" },
  { key: "9", value: "Septiembre" },
  { key: "10", value: "Octubre" },
  { key: "11", value: "Noviembre" },
  { key: "12", value: "Diciembre" }
];

const arrayIsNumber = [
  'Valor informado bruto',
  'Valor informado líquido',
  'Cantidad',
  'Porcentaje',
  'Base de cálculo',
];

const meses = mesesKeyValue.map(mes => mes.value);

const isObligatory = value => { return value === "B" };

const gridStyle = { height: 100, padding: "0px 10px" };

const HolidayTable = ({ history }) => {

  const location = useLocation();

  const [selectedEntryStructureCode, setSelectedEntryStructureCode] = useState(location?.state?.structureKey || "");
  const [dataChanged, setDataChanged] = useState(false);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [gridReference, setGridReference] = useState(null);
  const rowData = useSelector(getRows);
  const entryStructures = useSelector(getEntryStructures);
  const columnTypes = useSelector(getColumnTypes);
  const conceptsTypes = useSelector(getConceptsTypes);
  const concepts = useSelector(getConcepts);
  const selects = useSelector(getSelects);
  const isLoading = useSelector(getLoading);
  const quantityPerUsedEntryStructure = useSelector(getQuantityPerUsedEntryStructure);

  const gridRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssetDiscountsBasicElementsFromServer());
  }, [dispatch])

  useEffect(() => {
    if (selectedEntryStructureCode !== "") {
      dispatch(getAssetDiscountsGivenEntryStructureFromServer(selectedEntryStructureCode)).then(resp => {
        if (resp.statusText === "OK") {
          setShouldReloadGrid(true);
        }
      });
    }
  }, [dispatch, selectedEntryStructureCode, gridReference])

  useEffect(() => {
    if (selectedEntryStructureCode !== "" && gridRef) {
      setGridReference(gridRef.current.refs.dataGrid._instance);
    }
  }, [gridRef, selectedEntryStructureCode])

  const handleSave = () => {
    const rowData = gridReference.getVisibleRows().map(item => item.data);

    // Aca arranco a poner lo que hace falta
    const entryStructure = getEntryStructure();

    let filteredData = rowData.filter(row => row["Concepto"]);

    let formattedData = filteredData.map(row => {
      let modifiedRow = { ...row };

      // Periodicidad
      switch (entryStructure.periodicity) {
        case "F":
          modifiedRow["En fecha"] = formatDate(row["En fecha"]);
          break;
        case "M":
          if (modifiedRow["Enero"] === "N" &&
            modifiedRow["Febrero"] === "N" &&
            modifiedRow["Marzo"] === "N" &&
            modifiedRow["Abril"] === "N" &&
            modifiedRow["Mayo"] === "N" &&
            modifiedRow["Junio"] === "N" &&
            modifiedRow["Julio"] === "N" &&
            modifiedRow["Agosto"] === "N" &&
            modifiedRow["Septiembre"] === "N" &&
            modifiedRow["Octubre"] === "N" &&
            modifiedRow["Noviembre"] === "N" &&
            modifiedRow["Diciembre"] === "N") {
            return null;
          }

          modifiedRow["Enero"] = modifiedRow["Enero"] === "Y";
          modifiedRow["Febrero"] = modifiedRow["Febrero"] === "Y";
          modifiedRow["Marzo"] = modifiedRow["Marzo"] === "Y";
          modifiedRow["Abril"] = modifiedRow["Abril"] === "Y";
          modifiedRow["Mayo"] = modifiedRow["Mayo"] === "Y";
          modifiedRow["Junio"] = modifiedRow["Junio"] === "Y";
          modifiedRow["Julio"] = modifiedRow["Julio"] === "Y";
          modifiedRow["Agosto"] = modifiedRow["Agosto"] === "Y";
          modifiedRow["Septiembre"] = modifiedRow["Septiembre"] === "Y";
          modifiedRow["Octubre"] = modifiedRow["Octubre"] === "Y";
          modifiedRow["Noviembre"] = modifiedRow["Noviembre"] === "Y";
          modifiedRow["Diciembre"] = modifiedRow["Diciembre"] === "Y";

          break;
        default:
          break;
      }

      if (entryStructure.advancePayment) {
        modifiedRow["Fecha de descuento"] = formatDate(row["Fecha de descuento"]);
      }

      delete modifiedRow["Fecha de contratación"];
      delete modifiedRow["Fecha de egreso"];

      // Tengo que formatear los valores numericos con punto en vez de coma
      if (row["Valor informado líquido"]) {
        modifiedRow["Valor informado líquido"] = row["Valor informado líquido"].replace(/\./g, '').replace(/,/g, '.')
      }

      if (row["Valor informado bruto"]) {
        modifiedRow["Valor informado bruto"] = row["Valor informado bruto"].replace(/\./g, '').replace(/,/g, '.')
      }

      if (row["Cantidad"]) {
        modifiedRow["Cantidad"] = row["Cantidad"].replace(/\./g, '').replace(/,/g, '.')
      }

      if (row["Porcentaje"]) {
        modifiedRow["Porcentaje"] = row["Porcentaje"].replace(/\./g, '').replace(/,/g, '.')
      }

      if (row["Base de cálculo"]) {
        modifiedRow["Base de cálculo"] = row["Base de cálculo"].replace(/\./g, '').replace(/,/g, '.')
      }

      modifiedRow["Periodicidad"] = entryStructure.periodicity;

      return modifiedRow;
    });

    formattedData = formattedData.filter(item => item);

    dispatch(setAssetDiscountsEntryPerSocietysToServer({ entryStructure: selectedEntryStructureCode, items: formattedData }))
      .then((resp) => {
        //limpiar el seleccionado
        gridReference.deselectAll()

        if (resp?.response?.data?.status === 'DUPLICATED_DATA') {
          const rowsToSelect = [];
          resp.response.data.codes.forEach(element => {
            gridReference.getVisibleRows().forEach(node => {
              if (Number(node.data.type) === Number(element.type) &&
                Number(node.data.seniorityFrom) === Number(element.seniorityFrom) &&
                Number(node.data.seniorityTo) === Number(element.seniorityTo) &&
                Number(node.data.additionalDays) === Number(element.additionalDays) &&
                Number(node.data.lawDays) === Number(element.lawDays)) {
                rowsToSelect.push(node.key);
              }
            });

            gridReference.selectRows(rowsToSelect);
          })
        } else {
          setShouldReloadGrid(true);
        }

        if (resp?.status === 200) {
          setDataChanged(false);
        }
      })
  };


  const handleOpenDeleteDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Desea eliminar las filas seleccionadas?',
      onConfirm: () => deleteRowsLocally(selectedRows),
    }));
  }

  const deleteRowsLocally = (rowsToDelete) => {
    gridReference.cancelEditData();
    if (Array.isArray(rowsToDelete)) {
      const ds = gridReference.getDataSource();
      const filter = gridReference.getCombinedFilter();
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          rowsToDelete.forEach(row => {
            const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
            filteredRows.forEach(gridRow =>
              gridReference.getDataSource().store().remove(gridRow).then(() => {
                gridReference.refresh();
              })
            )
          });
        });
    }
    dispatch(openSnackbarAction({ msg: 'Valores eliminados con éxito', severity: "success" }));
  }

  const handleGoBack = () => {
    history.push(paths.assetsAndDiscountsScreenSelector);
  }

  const handleCheckGoBack = () => {
    if (dataChanged) {
      setConfirmationDialogIsOpen(true);
    } else {
      if (location?.state?.from) {
        history.push(paths.assetsDiscountsSummary);
      } else {
        handleGoBack();
      }
    }
  }

  const handleExportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Haberes y descuentos');

    exportDataGrid({
      component: gridReference,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.value) {
          if (gridCell.rowType === 'data') {
            if (arrayIsNumber.includes(gridCell.column.dataField) && Boolean(gridCell.value)) {
              excelCell.value = Number(gridCell.value.replace(/\./g, '').replace(/,/g, '.'));
              excelCell.numFmt = '_(###0.00_);_((###0.00)';
            }

          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Haberes y descuentos - Por sociedad.xlsx');
      });
    });
  }

  const saveItem = () => (
    <CustomIconButton
      title={"Guardar"}
      onClick={handleSave}
      type={"save"}
    />
  )

  const addRowButton = () => (
    <AddRowStatusBar {...gridRef.current} />
  )
  const deleteItem = () => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={handleOpenDeleteDialog}
      type={"delete"}
    />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleCheckGoBack}
      type={"goBack"}
    />
  )

  const exportGrid = () => (
    <CustomIconButton
      title={"Exportar"}
      onClick={handleExportGrid}
      type={"excel"}
    />
  )

  const selectDataSources = (name, data) => {
    if (meses.includes(name)) {
      return [{ key: "N", value: "No" }, { key: "Y", value: "Si" }]
    }
    switch (name) {
      case "Meses a reliquidar":
        const mesesReliquidacion = [];
        for (let i = 1; i <= 36; i++) {
          mesesReliquidacion.push({ key: `${i}`, value: `${i}` })
        }
        return mesesReliquidacion;
      case "Tipo de concepto":
        return conceptsTypes;
      case "Secuencia de Cálculo":
        return selects.calculationSequences;
      case "Indicador de cálculo":
        return selects.calculationIndicators;
      case "Código de referencia":
        return selects.codeRefs;
      case "Banco":
        return selects.banks;
      case "Forma de pago":
        return selects.paymentMethods;
      case "Tercero":
        return selects.thirdParties;
      case "Tipo de pago":
        return selects.paymentTypes;
      case "Concepto":
        const element = conceptsTypes.find(el => el.key === data);
        if (element && element.value) {
          return concepts[element.value];
        }
        return [];
      case "all-concepts":
        let testArr = []
        conceptsTypes.forEach(el => testArr.push(...concepts[el.value].map(concept => ({ ...concept, "Tipo de concepto": el.key }))));
        return testArr;
      default:
        return [];
    }
  }

  const formattedData = rowData.map(originalRow => {
    let row = { ...originalRow };

    if (row["Cantidad"]) {
      row["Cantidad"] = formatNumberOrReturnUndefined(row["Cantidad"], 0, 5)
    }

    if (row["Valor informado líquido"]) {
      row["Valor informado líquido"] = formatNumberOrReturnUndefined(row["Valor informado líquido"], 0, 2)
    }

    if (row["Valor informado bruto"]) {
      row["Valor informado bruto"] = formatNumberOrReturnUndefined(row["Valor informado bruto"], 0, 2)
    }

    if (row["Porcentaje"]) {
      row["Porcentaje"] = formatNumberOrReturnUndefined(row["Porcentaje"], 0, 2)
    }

    if (row["Base de cálculo"]) {
      row["Base de cálculo"] = formatNumberOrReturnUndefined(row["Base de cálculo"], 0, 2)
    }

    return row;
  })

  const getEntryStructure = () => {
    const entryStructure = entryStructures?.find(entry => entry.code === selectedEntryStructureCode);
    return entryStructure || { periodicity: '' };
  }

  const generateColumns = () => {
    // Primero, tengo las columnas fijas
    let columns = [];
    columns.push({
      headerName: "Tipo de concepto",
      field: "Tipo de concepto",
      isObligatory: true,
      isSelect: true,
    });

    columns.push({
      headerName: "Concepto",
      field: "Concepto",
      isObligatory: true,
      isSelect: true,
    });


    columns.push({
      headerName: "Secuencia de Cálculo",
      field: "Secuencia de Cálculo",
      isObligatory: true,
      disabled: true,
      isSelect: true,
    });

    columns.push({
      headerName: "Nombre del concepto",
      field: "Nombre del concepto",
      isObligatory: true,
      maxLength: 254,
      minLength: 0,
    });

    // Aca arranco a poner lo que hace falta
    const entryStructure = getEntryStructure();

    // Periodicidad
    switch (entryStructure.periodicity) {
      case "F": // En fecha especifica
        columns.push({
          headerName: "En fecha",
          field: "En fecha",
          cellEditor: "dateEditor",
          filter: "agTextColumnFilter",
          isDate: true,
          dataType: "date",
          isObligatory: true,
        });
        break;
      case "T": // Temporal (Desde y hasta)
        columns.push({
          headerName: "Período desde",
          field: "Período desde",
          isObligatory: true,
        });
        columns.push({
          headerName: "Período hasta",
          field: "Período hasta",
          isObligatory: true,
        });
        break;
      case "M": // En meses específicos
        meses.forEach(mes => columns.push({
          headerName: mes,
          field: mes,
          isObligatory: true,
          isSelect: true,
        })
        );
        break;
      default: break;
    }

    // Cantidad
    if (entryStructure.quantity) {
      columns.push({
        headerName: "Cantidad",
        field: "Cantidad",
        isObligatory: isObligatory(entryStructure.quantity),
        maxLength: 254,
        minLength: 0,
        isNumeric: true,
        validationUnrequired: true
      });
    }

    // Valor informado
    if (entryStructure.informedValue) {
      if (entryStructure.informedValueType === "L") {
        columns.push({
          headerName: "Valor informado líquido",
          field: "Valor informado líquido",
          isObligatory: isObligatory(entryStructure.informedValue),
          maxLength: 254,
          minLength: 0,
          isNumeric: true,
          validationUnrequired: true
        });
      } else {
        columns.push({
          headerName: "Valor informado bruto",
          field: "Valor informado bruto",
          isObligatory: isObligatory(entryStructure.informedValue),
          maxLength: 254,
          minLength: 0,
          isNumeric: true,
          validationUnrequired: true
        });
      }
    }

    // Porcentaje
    if (entryStructure.percentage) {
      columns.push({
        headerName: "Porcentaje",
        field: "Porcentaje",
        isObligatory: isObligatory(entryStructure.percentage),
        maxLength: 254,
        minLength: 0,
        isNumeric: true,
        validationUnrequired: true
      });
    }

    // Base de calculo
    if (entryStructure.calculationBase) {
      columns.push({
        headerName: "Base de cálculo",
        field: "Base de cálculo",
        isObligatory: isObligatory(entryStructure.calculationBase),
        maxLength: 254,
        minLength: 0,
        isNumeric: true,
        validationUnrequired: true
      });
    }

    // Datos para reliquidación - Período de reliquidación - Meses a reliquidar
    if (entryStructure.reliquidation) {
      columns.push({
        headerName: "Período de reliquidación",
        field: "Período de reliquidación",
        isObligatory: isObligatory(entryStructure.reliquidation),
        maxLength: 254,
        minLength: 0,
      });

      columns.push({
        headerName: "Meses a reliquidar",
        field: "Meses a reliquidar",
        isObligatory: isObligatory(entryStructure.reliquidation),
        isSelect: true,
      });
    }

    // Pago a terceros
    if (entryStructure.thirdPartyPayment) {
      columns.push({
        headerName: "Tercero",
        field: "Tercero",
        isObligatory: isObligatory(entryStructure.thirdPartyPayment),
        isSelect: true,
      })
      columns.push({
        headerName: "Forma de pago",
        field: "Forma de pago",
        isObligatory: isObligatory(entryStructure.thirdPartyPayment),
        isSelect: true,
      })
      columns.push({
        headerName: "Tipo de pago",
        field: "Tipo de pago",
        isObligatory: isObligatory(entryStructure.thirdPartyPayment),
        isSelect: true,
      })
      columns.push({
        headerName: "Banco",
        field: "Banco",
        isObligatory: false,
        isSelect: true,
      })

      columns.push({
        headerName: "Cuenta bancaria",
        field: "Cuenta bancaria",
        isObligatory: false,
        maxLength: 254,
        minLength: 0,
      });

      columns.push({
        headerName: "Comentarios del pago al tercero",
        field: "Comentarios del pago al tercero",
        isObligatory: false,
        maxLength: 254,
        minLength: 0,
      });
    }

    // Código de referencia
    if (entryStructure.codeRef) {
      columns.push({
        headerName: "Código de referencia",
        field: "Código de referencia",
        isObligatory: Boolean(entryStructure.codeRef),
        isSelect: true,
      })
    }


    // Indicador de cálculo
    if (entryStructure.calculationIndicator) {
      columns.push({
        headerName: "Indicador de cálculo",
        field: "Indicador de cálculo",
        isObligatory: Boolean(entryStructure.calculationIndicator),
        isSelect: true,
      })
    }

    columns.push({
      headerName: "Comentarios",
      field: "Comentarios",
      maxLength: 254,
      minLength: 0,
    });

    columns.push({
      headerName: "Condicional de cálculo",
      field: "Condicional de cálculo",
      maxLength: 254,
      minLength: 0,
    });

    return columns;
  }

  const onChangeEntryStructure = (entryStructure) => {
    setSelectedEntryStructureCode(entryStructure)
  }

  let customItems = [goBackButton];

  if (selectedEntryStructureCode !== "") {
    customItems = [...customItems, addRowButton, saveItem, exportGrid, deleteItem]
  }

  const usedEntryStructures = quantityPerUsedEntryStructure.map(structure => structure.code);

  useEffect(() => {
    rowData && setShouldReloadGrid(true);
  }, [rowData])

  return (
    <CommonPage
      title={'Haberes y descuentos - Por sociedad (Automáticos)'}
      menuItems={customItems}
      isLoading={isLoading}
      isNotGridPage
    >
      <Grid container item alignItems="center" xs={12} style={{ paddingLeft: 5 }}>
        <Grid container item alignItems="center" xs={12}>
          <Grid container item alignItems="center" style={gridStyle} xs={3} >
            <FormControl style={{ width: "100%" }}>
              <InputLabel>{"Estructura de ingreso"}</InputLabel>
              <Select
                value={selectedEntryStructureCode}
                onChange={(e) => onChangeEntryStructure(e.target.value)}
                margin={"none"}
              >
                {
                  entryStructures && entryStructures.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.code}
                      disabled={usedEntryStructures.includes(item.code)}
                      value={item.code}>
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" style={gridStyle} xs={3}>
            <TextField
              disabled
              fullWidth
              margin={"none"}
              label={"Periodicidad"}
              value={(selectedEntryStructureCode && Boolean(columnTypes.length)) ? columnTypes?.find(el => el.key === getEntryStructure()?.periodicity)?.value : ""}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        quantityPerUsedEntryStructure.length > 0 &&
        <Grid container item xs={12} style={{ margin: 10, marginTop: 0 }}>
          {
            quantityPerUsedEntryStructure.map((el, index) => (
              <NumberItem
                key={index}
                name={el.name}
                value={el.cant}
                selected={el.code === selectedEntryStructureCode}
                onClick={() => onChangeEntryStructure(el.code)}
              />
            ))
          }
        </Grid>}
      {
        selectedEntryStructureCode !== "" &&
        <EditableGridv2
          selectDataSources={selectDataSources}
          setShouldReloadGrid={setShouldReloadGrid}
          shouldReloadGrid={shouldReloadGrid}
          setDataChanged={setDataChanged}
          ref={gridRef}
          gridName="entryPerSociety"
          rowData={formattedData}
          columns={generateColumns()}
        />
      }
      {
        selectedEntryStructureCode === "" &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
            <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
              {"Seleccione una estructura de ingreso"}
            </Typography>
          </Grid>
        </Grid>
      }
      {
        confirmationDialogIsOpen &&
        <ConfirmationDialog
          open={confirmationDialogIsOpen}
          handleConfirm={handleGoBack}
          handleClose={() => setConfirmationDialogIsOpen(false)}
        />
      }
    </CommonPage >
  );
}

export default HolidayTable;
