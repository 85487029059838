import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import {
    downloadImportStructureAction,
    getInitialEntryPerConcept,
    getEntryPerConcept,
    createEntryPerConcept,
    modifyEntryPerConcept,
    deleteEntryPerConcept,
    getEntryPerConceptMyPeopleGrid,
    duplicateEntryPerConcept,
} from "../actions";
import {
    getEntryStructures,
    getLoading,
    getColumnTypes,
    getRows,
    getConceptsTypes,
    getConcepts,
    getSelects,
    getSocietyPeople,
    getColaborators,
    getCurrencyLocalization,
    getDateFormat,
    getQuantityPerUsedEntryStructure,
    getDecimals,
} from "../selectors";
import { getAppColor, getUserRole } from "src/app/selectors";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { getColumnDefs, getFields } from "./columns";
import { RESET_STATE } from "../actionTypes";

const mesesKeyValue = [
    { key: "1", value: "Enero" },
    { key: "2", value: "Febrero" },
    { key: "3", value: "Marzo" },
    { key: "4", value: "Abril" },
    { key: "5", value: "Mayo" },
    { key: "6", value: "Junio" },
    { key: "7", value: "Julio" },
    { key: "8", value: "Agosto" },
    { key: "9", value: "Septiembre" },
    { key: "10", value: "Octubre" },
    { key: "11", value: "Noviembre" },
    { key: "12", value: "Diciembre" }
];

const months = mesesKeyValue.map(mes => mes.value);

const useEntryPerConcept = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const columnTypes = useSelector(getColumnTypes);

    const [createEditDialogIsOpen, setCreateEditDialogIsOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedEntryStructureCode, setSelectedEntryStructureCode] = useState(location?.state?.structureKey || "");
    const [myPeopleGridDialogIsOpen, setMyPeopleGridDialogIsOpen] = useState(false);
    const [rowDataToReplicate, setRowDataToReplicate] = useState(null);

    const gridData = useSelector(getRows);
    const entryStructures = useSelector(getEntryStructures);
    const conceptsTypes = useSelector(getConceptsTypes);
    const concepts = useSelector(getConcepts);
    const selects = useSelector(getSelects);
    const isLoading = useSelector(getLoading);
    const societyPeople = useSelector(getSocietyPeople);
    const colaborators = useSelector(getColaborators);
    const locale = useSelector(getCurrencyLocalization);
    const dateFormat = useSelector(getDateFormat);
    const quantityPerUsedEntryStructure = useSelector(getQuantityPerUsedEntryStructure);
    const decimals = useSelector(getDecimals);


    const color = useSelector(getAppColor);

    const level = useSelector(getUserRole);

    const getEntryStructure = (selectedEntryStructureCode) => {
        const entryStructure = entryStructures.find(entry => entry.code === selectedEntryStructureCode);
        return entryStructure || { periodicity: '' };
    }

    const getPeriodicity = (selectedEntryStructureCode) => {
        if (selectedEntryStructureCode && columnTypes.length) {
            const element = columnTypes.find(el => el.key === getEntryStructure(selectedEntryStructureCode).periodicity);
            if (element) {
                return element.value;
            }
        }
        return "";
    }

    const [selectedEntryStructurePeriodicity, setSelectedEntryStructurePeriodicity] = useState(getPeriodicity(location?.state?.structureKey));

    const columnDefs = getColumnDefs(getEntryStructure(selectedEntryStructureCode), decimals);
    const fields = getFields(getEntryStructure(selectedEntryStructureCode), decimals);

    useEffect(() => {
        dispatch(getInitialEntryPerConcept("CO"));
    }, [dispatch])

    useEffect(() => {
        if (selectedEntryStructureCode !== "") {
            dispatch(getEntryPerConcept(selectedEntryStructureCode));
        }
    }, [dispatch, selectedEntryStructureCode])

    const handleGoBack = () => {
        if (location?.state?.from) {
            history.push(paths.assetsDiscountsSummary);
        } else {
            dispatch({ type: RESET_STATE });
            history.push(paths.assetsAndDiscountsScreenSelector);
        }
    }

    const handleChangeEntryStructure = (entryStructure) => {
        setSelectedEntryStructureCode(entryStructure);
        setSelectedEntryStructurePeriodicity(getPeriodicity(entryStructure));
        setRowDataToReplicate(null);
    }

    const handleSetUsers = (employeeCodes) => {
        dispatch(duplicateEntryPerConcept({ entryStructure: selectedEntryStructureCode, employeeCodes, internalCode: rowDataToReplicate["Código interno del registro"] }));
    }

    const formatData = data => {
        let modifiedRow = { ...data };

        // Aca arranco a poner lo que hace falta
        const entryStructure = getEntryStructure(selectedEntryStructureCode);

        modifiedRow["Periodicidad"] = entryStructure.periodicity;

        for (let [key, value] of Object.entries(modifiedRow)) {
            if (key.includes("(hhmm)")) {
                delete modifiedRow[key];
                let newKey = key.replace("(hhmm)", "(hh:mm)");
                modifiedRow[newKey] = value;
            }

            if (months.includes(key)) {
                modifiedRow[key] = value === "Y";
            }
        }

        return modifiedRow;
    }

    const handleCreateEntryPerConcept = data => {
        let formattedData = formatData(data);
        dispatch(createEntryPerConcept({
            entryStructure: selectedEntryStructureCode,
            item: formattedData
        })).then(resp => resp.status === 200 && handleCloseCreateEditDialog());
    }


    const handleCloseCreateEditDialog = () => {
        setCreateEditDialogIsOpen(false);
        setSelectedDocument(null);
    }

    const handleModifyEntryPerConcept = data => {
        let formattedData = formatData(data);

        dispatch(modifyEntryPerConcept({
            entryStructure: selectedEntryStructureCode,
            item: formattedData
        })).then(resp => resp.status === 200 && handleCloseCreateEditDialog());
    }


    const handleOpenDeleteDialog = (gridRef) => {
        let rows = gridRef.api.getSelectedRows();
        if (rows.length < 1) {
            dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
            return;
        }

        const codes = rows.map(el => el["Código interno del registro"]);

        dispatch(openDialogAction({
            title: getLocalizedString("atention"),
            msg: '¿Desea eliminar las filas seleccionadas?',
            onConfirm: () => dispatch(deleteEntryPerConcept({ entryStructure: selectedEntryStructureCode, internalCodes: codes })),
        }));
    }

    const handleExportStructure = () => {
        if (selectedEntryStructureCode === "") {
            dispatch(openSnackbarAction({ msg: "Debe seleccionar una estructura", severity: "warning" }));
            return;
        }

        dispatch(downloadImportStructureAction({ structure: selectedEntryStructureCode, type: "C", empty: false }));
    }

    const handleOpenMyPeopleGridDialog = (gridRef) => {
        let documents = gridRef.api.getSelectedRows();
        if (documents.length !== 1) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro para replicar', severity: "warning" }));
            return;
        }
        setRowDataToReplicate(documents[0]);

        if (colaborators.length === 0) {
            dispatch(getEntryPerConceptMyPeopleGrid()).then(resp => resp.status === 200 && setMyPeopleGridDialogIsOpen(true));
        } else {
            setMyPeopleGridDialogIsOpen(true);
        }
    }

    const handleCloseMyPeopleGridDialog = () => {
        setMyPeopleGridDialogIsOpen(false);
    }

    const handleOpenCreateDialog = () => {
        setSelectedDocument(null);

        if (societyPeople.length === 0) {
            dispatch(getEntryPerConceptMyPeopleGrid()).then(resp => resp.status === 200 && setCreateEditDialogIsOpen(true));
        } else {
            setCreateEditDialogIsOpen(true);
        }
    }

    const handleOpenEditDialog = (gridRef) => {
        let documents = gridRef.api.getSelectedRows();
        if (documents.length !== 1) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
            return;
        }

        setSelectedDocument(documents[0]);

        if (societyPeople.length === 0) {
            dispatch(getEntryPerConceptMyPeopleGrid()).then(resp => resp.status === 200 && setCreateEditDialogIsOpen(true));
        } else {
            setCreateEditDialogIsOpen(true);
        }
    }


    const handleRowClick = (row) => {
        setSelectedDocument(row.data);

        if (societyPeople.length === 0) {
            dispatch(getEntryPerConceptMyPeopleGrid()).then(resp => resp.status === 200 && setCreateEditDialogIsOpen(true));
        } else {
            setCreateEditDialogIsOpen(true);
        }
    }

    const state = {
        createEditDialogIsOpen,
        selectedDocument,
        selectedEntryStructureCode,
        myPeopleGridDialogIsOpen,
        selectedEntryStructurePeriodicity,
    }

    const handlers = {
        handleSetUsers,
        handleOpenDeleteDialog,
        handleChangeEntryStructure,
        handleGoBack,
        handleExportStructure,
        handleOpenMyPeopleGridDialog,
        handleCloseMyPeopleGridDialog,
        handleOpenCreateDialog,
        handleOpenEditDialog,
        handleCloseCreateEditDialog,
        handleCreateEntryPerConcept,
        handleModifyEntryPerConcept,
        handleRowClick,
    }

    return {
        months,
        societyPeople,
        quantityPerUsedEntryStructure,
        entryStructures,
        level,
        dateFormat,
        process,
        gridData,
        color,
        columnDefs,
        fields,
        isLoading,
        state,
        handlers,
        colaborators,
        locale,
        selects,
        concepts,
        conceptsTypes,
        decimals,
    }
}

export default useEntryPerConcept;