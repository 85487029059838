import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData, getPayrollTypes } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import {
  getAssetsDiscountsSummaryAction,
  deleteAssetsDiscountsSummaryAction
} from "../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useAssetsDiscountsSummary = (code) => {

  const [switchIsOn, setSwitchIsOn] = useState(false);
  const [payrollSelected, setPayrollSelected] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const payrollTypes = useSelector(getPayrollTypes);

  useEffect(() => {
    dispatch(getAssetsDiscountsSummaryAction(payrollSelected, switchIsOn, code));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, payrollSelected, switchIsOn, code])

  const handleOpenDeleteDialog = (gridRef) => {
    if (switchIsOn) {
      dispatch(openSnackbarAction({ msg: "No es posible eliminar un haber o descuento cuya modalidad de ingreso sea por sociedad", severity: "warning" }));
      return;
    }

    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: "Eliminar haberes",
      msg: "¿Desea eliminar los haberes?",
      onConfirm: () => handleDelete(selectedRows.map(item => item.internalCode)),
    }));
  }

  const handleDelete = (rows) => {
    dispatch(deleteAssetsDiscountsSummaryAction({ codes: rows, payrollType: payrollSelected }));
  }

  const handleRowClick = (row) => {
    const stateToSend = { from: paths.assetsDiscountsSummary, structureKey: row.data.structureKey };

    switch (row.data["Modalidad de ingreso"]) {
      case 'Por concepto':
        history.push({
          pathname: paths.entryPerConcept,
          state: stateToSend
        });
        break;
      case 'Por conjunto de conceptos':
        history.push({
          pathname: paths.entryPerConceptSet,
          state: stateToSend,
        });
        break;
      case 'Por sociedad':
        history.push({
          pathname: paths.entryPerSociety,
          state: stateToSend,
        });
        break;
      case 'En cuotas':
        history.push({
          pathname: paths.entryInInstallments,
          state: stateToSend,
        });
        break;
      case 'Vía importación de datos':
        // history.push(paths.)
        break;
      case 'Vía captura desde otro sistema (API)':
        // history.push(paths.)
        break;
      case 'Masivo':
        history.push({
          pathname: paths.massEntry,
          state: stateToSend,
        });
        break;
      default: break;
    }
  }

  const handleChangeSwitch = () => {
    setSwitchIsOn(prev => !prev);
  }

  return {
    isLoading,
    data,
    payrollTypes,
    payrollSelected,
    switchIsOn,
    handleChangeSwitch,
    setPayrollSelected,
    handleOpenDeleteDialog,
    handleDelete,
    handleRowClick,
  }
}

export default useAssetsDiscountsSummary;