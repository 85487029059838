import {
    dateClassRule,
    dateFormatter,
    periodFormatter,
    dateValueGetter,
    periodValueGetter,
    dateFilterParams,
    periodFilterParams,
    dateComparator,
    periodComparator,
} from "@icarius-table/date";
import {
    numberComparatorMax2,
    numberFilterParams,
    numberFormatterMin0Max0,
    numberComparatorCustom,
    numberFilterParamsCustom,
    numberFormatterDecimalsCustom,
} from "@icarius-table/number";
import moment from "moment";

const mesesKeyValue = [
    { key: "1", value: "Enero" },
    { key: "2", value: "Febrero" },
    { key: "3", value: "Marzo" },
    { key: "4", value: "Abril" },
    { key: "5", value: "Mayo" },
    { key: "6", value: "Junio" },
    { key: "7", value: "Julio" },
    { key: "8", value: "Agosto" },
    { key: "9", value: "Septiembre" },
    { key: "10", value: "Octubre" },
    { key: "11", value: "Noviembre" },
    { key: "12", value: "Diciembre" }
];

const getComponent = (decimals) => {
    switch (decimals) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            return `N${decimals}`;
        default:
            return "N2";
    }
}

const months = mesesKeyValue.map(mes => mes.value);

const isObligatory = value => { return value === "B" };

const getColumnDefs = (entryStructure, decimals) => {
    // Primero, tengo las columnas fijas
    let columns = [];
    columns.push({
        headerName: "Código de empleado",
        field: "Código de empleado",
        filter: "agTextColumnFilter",
        chartDataType: "series",
        checkboxSelection: function (params) {
            return params.columnApi.getRowGroupColumns().length === 0;
        },
        headerCheckboxSelection: function (params) {
            return params.columnApi.getRowGroupColumns().length === 0;
        },
        headerCheckboxSelectionFilteredOnly: true,
        enableRowGroup: true,
        pinned: "left",
    });

    columns.push({
        headerName: "Apellido y nombres",
        field: "Apellido y nombres",
        filter: "agTextColumnFilter",
        pinned: "left",
    });

    columns.push({
        headerName: "Tipo de concepto",
        field: "Tipo de concepto String",
        filter: "agSetColumnFilter",
    });

    columns.push({
        headerName: "Concepto",
        field: "Concepto String",
        filter: "agSetColumnFilter",
    });

    columns.push({
        headerName: "Nombre del concepto",
        field: "Nombre del concepto",
        filter: "agTextColumnFilter",
    });

    // Periodicidad
    switch (entryStructure.periodicity) {
        case "U": // En fecha especifica
            columns.push({
                headerName: "En fecha",
                field: "En fecha",
                filter: "agTextColumnFilter",
                comparator: dateComparator,
                filterParams: dateFilterParams,
                cellClassRules: dateClassRule,
                valueFormatter: dateFormatter,
                valueGetter: dateValueGetter,
            });
            break;
        case "T": // Temporal (Desde y hasta)
            columns.push({
                headerName: "Período desde",
                field: "Período desde",
                filter: "agTextColumnFilter",
                comparator: periodComparator,
                filterParams: periodFilterParams,
                valueFormatter: periodFormatter,
                valueGetter: periodValueGetter,
            });
            columns.push({
                headerName: "Período hasta",
                field: "Período hasta",
                filter: "agTextColumnFilter",
                comparator: periodComparator,
                filterParams: periodFilterParams,
                valueFormatter: periodFormatter,
                valueGetter: periodValueGetter,
            });
            break;
        case "M": // En meses específicos
            months.forEach(mes => columns.push({
                headerName: mes,
                field: `${mes} String`,
                filter: "agSetColumnFilter",
            })
            );
            break;
        default: break;
    }

    // Horas y Minutos
    if (entryStructure.hoursMinutes) {
        columns.push({
            headerName: "Horas y Minutos (hh:mm)",
            field: "Horas y Minutos (hh:mm)",
            filter: "agTextColumnFilter",
        });
    }

    // Cantidad
    if (entryStructure.quantity) {
        columns.push({
            headerName: "Cantidad",
            field: "Cantidad",
            filter: "agTextColumnFilter",
            comparator: (p) => numberComparatorCustom(p, decimals["Cantidad"]),
            filterParams: numberFilterParamsCustom(decimals["Cantidad"]),
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: (p) => numberFormatterDecimalsCustom(p, decimals["Cantidad"]),
        });
    }

    // Valor informado
    if (entryStructure.informedValue) {
        if (entryStructure.informedValueType === "L") {
            columns.push({
                headerName: "Valor informado líquido",
                field: "Valor informado líquido",
                filter: "agTextColumnFilter",
                comparator: (p) => numberComparatorCustom(p, decimals["Valor informado líquido"]),
                filterParams: numberFilterParamsCustom(decimals["Valor informado líquido"]),
                chartDataType: "series",
                cellClass: "currencyValue",
                valueFormatter: (p) => numberFormatterDecimalsCustom(p, decimals["Valor informado líquido"]),
            });
        } else {
            columns.push({
                headerName: "Valor informado bruto",
                field: "Valor informado bruto",
                filter: "agTextColumnFilter",
                comparator: (p) => numberComparatorCustom(p, decimals["Valor informado bruto"]),
                filterParams: numberFilterParamsCustom(decimals["Valor informado bruto"]),
                chartDataType: "series",
                cellClass: "currencyValue",
                valueFormatter: (p) => numberFormatterDecimalsCustom(p, decimals["Valor informado bruto"]),
            });
        }
    }

    // Porcentaje
    if (entryStructure.percentage) {
        columns.push({
            headerName: "Porcentaje",
            field: "Porcentaje",
            filter: "agTextColumnFilter",
            comparator: (p) => numberComparatorCustom(p, decimals["Porcentaje"]),
            filterParams: numberFilterParamsCustom(decimals["Porcentaje"]),
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: (p) => numberFormatterDecimalsCustom(p, decimals["Porcentaje"]),
        });
    }

    // Base de calculo
    if (entryStructure.calculationBase) {
        columns.push({
            headerName: "Base de cálculo",
            field: "Base de cálculo",
            filter: "agTextColumnFilter",
            comparator: (p) => numberComparatorCustom(p, decimals["Base de cálculo"]),
            filterParams: numberFilterParamsCustom(decimals["Base de cálculo"]),
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: (p) => numberFormatterDecimalsCustom(p, decimals["Base de cálculo"]),
        });
    }

    // Datos para reliquidación - Período de reliquidación - Meses a reliquidar
    if (entryStructure.reliquidation) {
        columns.push({
            headerName: "Período de reliquidación",
            field: "Período de reliquidación",
            filter: "agTextColumnFilter",
            comparator: periodComparator,
            filterParams: periodFilterParams,
            valueFormatter: periodFormatter,
            valueGetter: periodValueGetter,
        });

        columns.push({
            headerName: "Meses a reliquidar",
            field: "Meses a reliquidar",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
        });
    }

    // Honorarios - Número de comprobante
    if (entryStructure.honoraries) {
        columns.push({
            headerName: "Número de comprobante",
            field: "Número de comprobante",
            filter: "agTextColumnFilter",
        });
    }

    // Pago a terceros
    if (entryStructure.thirdPartyPayment) {
        columns.push({
            headerName: "Tercero",
            field: "Tercero String",
            filter: "agTextColumnFilter",
        })
        columns.push({
            headerName: "Forma de pago",
            field: "Forma de pago String",
            filter: "agSetColumnFilter",
        })
        columns.push({
            headerName: "Tipo de pago",
            field: "Tipo de pago String",
            filter: "agSetColumnFilter",
        })
        columns.push({
            headerName: "Banco",
            field: "Banco String",
            filter: "agSetColumnFilter",
        })

        columns.push({
            headerName: "Cuenta bancaria",
            field: "Cuenta bancaria",
            filter: "agTextColumnFilter",
        });

        columns.push({
            headerName: "Comentarios del pago al tercero",
            field: "Comentarios del pago al tercero",
            filter: "agTextColumnFilter",
        });
    }

    // Periodo de imputacion
    if (entryStructure.imputationPeriod) {
        columns.push({
            headerName: "Período de imputación",
            field: "Periodo de imputación",
            filter: "agSetColumnFilter",
            comparator: periodComparator,
            filterParams: periodFilterParams,
            valueFormatter: periodFormatter,
            valueGetter: periodValueGetter,
        });
    }

    // Centro de costo
    if (entryStructure.benefitCenter) {
        columns.push({
            headerName: "Centro de costo",
            field: "Centro de costo String",
            filter: "agSetColumnFilter",
        })
    }

    // Proyecto
    if (entryStructure.project) {
        columns.push({
            headerName: "Proyecto",
            field: "Proyecto String",
            filter: "agSetColumnFilter",
        })
    }

    // Código de referencia
    if (entryStructure.codeRef) {
        columns.push({
            headerName: "Código de referencia",
            field: "Código de referencia String",
            filter: "agTextColumnFilter",
        })
    }

    // Anticipo - Fecha de descuento
    if (entryStructure.advancePayment) {
        columns.push({
            headerName: "Fecha de descuento",
            field: "Fecha de descuento",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        });
    }

    // Indicador de cálculo
    if (entryStructure.calculationIndicator) {
        columns.push({
            headerName: "Indicador de cálculo",
            field: "Indicador de cálculo String",
            filter: "agSetColumnFilter",
        })
    }

    columns.push({
        headerName: "Comentarios",
        field: "Comentarios",
        filter: "agTextColumnFilter",
    });

    return columns;
};


const getFields = (entryStructure, decimals) => {
    // Primero, tengo las columnas fijas
    let columns = [];
    columns.push({
        headerName: "Apellido y nombres",
        field: "Apellido y nombres",
        isObligatory: true,
        maxLength: 254,
        minLength: 0,
        component: 'A',
    });

    columns.push({
        headerName: "Tipo de concepto",
        field: "Tipo de concepto",
        isObligatory: true,
        isSelect: true,
        component: 'L',
    });

    columns.push({
        headerName: "Concepto",
        field: "Concepto",
        isObligatory: true,
        isSelect: true,
        component: 'L',
    });

    columns.push({
        headerName: "Nombre del concepto",
        field: "Nombre del concepto",
        isObligatory: true,
        maxLength: 254,
        minLength: 0,
        component: 'E',
    });

    // Periodicidad
    switch (entryStructure.periodicity) {
        case "U": // En fecha especifica
            columns.push({
                headerName: "En fecha",
                field: "En fecha",
                isDate: true,
                dataType: "date",
                isObligatory: true,
                component: 'DS',
            });
            break;
        case "T": // Temporal (Desde y hasta)
            columns.push({
                headerName: "Período desde",
                field: "Período desde",
                isObligatory: true,
                component: 'P',
            });
            columns.push({
                headerName: "Período hasta",
                field: "Período hasta",
                isObligatory: true,
                component: 'P',
            });
            break;
        case "M": // En meses específicos
            months.forEach(mes => columns.push({
                headerName: mes,
                field: mes,
                isObligatory: true,
                isSelect: true,
                component: 'L',
            })
            );
            break;
        default: break;
    }

    // Horas y Minutos
    if (entryStructure.hoursMinutes) {
        columns.push({
            headerName: "Horas y Minutos (hh:mm)",
            field: "Horas y Minutos (hh:mm)",
            isObligatory: isObligatory(entryStructure.hoursMinutes),
            maxLength: 254,
            minLength: 0,
            component: 'T',
        });
    }

    // Cantidad
    if (entryStructure.quantity) {
        columns.push({
            headerName: "Cantidad",
            field: "Cantidad",
            isObligatory: isObligatory(entryStructure.quantity),
            maxLength: 254,
            minLength: 0,
            isNumeric: true,
            validationUnrequired: true,
            component: getComponent(decimals["Cantidad"]),
        });
    }

    // Valor informado
    if (entryStructure.informedValue) {
        if (entryStructure.informedValueType === "L") {
            columns.push({
                headerName: "Valor informado líquido",
                field: "Valor informado líquido",
                isObligatory: isObligatory(entryStructure.informedValue),
                maxLength: 254,
                minLength: 0,
                isNumeric: true,
                validationUnrequired: true,
                component: getComponent(decimals["Valor informado líquido"]),
            });
        } else {
            columns.push({
                headerName: "Valor informado bruto",
                field: "Valor informado bruto",
                isObligatory: isObligatory(entryStructure.informedValue),
                maxLength: 254,
                minLength: 0,
                isNumeric: true,
                validationUnrequired: true,
                isQuantityEntryPerConceptSet: false,
                component: getComponent(decimals["Valor informado bruto"]),
            });
        }
    }

    // Porcentaje
    if (entryStructure.percentage) {
        columns.push({
            headerName: "Porcentaje",
            field: "Porcentaje",
            isObligatory: isObligatory(entryStructure.percentage),
            maxLength: 254,
            minLength: 0,
            isNumeric: true,
            validationUnrequired: true,
            component: getComponent(decimals["Porcentaje"]),
        });
    }

    // Base de calculo
    if (entryStructure.calculationBase) {
        columns.push({
            headerName: "Base de cálculo",
            field: "Base de cálculo",
            isObligatory: isObligatory(entryStructure.calculationBase),
            maxLength: 254,
            minLength: 0,
            isNumeric: true,
            validationUnrequired: true,
            component: getComponent(decimals["Base de cálculo"]),
        });
    }

    // Datos para reliquidación - Período de reliquidación - Meses a reliquidar
    if (entryStructure.reliquidation) {
        columns.push({
            headerName: "Período de reliquidación",
            field: "Período de reliquidación",
            isObligatory: isObligatory(entryStructure.reliquidation),
            component: 'P',
        });

        columns.push({
            headerName: "Meses a reliquidar",
            field: "Meses a reliquidar",
            isObligatory: isObligatory(entryStructure.reliquidation),
            isSelect: true,
            component: 'L',
        });
    }

    // Honorarios - Número de comprobante
    if (entryStructure.honoraries) {
        columns.push({
            headerName: "Número de comprobante",
            field: "Número de comprobante",
            isObligatory: isObligatory(entryStructure.honoraries),
            maxLength: 254,
            minLength: 0,
            component: 'E',
        });
    }

    // Pago a terceros
    if (entryStructure.thirdPartyPayment) {
        columns.push({
            headerName: "Tercero",
            field: "Tercero",
            isObligatory: isObligatory(entryStructure.thirdPartyPayment),
            isSelect: true,
            component: 'L',
        })
        columns.push({
            headerName: "Forma de pago",
            field: "Forma de pago",
            isObligatory: isObligatory(entryStructure.thirdPartyPayment),
            isSelect: true,
            component: 'L',
        })
        columns.push({
            headerName: "Tipo de pago",
            field: "Tipo de pago",
            isObligatory: isObligatory(entryStructure.thirdPartyPayment),
            isSelect: true,
            component: 'L',
        })
        columns.push({
            headerName: "Banco",
            field: "Banco",
            isObligatory: false,
            isSelect: true,
            component: 'L',
        })

        columns.push({
            headerName: "Cuenta bancaria",
            field: "Cuenta bancaria",
            isObligatory: false,
            maxLength: 254,
            minLength: 0,
            component: 'E',
        });

        columns.push({
            headerName: "Comentarios del pago al tercero",
            field: "Comentarios del pago al tercero",
            isObligatory: false,
            maxLength: 254,
            minLength: 0,
            component: 'E',
        });
    }

    // Periodo de imputacion
    if (entryStructure.imputationPeriod) {
        columns.push({
            headerName: "Período de imputación",
            field: "Periodo de imputación",
            isObligatory: isObligatory(entryStructure.imputationPeriod),
            component: 'P',
        });
    }

    // Centro de costo
    if (entryStructure.benefitCenter) {
        columns.push({
            headerName: "Centro de costo",
            field: "Centro de costo",
            isObligatory: isObligatory(entryStructure.benefitCenter),
            isSelect: true,
            component: 'L',
        })
    }

    // Proyecto
    if (entryStructure.project) {
        columns.push({
            headerName: "Proyecto",
            field: "Proyecto",
            isObligatory: isObligatory(entryStructure.project),
            isSelect: true,
            component: 'L',
        })
    }

    // Código de referencia
    if (entryStructure.codeRef) {
        columns.push({
            headerName: "Código de referencia",
            field: "Código de referencia",
            isObligatory: Boolean(entryStructure.codeRef),
            isSelect: true,
            component: 'L',
        })
    }

    // Anticipo - Fecha de descuento
    if (entryStructure.advancePayment) {
        columns.push({
            headerName: "Fecha de descuento",
            field: "Fecha de descuento",
            isDate: true,
            isObligatory: isObligatory(entryStructure.advancePayment),
            component: 'DS',
        });
    }

    // Indicador de cálculo
    if (entryStructure.calculationIndicator) {
        columns.push({
            headerName: "Indicador de cálculo",
            field: "Indicador de cálculo",
            isObligatory: Boolean(entryStructure.calculationIndicator),
            isSelect: true,
            component: 'L',
        })
    }

    columns.push({
        headerName: "Comentarios",
        field: "Comentarios",
        maxLength: 254,
        minLength: 0,
        component: 'E',
    });

    return columns;
};

const timeToDecimal = t => {
    if (t) {
        let arr = t.split(':');
        let dec = parseInt((arr[1] / 6) * 10, 10);

        return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
    }
}

const isValidEntryPerConceptHoursMinutesValidSyntax = e => {
    let data = e ? `${e.getHours()}:${e.getMinutes()}` : "";
    if (data !== undefined && data !== null && data !== "" && data !== "") {
        return /^(\d|0\d|1\d|2[0-3]):[0-5]\d$/.test(data)
    }
    return true;
}

const isValidEntryPerConceptHoursMinutes = (e, colaboratorData) => {
    const value = e ? `${e.getHours()}:${e.getMinutes()}` : "";
    if (value !== undefined && value !== null && value !== "" && value !== "") {
        const data = colaboratorData;
        if (data && data["HORAS DIARIAS CONTRATO"] !== null && data["HORAS DIARIAS CONTRATO"] !== "") {
            return timeToDecimal(value) <= parseFloat(data["HORAS DIARIAS CONTRATO"]);
        }
    }
    return true;
}

const isValidMax3Decimals = e => {
    if (parseFloat(String(e).replace(',', '.')) === 0) return false;
    if (e !== undefined && e !== null && e !== "" && e !== "") {
        return /^\d+(\.\d{3})*(\.\d{0,3})*$/.test(e);
    }
    return true;
}

const isValidMax3DecimalsAllowZero = e => {
    if (e !== undefined && e !== null && e !== "" && e !== "") {
        return /^\d+(\.\d{3})*(\.\d{0,3})*$/.test(e);
    }
    return true;
}

const isValidMax5Decimals = e => {
    if (parseFloat(String(e).replace(',', '.')) === 0) return false;
    if (e !== undefined && e !== null && e !== "" && e !== "") {
        return /^([1-9]\d{0,2}(\.?\d{3})*|0)(\.(\d{1,5}))?$/.test(e);
    }
    return true;
}

const isValidMax5DecimalsAllowZero = e => {
    if (e !== undefined && e !== null && e !== "" && e !== "") {
        return /^([1-9]\d{0,2}(\.?\d{3})*|0)(\.(\d{1,5}))?$/.test(e);
    }
    return true;
}

const isValidCustomDecimalsAllowNegative = (e, decimals) => {
    if (parseFloat(String(e).replace(',', '.')) === 0) return false;
    if (e !== undefined && e !== null && e !== "") {
        const re = new RegExp(`^-?\\d+(\\.\\d{${decimals}})*(\\.\\d{0,${decimals}})*$`);
        return re.test(e);
    }
    return true;
}

const isValidCustomDecimalsAllowZeroAllowNegative = (e, decimals) => {
    if (e !== undefined && e !== null && e !== "") {
        const re = new RegExp(`^-?\\d+(\\.\\d{${decimals}})*(\\.\\d{0,${decimals}})*$`);
        return re.test(e);
    }
    return true;
}

const isValidCustomDecimals = (e, decimals) => {
    if (parseFloat(String(e).replace(',', '.')) === 0) return false;
    if (e !== undefined && e !== null && e !== "") {
        const re = new RegExp(`^\\d+(\\.\\d{3})*(\\.\\d{0,${decimals}})*$`);
        return re.test(e);
    }
    return true;
}

const isValidCustomDecimalsAllowZero = (e, decimals) => {
    if (e !== undefined && e !== null && e !== "") {
        const re = new RegExp(`^\\d+(\\.\\d{3})*(\\.\\d{0,${decimals}})*$`);
        return re.test(e);
    }
    return true;
}

const isValidPeriodInverted = e => {
    if (e !== undefined && e !== null && e !== "") {
        return /20\d{2}\/(0[1-9]|10|11|12)$/.test(e);
    }
    return true;
}

const isValidPeriodFromTo = (dateStart, dateEnd) => {
    if (dateStart && dateEnd) {
        // Convierto a date y veo si fallan
        const datePeriodMin = moment(dateStart);
        const datePeriodMax = moment(dateEnd);

        return datePeriodMin.isSameOrBefore(datePeriodMax);
    }
    return true;
}

const isValidPercentage = (e, decimals = 2) => {
    if (parseFloat(String(e).replace(',', '.')) === 0) return false;
    if (e !== undefined && e !== null && e !== "") {
        const re = new RegExp(`(^100(\\.0{1,3})?$)|(^([1-9](\\d)?|0)(\\.\\d{0,${decimals}})?$)`);
        return re.test(e);
    }
    return true;
}

const isValidPercentageAllowZero = (e, decimals = 2) => {
    if (e !== undefined && e !== null && e !== "") {
        const re = new RegExp(`(^100(\\.0{1,2})?$)|(^([1-9](\\d)?|0)(\\.\\d{0,${decimals}})?$)`);
        return re.test(e);
    }
    return true;
}

export {
    getColumnDefs,
    getFields,
    isValidPercentageAllowZero,
    isValidPercentage,
    isValidPeriodFromTo,
    isValidPeriodInverted,
    isValidCustomDecimalsAllowZero,
    isValidCustomDecimals,
    isValidMax3DecimalsAllowZero,
    isValidMax3Decimals,
    isValidMax5DecimalsAllowZero,
    isValidMax5Decimals,
    isValidCustomDecimalsAllowNegative,
    isValidCustomDecimalsAllowZeroAllowNegative,
    isValidEntryPerConceptHoursMinutesValidSyntax,
    isValidEntryPerConceptHoursMinutes,
}