import React, { useRef } from "react";
import { DialogTitle, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import paths from "@icarius-localization/paths";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import CommonPage from "@icarius-common/commonPage";
import { useDispatch } from "react-redux";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "85%",
  },
})(Dialog);

const MyPeopleGridDialogIsOpen = (props) => {

  const {
    open,
    data,
    dateFormat,
    locale,
    handleConfirm,
    handleClose,
  } = props;

  const ownGridRef = useRef();
  const dispatch = useDispatch();

  const submit = () => {
    if (ownGridRef.current) {
      let selectedRows = ownGridRef.current.api.getSelectedRows();

      // Si no hay seleccionado, pido seleccionar
      if (selectedRows.length === 0) {
        dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
        return;
      }

      const colabCodes = selectedRows.map(row => row["CODIGO DE EMPLEADO"]);
      handleConfirm(colabCodes);
      handleClose();
    }
  };

  return (
    <StyledDialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {'Replica de registro'}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <CommonPage
          ownGridRef={ownGridRef}
          gridTitle={'Lista de colaboradores'}
          columnDefPage={paths.myPeople}
          rowData={data}
          dateFormat={dateFormat}
          locale={locale}
          gridHeight={'70vh'}
          ignoreProcessesbyPath
        />
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={'Cerrar'} />
        <ButtonDialogAction onClick={submit} isAccept text={"Agregar con replica de datos"} />
      </DialogActions>
    </StyledDialog>
  );
}

export default MyPeopleGridDialogIsOpen;