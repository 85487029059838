import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import DataItem from "./data/dataItem";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from "react-redux";
import { orderAssetsAndDiscountsCreationAction } from "../actions";

const PublicationTypeList = (props) => {
  const { index, publicationArr, color, title, payrollSelected, isLoading } = props;
  const [items, setItems] = useState(publicationArr);
  const dispatch = useDispatch();

  useEffect(() => {
    // actualizar setItems cuando el array se modifique
    setItems(publicationArr)
  }, [publicationArr])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
  };

  const handleSaveOrder = () => {
    const dataToSend = items.map((item, index) => ({ code: item.code, order: index + 1 }))
    dispatch(orderAssetsAndDiscountsCreationAction({ payrollType: payrollSelected, items: dataToSend }));
  }

  return (
    <div style={{ marginTop: 15, width: "100%" }} key={index}>
      <Typography style={{ marginBottom: "-10px", padding: "6px 16px", fontWeight: 400 }} className="whiteText">
        {title}
      </Typography>
      <div style={{ padding: "0px 16px 6px 16px" }}>
        <DialogTitleDivider />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {
            provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {
                  items.map((item, index) => (
                    <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
                      <DataItem
                        key={item.code}
                        index={index}
                        data={item}
                        color={color}
                        payrollSelected={payrollSelected}
                        isLoading={isLoading}
                      />
                    </Grid>
                  ))
                }
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={handleSaveOrder} text={'Guardar orden'} />
      </Grid>
    </div>
  )
}

export default PublicationTypeList;