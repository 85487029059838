import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip, ListItem } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon, FileCopyIcon } from "@icarius-icons";
import Form from "./form";
import { deleteAssetsAndDiscountsCreationAction } from "../../actions";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from "@material-ui/core/styles";
import DuplicateDialog from "../dialogs/duplicateDialog";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { openDialogAction } from "@icarius-common/dialog/actions";

const useStyles = makeStyles({
  noPadding: {
    padding: '0px'
  }
});

const DataItem = (props) => {

  const { data, index, color, payrollSelected, isLoading } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);

  const handleDelete = () => {
    dispatch(deleteAssetsAndDiscountsCreationAction({
      payrollType: payrollSelected,
      code: data.code,
      name: data.name,
    }));
  }

  const handleClose = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: "Eliminar concepto",
      msg: "¿Desea eliminar el concepto?",
      onConfirm: handleDelete,
    }));
  }

  const publicationItem = (
    <Card style={{ width: "100%", borderRadius: 10, background: (isOpen) ? color : "" }}>
      <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
        <Grid container item xs={1}>
          <DragIndicatorIcon />
        </Grid>
        <Grid container item xs={10} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClose}>
          <DataContainer sm={12} md={2} lg={2} text={data.code} />
          <DataContainer sm={12} md={4} lg={4} text={data.name} />
          <DataContainer sm={12} md={3} lg={3} text={`ID: ${data.conceptID}`} />
          <DataContainer sm={12} md={3} lg={3} text={`Secuencia: ${data.order}`} />
        </Grid>
        <Grid container direction="row" item xs={1} justify="space-between">
          <Tooltip title={getLocalizedString("duplicate")}>
            <IconButton size={"small"} disableRipple onClick={() => setDuplicateDialogIsOpen(true)}>
              <FileCopyIcon style={{ fill: "var(--icons)" }} fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={getLocalizedString("delete")}>
            <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
              <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  )

  return (
    <>
      <Draggable draggableId={data.code} index={index}>
        {
          (provided) => (
            <ListItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={classes.noPadding}
            >
              {publicationItem}
            </ListItem>
          )
        }
      </Draggable>
      {
        isOpen &&
        <Form
          color={color}
          data={data}
          payrollSelected={payrollSelected}
          handleClose={handleClose}
        />
      }
      {
        duplicateDialogIsOpen && !isLoading &&
        <DuplicateDialog
          open={duplicateDialogIsOpen}
          data={data}
          color={color}
          payrollSelected={payrollSelected}
          handleCloseForm={handleClose}
          handleClose={() => setDuplicateDialogIsOpen(false)}
        />
      }
    </>
  );
}

export default DataItem;
