import React, { useEffect, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import FabButton from "@icarius-common/fabButton";
import UploadFileDialog from "./uploadFileDialog";
import { Grid, Typography } from "@material-ui/core";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { useDispatch, useSelector } from "react-redux";
import { RESET_STATE } from "../actionTypes";
import { getIsLoading } from "../selectors";

const EntryByImport = ({ history }) => {

  const [uploadDialogIsOpen, setUploadDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    return () => { dispatch({ type: RESET_STATE }); }
  }, [dispatch])

  const handleGoBack = () => {
    history.push(paths.assetsAndDiscountsScreenSelector);
  }

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />
  )

  let customItems = [goBackButton];

  return (
    <CommonPage
      title={`Haberes y descuentos - Ingreso vía importación`}
      menuItems={customItems}
      isLoading={isLoading}
      isNotGridPage
    >
      <Grid container direction="column" alignItems="center" item xs={12} style={{ margin: "10px 0px" }}>
        <Grid item>
          <FabButton
            ignoreIcon
            useColorBackground
            useWhiteTextAlways
            onClick={() => setUploadDialogIsOpen(true)}
            title={'Buscar archivo excel'}
          />
        </Grid>
        <Grid item>
          <Typography className="whiteText" align="center" style={{ paddingTop: 50, fontSize: 22, fontWeight: 600, maxWidth: 800 }}>
            {"Considere que el archivo seleccionado debe tener una estructura compatible con las estructuras de ingreso para Haberes y Descuentos."}
          </Typography>
          <Typography className="whiteText" align="center" style={{ paddingTop: 8, fontSize: 20, maxWidth: 800, margin: "0 auto" }}>
            {"Si no está seguro de la estructura que debe utilizar, acceda a la función de Haberes y Descuentos, seleccione una modalidad de ingreso, y dentro de ella seleccione la estructura deseada. Luego, pulse el ícono para exportar estructura de importación."}
          </Typography>
        </Grid>
      </Grid>
      {
        uploadDialogIsOpen &&
        <UploadFileDialog
          open={uploadDialogIsOpen}
          isLoading={isLoading}
          handleClose={() => setUploadDialogIsOpen(false)}
        />
      }
    </CommonPage>
  );
}

export default EntryByImport;
