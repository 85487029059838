import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getAssetDiscountsBasicElementsAPI,
    getAssetDiscountsGivenEntryStructureAPI,
    setAssetDiscountsEntryPerConceptsAPI,
} from "@icarius-connection/api";

export const getAssetDiscountsBasicElementsFromServer = (type) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_BASIC_ELEMENTS });
    try {
        let response = await getAssetDiscountsBasicElementsAPI(`?type=${type}`);
        const { entryStructures, societyPeople, colaborators, currency_localization, date_format, informedValueTypeTypes, columnTypes, quantityPerUsedEntryStructure } = response.data;

        dispatch({
            type: actionTypes.GET_BASIC_ELEMENTS_FULFILLED,
            payload: {
                entryStructures,
                societyPeople,
                colaborators,
                currency_localization,
                date_format,
                informedValueTypeTypes,
                columnTypes,
                quantityPerUsedEntryStructure
            },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_BASIC_ELEMENTS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const getAssetDiscountsGivenEntryStructureFromServer = (type, code) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await getAssetDiscountsGivenEntryStructureAPI(`?type=${type}&entryStructure=${code}`);
        const { result, concepts, conceptsTypes, selects, quantityPerUsedEntryStructure } = response.data;
        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows: result, concepts, conceptsTypes, selects, quantityPerUsedEntryStructure },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const setAssetDiscountsEntryPerConceptsToServer = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await setAssetDiscountsEntryPerConceptsAPI(data);
        const { result, concepts, conceptsTypes, selects, quantityPerUsedEntryStructure } = response.data;
        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows: result, concepts, quantityPerUsedEntryStructure, conceptsTypes, selects },
        });
        dispatch(openSnackbarAction({ msg: 'Valores actualizados con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo Tipo, Antiguedad Desde y Antiguedad Hasta", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};