import * as actionTypes from "./actionTypes";
import {
  getAssetsDiscountsEntryByInstallmentsAPI,
  deleteAssetsDiscountsEntryByInstallmentAPI,
  createAssetsDiscountsEntryByInstallmentAPI,
  modifyAssetsDiscountsEntryByInstallmentAPI,
  initialGetAssetsDiscountsEntryByInstallmentsAPI,
  downloadImportStructureApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { downloadBlob } from "@icarius-utils/download";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getAssetDiscountsBasicElementsFromServer = (type) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_BASIC_ELEMENTS });
  try {
    let response = await initialGetAssetsDiscountsEntryByInstallmentsAPI();
    const {
      entryStructures, societyPeople,
      colaborators, currency_localization,
      date_format, informedValueTypeTypes,
      columnTypes, quantityPerUsedEntryStructure
    } = response.data;

    dispatch({
      type: actionTypes.GET_BASIC_ELEMENTS_FULFILLED,
      payload: {
        entryStructures,
        societyPeople,
        colaborators,
        currency_localization,
        date_format,
        informedValueTypeTypes,
        columnTypes,
        quantityPerUsedEntryStructure
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_BASIC_ELEMENTS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getAssetsDiscountsEntryByInstallmentsAction = (type) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getAssetsDiscountsEntryByInstallmentsAPI(`?entryStructure=${type}`);

    let data = response.data && response.data.result;
    let quantityPerUsedEntryStructure = response.data && response.data.quantityPerUsedEntryStructure;
    let selects = response.data && response.data.selects;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, selects, quantityPerUsedEntryStructure },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteAssetsDiscountsEntryByInstallmentAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteAssetsDiscountsEntryByInstallmentAPI(permission);

    let data = response.data && response.data.result;
    let quantityPerUsedEntryStructure = response.data && response.data.quantityPerUsedEntryStructure;
    let selects = response.data && response.data.selects;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data, selects, quantityPerUsedEntryStructure },
    });

    dispatch(openSnackbarAction({ msg: 'Prestamo eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createAssetsDiscountsEntryByInstallmentAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createAssetsDiscountsEntryByInstallmentAPI(permission);

    let data = response.data && response.data.result;
    let quantityPerUsedEntryStructure = response.data && response.data.quantityPerUsedEntryStructure;
    let selects = response.data && response.data.selects;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data, selects, quantityPerUsedEntryStructure },
    });

    dispatch(openSnackbarAction({ msg: 'Prestamo creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyAssetsDiscountsEntryByInstallmentAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyAssetsDiscountsEntryByInstallmentAPI(permission);

    let data = response.data && response.data.result;
    let quantityPerUsedEntryStructure = response.data && response.data.quantityPerUsedEntryStructure;
    let selects = response.data && response.data.selects;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data, selects, quantityPerUsedEntryStructure },
    });

    dispatch(openSnackbarAction({ msg: 'Prestamo actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadImportStructureAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadImportStructureApi(dataToSend);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};