import * as actionTypes from "./actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  columnTypes: [],
  quantityPerUsedEntryStructure: [],
  entryStructures: [],
  societyPeople: [],
  colaborators: [],
  selects: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_BASIC_ELEMENTS:
      return { ...state, loadingBasicElements: true, isLoading: true };
    case actionTypes.GET_BASIC_ELEMENTS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        entryStructures: action.payload.entryStructures.filter(el => el.periodicity === "CP" || el.periodicity === "C"),
        societyPeople: action.payload.societyPeople,
        colaborators: action.payload.colaborators,
        columnTypes: action.payload.columnTypes,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };

    case actionTypes.GET_REJECTED:
    case actionTypes.DELETE_REJECTED:
    case actionTypes.CREATE_REJECTED:
    case actionTypes.MODIFY_REJECTED:
    case actionTypes.DOWNLOAD_FULFILLED:
    case actionTypes.GET_BASIC_ELEMENTS_REJECTED:
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
    case actionTypes.CREATE:
    case actionTypes.MODIFY:
    case actionTypes.DOWNLOAD:
    case actionTypes.GET:
      return { ...state, isLoading: true };

    case actionTypes.DELETE_FULFILLED:
    case actionTypes.GET_FULFILLED:
    case actionTypes.CREATE_FULFILLED:
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        selects: action.payload.selects,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
