import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET_ROWS = NAME + "/GET_ROWS";
export const GET_ROWS_FULFILLED = NAME + "/GET_ROWS_FULFILLED";
export const GET_ROWS_REJECTED = NAME + "/GET_ROWS_REJECTED";

export const GET_BASIC_ELEMENTS = NAME + "/GET_BASIC_ELEMENTS";
export const GET_BASIC_ELEMENTS_FULFILLED = NAME + "/GET_BASIC_ELEMENTS_FULFILLED";
export const GET_BASIC_ELEMENTS_REJECTED = NAME + "/GET_BASIC_ELEMENTS_REJECTED";

export const SET_ROWS = NAME + "/SET_ROWS";
export const SET_ROWS_FULFILLED = NAME + "/SET_ROWS_FULFILLED";
export const SET_ROWS_REJECTED = NAME + "/SET_ROWS_REJECTED";

export const DELETE_ROWS = NAME + "/DELETE_ROWS";
export const DELETE_ROWS_FULFILLED = NAME + "/DELETE_ROWS_FULFILLED";
export const DELETE_ROWS_REJECTED = NAME + "/DELETE_ROWS_REJECTED";

export const DOWNLOAD = NAME + "/DOWNLOAD";
export const DOWNLOAD_FULFILLED = NAME + "/DOWNLOAD_FULFILLED";
export const DOWNLOAD_REJECTED = NAME + "/DOWNLOAD_REJECTED";