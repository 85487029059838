import NAME from "./constants";

export const getEntryStructures = store => store[NAME].entryStructures;
export const getColaborators = store => store[NAME].colaborators;
export const getLoading = store => store[NAME].loading || store[NAME].loadingBasicElements;
export const getColumnTypes = store => store[NAME].columnTypes;
export const getRows = store => store[NAME].rows;
export const getConceptsTypes = store => store[NAME].conceptsTypes;
export const getConcepts = store => store[NAME].concepts;
export const getSelects = store => store[NAME].selects;
export const getQuantityPerUsedEntryStructure = store => store[NAME].quantityPerUsedEntryStructure;