import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssetsAndDiscountsCreationPayrollTypesAction,
  getAssetsAndDiscountsCreationAction
} from "../actions";
import { getLoading, getData, getPayrollTypes } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import PublicationTypeList from './publicationTypeList';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from "@material-ui/core";

const AssetsDiscountsCreation = ({ history }) => {

  const dispatch = useDispatch();
  const [payrollSelected, setPayrollSelected] = useState('');

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const payrollTypes = useSelector(getPayrollTypes);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getAssetsAndDiscountsCreationPayrollTypesAction());
    return () => {
      dispatch({ type: RESET_STATE })
    }
  }, [dispatch])

  useEffect(() => {
    if (payrollSelected) {
      dispatch(getAssetsAndDiscountsCreationAction(payrollSelected));
    }
  }, [dispatch, payrollSelected])

  const handleGoBack = () => {
    history.push(paths.assetsAndDiscountsScreenSelector);
  }

  const getDataIsEmpty = () => {
    if (!data) return true;
    const arrayNames = Object.keys(data);
    return arrayNames.every(name => data[name].length === 0);
  }

  const selectInput = (
    <div style={{ minWidth: 300 }}>
      <FormControl fullWidth style={{ width: "100%" }}>
        <InputLabel required id={`label-family-payroll`}>{"Seleccione tipo de nómina"}</InputLabel>
        <Select
          value={payrollSelected}
          labelId={`label-payroll`}
          id={`select-family-payroll`}
          onChange={(e) => setPayrollSelected(e.target.value)}
          margin={"none"}
        >
          {
            payrollTypes && payrollTypes.map(item => (
              <MenuItem
                className={"whiteText"}
                key={item.key}
                value={item.key}>
                {item.value}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  )

  const customItems = [selectInput]

  return (
    <ABMLayout
      title={'Creación de haberes y descuentos'}
      isLoading={isLoading}
      showGoBack
      handleGoBack={handleGoBack}
      customItems={customItems}
    >
      {
        data && Object.keys(data).map((publicationArr, index) => {
          return (
            data[publicationArr].length > 0 ? (
              <PublicationTypeList
                key={index}
                index={index}
                publicationArr={data[publicationArr]}
                title={publicationArr}
                color={color}
                isLoading={isLoading}
                payrollSelected={payrollSelected}
              />
            ) : null
          )
        })
      }
      {
        payrollSelected && !isLoading && getDataIsEmpty() &&
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
          <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
            {"No se encontraron haberes y descuentos"}
          </Typography>
        </Grid>
      }
      {
        !payrollSelected && !isLoading &&
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
          <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
            {"Seleccione tipo de nómina para comenzar"}
          </Typography>
        </Grid>
      }
    </ABMLayout>
  )
}

export default AssetsDiscountsCreation;