import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getQuantityPerScreenAPI,
} from "@icarius-connection/api";

export const getQuantityPerScreenAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_QUANTITY_PER_SCREEN });
  try {
    let response = await getQuantityPerScreenAPI();
    let quantityPerScreen = response.data && response.data.result;

    dispatch({
      type: actionTypes.GET_QUANTITY_PER_SCREEN_FULFILLED,
      payload: { quantityPerScreen },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_QUANTITY_PER_SCREEN_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};