import * as actionTypes from "./actionTypes";
import {
  getAssetsAndDiscountsCreationPayrollTypesAPI,
  getAssetsAndDiscountsCreationAPI,
  modifyAssetsAndDiscountsCreationAPI,
  orderAssetsAndDiscountsCreationAPI,
  deleteAssetsAndDiscountsCreationAPI,
  duplicateAssetsAndDiscountsCreationOrderAPI
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "PAYROLL_TYPE_NOT_EXISTS":
      errorString = 'El código de tipo de nomina no existe en la base de datos';
      break;
    case "CONCEPT_STRUCTURE_NOT_EXISTS":
      errorString = 'La estructura de conceptos no existe';
      break;
    case "INVALID_DATA":
      errorString = 'Alguno de los códigos de concepto ingresados no existe en la base de datos.';
      break;
    case "VALIDATION_ERROR":
      errorString = 'Error de validación.';
      break;
    case "UNAUTHORIZED":
      errorString = 'Error de permisos del usuario';
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un concepto con el mismo nombre.';
      break;
    case "ERROR_UPDATE":
      errorString = 'Error.';
      break;
    case "CONCEPT_IN_USE":
      errorString = 'El concepto está en uso por otras tablas.';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getAssetsAndDiscountsCreationPayrollTypesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PAYROLL });
  try {
    let response = await getAssetsAndDiscountsCreationPayrollTypesAPI();

    let payrollTypes = response.data && response.data.payrollTypes;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_PAYROLL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_PAYROLL_FULFILLED,
      payload: { payrollTypes },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_PAYROLL_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getAssetsAndDiscountsCreationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getAssetsAndDiscountsCreationAPI(`?payrollType=${dataToSend}`);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteAssetsAndDiscountsCreationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteAssetsAndDiscountsCreationAPI(dataToSend);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Eliminación exitosa", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyAssetsAndDiscountsCreationAction = (publication) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyAssetsAndDiscountsCreationAPI(publication);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Modificación exitosa", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const duplicateAssetsAndDiscountsCreationOrderAction = (publication) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateAssetsAndDiscountsCreationOrderAPI(publication);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DUPLICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Duplicación exitosa", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const orderAssetsAndDiscountsCreationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.ORDER });
  try {
    let response = await orderAssetsAndDiscountsCreationAPI(dataToSend);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ORDER_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.ORDER_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Orden guardado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ORDER_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};