import * as yup from 'yup';
import { useState } from "react";
import { createDateFromDDMMYYYY, createDateFromHHMM, createDateFromYYYYMM, formatDateHHMM, formatDateYYYYMM, formatDate } from "@icarius-utils/date";
import { useDispatch } from 'react-redux';
import { openSnackbarAction } from '@icarius-common/snackbar/actions';
import {
  isValidPercentageAllowZero,
  isValidPercentage,
  isValidPeriodFromTo,
  isValidMax5DecimalsAllowZero,
  isValidMax5Decimals,
  isValidEntryPerConceptHoursMinutesValidSyntax,
  isValidEntryPerConceptHoursMinutes,
  isValidCustomDecimalsAllowZero,
  isValidCustomDecimals,
  isValidCustomDecimalsAllowZeroAllowNegative,
  isValidCustomDecimalsAllowNegative,
} from "../../columns";

const useHandleForm = (data, fields, createCallback, modifyCallback, invalidDataCallback, months, selects, concepts, conceptsTypes, societyPeople, colaborators, decimals) => {
  const dispatch = useDispatch();
  const isCreate = !Boolean(data);

  const getOptions = (field) => {
    if (months.includes(field.headerName)) {
      return [{ key: "N", value: "No" }, { key: "Y", value: "Si" }]
    }
    switch (field.headerName) {
      case "Meses a reliquidar":
        const mesesReliquidacion = [];
        for (let i = 1; i <= 36; i++) {
          mesesReliquidacion.push({ key: `${i}`, value: `${i}` })
        }
        return mesesReliquidacion;
      case "Tipo de concepto":
        return conceptsTypes;
      case "Indicador de cálculo":
        return selects.calculationIndicators;
      case "Código de referencia":
        return selects.codeRefs;
      case "Proyecto":
        return selects.projects;
      case "Banco":
        return selects.banks;
      case "Forma de pago":
        return selects.paymentMethods;
      case "Tercero":
        return selects.thirdParties;
      case "Tipo de pago":
        return selects.paymentTypes;
      case "Centro de costo":
        return selects.benefitCenters;
      case "Concepto":
        const element = conceptsTypes.find(el => el.key === data);
        if (element && element.value) {
          return concepts[element.value];
        }
        return [];
      case "all-concepts":
        let testArr = []
        conceptsTypes.forEach(el => testArr.push(...concepts[el.value].map(concept => ({ ...concept, "Tipo de concepto": el.key }))));
        return testArr;
      default:
        return [];
    }
  }

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      const fieldItem = fields.find(el => el.field === fieldName);
      if (isCreate) {
        if (fieldItem.headerName === 'Apellido y nombres') {
          return null;
        }

        if (fieldItem.headerName === 'En fecha') {
          return new Date();
        }

        if (months.includes(fieldItem.headerName)) {
          // Pongo por defecto el valor N
          return 'N';
        }

        if (fieldItem.component === 'L') {
          if (fieldItem.isObligatory && getOptions(fieldItem).length === 1) return getOptions(fieldItem)[0].key; // si es obligatorio y tiene una opcion sola, autosetear esa
          return ''; // sino, inicializar en vacio
        }

        if (["N2", "N5"].includes(fieldItem.component)) return '';
        if (["D", "DS", "P", "T"].includes(fieldItem.component)) return null;

        return '';
      }

      // Si es el autocomplete, tengo que formatear la data
      if (fieldItem.component === 'A') {
        const element = colaborators.find(el => el.key === data["Código de empleado"]);
        return element || '';
      }

      if (fieldItem.component === 'L') return data[fieldName] || '';

      if ("D" === fieldItem.component || "DS" === fieldItem.component) return data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : null;
      if ("T" === fieldItem.component) return data[fieldName] ? createDateFromHHMM(data[fieldName]) : null;
      if ("P" === fieldItem.component) return data[fieldName] ? createDateFromYYYYMM(data[fieldName]) : null;

      return data[fieldName] || '';
    }

    const fieldNames = fields.map(el => el.field);

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData);

  const dataIsValid = async () => {
    const getSchema = () => {
      const schema = {};
      fields.forEach(fieldItem => {
        if (fieldItem.component === 'L') {// si usa el autocomplete, va a llegar como objeto aca
          if (fieldItem.isObligatory) {
            schema[fieldItem.headerName] = yup.string().required();
            return;
          }
          schema[fieldItem.headerName] = yup.string().nullable();
          return;
        }

        if (["D", "T", "P"].includes(fieldItem.component)) {
          fieldItem.isObligatory ? schema[fieldItem.headerName] = yup.date().required() : schema[fieldItem.headerName] = yup.date().nullable();
          return;
        }

      });

      return schema;
    }

    const schema = yup.object().shape(getSchema());

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const submit = async () => {
    let hasError = false;
    if (await dataIsValid()) {
      const dataToSend = {
        ...formData,
        "Código de empleado": formData["Apellido y nombres"]
      };
      // Comienzo validaciones
      fields.forEach(fieldItem => {
        if (fieldItem.headerName === "Horas y Minutos (hh:mm)") {
          const isValid = isValidEntryPerConceptHoursMinutesValidSyntax(dataToSend[fieldItem.field]);
          if (!isValid) {
            dispatch(openSnackbarAction({ msg: 'Verifique el formato. El mismo debe ser, por ejemplo, 09:00.', severity: "error" }));
            hasError = true;
            return;
          }
        }

        if ((fieldItem.headerName === "Horas y Minutos (hh:mm)")) {
          const colaboratorData = societyPeople.find(el => el.key === dataToSend["Código de empleado"].key);
          if (colaboratorData) {
            const isValid = isValidEntryPerConceptHoursMinutes(dataToSend[fieldItem.field], colaboratorData);
            if (!isValid) {
              dispatch(openSnackbarAction({ msg: 'La hora ingresada supera la jornada del colaborador', severity: "error" }));
              hasError = true;
              return;
            }
          }
        }

        if (fieldItem.headerName === "Cantidad" || fieldItem.isQuantityEntryPerConceptSet) {
          if (fieldItem.isObligatory) {
            const isValid = isValidMax5Decimals(dataToSend[fieldItem.field]);
            if (!isValid || !dataToSend[fieldItem.field]) {
              dispatch(openSnackbarAction({ msg: 'Solo se permiten 5 decimales. No se puede ingresar 0.', severity: "error" }));
              hasError = true;
              return;
            }
          } else {
            const isValid = isValidMax5DecimalsAllowZero(dataToSend[fieldItem.field]);
            if (!isValid) {
              dispatch(openSnackbarAction({ msg: 'Solo se permiten 5 decimales.', severity: "error" }));
              hasError = true;
              return;
            }
          }
        }

        if (
          fieldItem.headerName === "Cantidad" ||
          fieldItem.headerName === "Valor informado líquido" ||
          fieldItem.headerName === "Porcentaje" ||
          fieldItem.headerName === "Base de cálculo"
        ) {
          if (fieldItem.isObligatory) {
            const isValid = isValidCustomDecimals(dataToSend[fieldItem.field], decimals[fieldItem.headerName]);
            if (!isValid || !dataToSend[fieldItem.field]) {
              dispatch(openSnackbarAction({ msg: `Solo se permiten ${decimals[fieldItem.headerName]} decimales. No se puede ingresar 0.`, severity: "error" }));
              hasError = true;
              return;
            }
          } else {
            const isValid = isValidCustomDecimalsAllowZero(dataToSend[fieldItem.field], decimals[fieldItem.headerName]);
            if (!isValid) {
              dispatch(openSnackbarAction({ msg: `Solo se permiten ${decimals[fieldItem.headerName]} decimales.`, severity: "error" }));
              hasError = true;
              return;
            }
          }
        }

        if (
          fieldItem.headerName === "Valor informado bruto"
        ) {
          if (fieldItem.isObligatory) {
            const isValid = isValidCustomDecimalsAllowNegative(dataToSend[fieldItem.field], decimals[fieldItem.headerName]);
            if (!isValid || !dataToSend[fieldItem.field]) {
              dispatch(openSnackbarAction({ msg: `Solo se permiten ${decimals[fieldItem.headerName]} decimales. No se puede ingresar 0.`, severity: "error" }));
              hasError = true;
              return;
            }
          } else {
            const isValid = isValidCustomDecimalsAllowZeroAllowNegative(dataToSend[fieldItem.field], decimals[fieldItem.headerName]);
            if (!isValid) {
              dispatch(openSnackbarAction({ msg: `Solo se permiten ${decimals[fieldItem.headerName]} decimales.`, severity: "error" }));
              hasError = true;
              return;
            }
          }
        }

        if (fieldItem.headerName.includes("Período desde")) {
          const isValid = isValidPeriodFromTo(dataToSend["Período desde"], dataToSend["Período hasta"]);
          if (!isValid) {
            dispatch(openSnackbarAction({ msg: 'El "Período desde" debe ser menor o igual al "Período hasta"', severity: "error" }));
            hasError = true;
            return;
          }
        }

        if (fieldItem.headerName.includes("Período hasta")) {
          const isValid = isValidPeriodFromTo(dataToSend["Período desde"], dataToSend["Período hasta"]);
          if (!isValid) {
            dispatch(openSnackbarAction({ msg: 'El "Período hasta" debe ser mayor o igual al "Período desde"', severity: "error" }));
            hasError = true;
            return;
          }
        }

        if (fieldItem.headerName === "Porcentaje" || fieldItem.isPercentageEntryPerConceptSet) {
          if (fieldItem.isObligatory) {
            const isValid = isValidPercentage(dataToSend[fieldItem.field], decimals["Porcentaje"]);
            if (!isValid || !dataToSend[fieldItem.field]) {
              dispatch(openSnackbarAction({ msg: 'Ingrese un porcentaje válido', severity: "error" }));
              hasError = true;
              return;
            }
          } else {
            const isValid = isValidPercentageAllowZero(dataToSend[fieldItem.field], decimals["Porcentaje"]);
            if (!isValid) {
              dispatch(openSnackbarAction({ msg: 'Ingrese un porcentaje válido', severity: "error" }));
              hasError = true;
              return;
            }
          }
        }

        if (fieldItem.headerName === "Banco") {
          const paymentType = dataToSend["Forma de pago"];

          if (paymentType === "D" && !Boolean(dataToSend["Banco"])) {
            dispatch(openSnackbarAction({ msg: 'Se requiere Banco para la forma de pago "Depósito"', severity: "error" }));
            hasError = true;
            return;
          }

          return true;
        }

        if (fieldItem.headerName === "Cuenta bancaria") {
          const paymentType = dataToSend["Forma de pago"];

          if (paymentType === "D" && !Boolean(dataToSend["Cuenta bancaria"])) {
            dispatch(openSnackbarAction({ msg: 'Se requiere Cuenta Bancaria para la forma de pago "Depósito"', severity: "error" }));
            hasError = true;
            return;
          }
        }
      });
      // Fin de validaciones

      if (hasError) {
        return;
      }

      //formatear el apellido y nombre, junto al codigo
      dataToSend["Código de empleado"] = formData["Apellido y nombres"].key
      dataToSend["Apellido y nombres"] = formData["Apellido y nombres"].value

      //formatear los type "D", "T", "P"
      const dateTypeKeyFields = fields.filter(field => ["D", "DS", "T", "P"].includes(field.component));
      dateTypeKeyFields.forEach(field => {
        if (field.component === 'D' || field.component === 'DS') {
          dataToSend[field.field] = dataToSend[field.field] ? formatDate(dataToSend[field.field]) : null;
          return;
        }

        if (field.component === 'T') {
          dataToSend[field.field] = dataToSend[field.field] ? formatDateHHMM(dataToSend[field.field]) : null;
          return;
        }

        if (field.component === 'P') {
          dataToSend[field.field] = dataToSend[field.field] ? formatDateYYYYMM(dataToSend[field.field]) : null;
        }
      });

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data["Código interno del registro"] });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "Apellido y nombres") {
      return setFormData({
        ...formData,
        [fieldName]: value,
        "Código de empleado": value.key
      })
    }

    if (fieldName === "Tipo de concepto") {
      return setFormData({
        ...formData,
        [fieldName]: value,
        "Concepto": '',
        "Nombre del concepto": ''
      })
    }

    if (fieldName === "Concepto") {
      const element = getOptions({ headerName: "all-concepts" }).find(el => el.key === value);
      return setFormData({
        ...formData,
        [fieldName]: value,
        "Nombre del concepto": element?.value || ''
      })
    }


    if (fieldName === "Horas y Minutos (hh:mm)") {
      let newValue = '';
      if (value) {
        let dec = parseInt((value.getMinutes() / 6) * 10, 10);
        newValue = parseFloat(parseInt(value.getHours(), 10) + '.' + (dec < 10 ? '0' : '') + dec);
      }

      return setFormData({
        ...formData,
        [fieldName]: value,
        "Cantidad": Number.isFinite(newValue) ? newValue.toFixed(decimals["Cantidad"]) : newValue
      });
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
