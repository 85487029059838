import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import {
  modifyAssetsAndDiscountsCreationAction,
  duplicateAssetsAndDiscountsCreationOrderAction
} from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { withStyles } from "@material-ui/core/styles";

const gridStyle = { height: 75, padding: "0px 10px" };
const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const { data, handleClose, classes, payrollSelected, isDuplicate, handleCloseDuplicate } = props;
  const dispatch = useDispatch();

  const modify = (dataToSend) => {
    dispatch(modifyAssetsAndDiscountsCreationAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const duplicate = (dataToSend) => {
    dispatch(duplicateAssetsAndDiscountsCreationOrderAction(dataToSend))
      .then((res) => res?.status === 200 && handleCloseDuplicate());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, isDuplicate, payrollSelected, modify, duplicate, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!isDuplicate}
            fullWidth
            margin={"none"}
            label={isDuplicate ? "Código del nuevo concepto" : "Código del concepto"}
            value={formData.code}
            onChange={(e) => setFormValue(e.target.value, "code")}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={"Nombre del concepto"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* ID del concepto */}
          <TextField
            fullWidth
            margin={"none"}
            label={"ID del concepto"}
            value={formData.conceptID}
            onChange={(e) => setFormValue(e.target.value, "conceptID")}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* Secuencia */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={"Secuencia de cálculo"}
            value={data.order}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ padding: "10px 10px" }}> {/* Texto de ayuda */}
          <TextField
            fullWidth
            label={"Texto de ayuda"}
            variant="outlined"
            multiline
            rows={5}
            margin={"none"}
            value={formData.dataEntryHelp}
            onChange={(e) => setFormValue(e.target.value, "dataEntryHelp")}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false', maxLength: 250 }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        {
          isDuplicate &&
          <div style={{ paddingRight: 10 }}>
            <ButtonDialogAction onClick={handleCloseDuplicate} text={getLocalizedString("disagree")} />
          </div>
        }
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Form);
