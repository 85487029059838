import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
