import React from "react";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import SelectedUsersColumn from "@icarius-common/userSelectionDialog/components/selectedUsersColumn";
import UserSelectionColumn from "@icarius-common/userSelectionDialog/components/userSelectionColumn";
import useHandleUserSelection from "@icarius-common/userSelectionDialog/useHandleUserSelection";

const UserSelectionDialogWithReplica = (props) => {

  const {
    open,
    singleSelection, //bool indicando si es para seleccionar de a uno o varios
    users, //array de objetos con key, name y FOTOGRAFIA
    initialData, //array de strings con key del primero seleccionado, si no hay, [] o [""]
    title,
    selectedUsersTitle,
    filterPlaceholder, //texto opcional para el placeholder del input
    isDataReplicateDisabled, // para habilitar o deshabilitar la replicación de datos
    handleClose,
    handleSubmit,
  } = props;

  const {
    keysSelected,
    checkedList,
    handleSelection,
  } = useHandleUserSelection(users, initialData, singleSelection);

  const handleSubmitUsers = (dataReplicate) => {
    handleSubmit(keysSelected, dataReplicate);
    handleClose();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container direction="row" justify="space-around">
          <SelectedUsersColumn
            employees={checkedList}
            title={selectedUsersTitle}
            showAmount={!singleSelection}
            handleClick={handleSelection}
          />
          <UserSelectionColumn
            employees={checkedList}
            filterPlaceholder={filterPlaceholder}
            handleClick={handleSelection}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={() => handleSubmitUsers(false)} isAccept text={"Agregar"} />
        {
          !isDataReplicateDisabled &&
          <ButtonDialogAction disabled={isDataReplicateDisabled} onClick={() => handleSubmitUsers(true)} isAccept text={"Agregar con replica de datos"} />
        }
      </DialogActions>
    </Dialog>
  );
}

export default UserSelectionDialogWithReplica;