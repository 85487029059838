import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useAssetsDiscountsSummary from "./useAssetsDiscountsSummary";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import useTemplates from "@icarius-common/templates/components/useTemplates";

const AssetsDiscountsSummary = ({ code, name, handleGoBack, history }) => {

  const comesFromOtherPage = Boolean(code);

  const {
    isLoading,
    data,
    payrollTypes,
    payrollSelected,
    switchIsOn,
    handleChangeSwitch,
    setPayrollSelected,
    handleOpenDeleteDialog,
    handleRowClick,
  } = useAssetsDiscountsSummary(code);

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates(`SUMMARY_${payrollSelected}`);

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => Boolean(handleGoBack) ? handleGoBack() : history.push(paths.assetsAndDiscountsScreenSelector)}
      type={"goBack"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const selectInput = (gridRef) => (
    <>
      <div style={{ minWidth: 220, maxWidth: 220 }}>
        <FormControl fullWidth style={{ width: "100%" }}>
          <InputLabel id={`label-family-payroll`}>{"Seleccione tipo de nómina"}</InputLabel>
          <Select
            value={payrollSelected}
            labelId={`label-payroll`}
            id={`select-family-payroll`}
            onChange={(e) => {
              setPayrollSelected(e.target.value);
              handleSelectTemplateAndUpdateGrid('', gridRef);
            }}
            margin={"none"}
          >
            <MenuItem className={"whiteText"} value={''}>
              {'Todas las nóminas'}
            </MenuItem>
            {
              payrollTypes && payrollTypes.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      <div style={{ paddingLeft: 10, display: 'flex' }}>
        <TemplatesHeader
          gridRef={gridRef}
          user={user}
          templates={templates}
          selectedTemplate={selectedTemplate}
          getRole={getRole}
          handleImportTemplates={handleImportTemplates}
          handleExportTemplates={handleExportTemplates}
          handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
          handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
          handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
          handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
        />
      </div>
    </>
  )

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>

  return (
    <CommonPage
      isLoading={isLoading || isLoadingTemplates}
      title={`Resumen de haberes y descuentos ${name ? `- ${name}` : ''}`}
      gridTitle={`Resumen de haberes y descuentos ${name ? `- ${name}` : ''}`}
      columnDefPage={paths.assetsDiscountsSummary}
      rowData={data}
      handleRowClick={!comesFromOtherPage && handleRowClick}
      customHeader={!comesFromOtherPage && selectInput}
      menuItems={comesFromOtherPage ? [goBackButton] : [goBackButton, deleteButton]}
      hasExpand
      hasHelp
      hasSwitch={!comesFromOtherPage}
      handleSwitchClick={handleChangeSwitch}
      switchValue={switchIsOn}
      switchLabelOff={'Visualizar conceptos por sociedad'}
      switchLabelOn={'Visualizar conceptos por sociedad'}
      codeFieldName={'Código de empleado'}
    >
      {child}
    </CommonPage>
  )
}

export default AssetsDiscountsSummary;