import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getInitialEntryPerConceptAPI,
    getEntryPerConceptMyPeopleApi,
    getEntryPerConceptApi,
    createEntryPerConceptApi,
    modifyEntryPerConceptApi,
    deleteEntryPerConceptApi,
    downloadImportStructureApi,
    duplicateEntryPerConceptApi,
} from "@icarius-connection/api";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getInitialEntryPerConcept = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_BASIC_ELEMENTS });
    try {
        let response = await getInitialEntryPerConceptAPI();
        const { entryStructures, decimals, societyPeople, currency_localization, date_format, informedValueTypeTypes, columnTypes, quantityPerUsedEntryStructure } = response.data;

        dispatch({
            type: actionTypes.GET_BASIC_ELEMENTS_FULFILLED,
            payload: {
                entryStructures,
                societyPeople,
                currency_localization,
                date_format,
                informedValueTypeTypes,
                columnTypes,
                quantityPerUsedEntryStructure,
                decimals
            },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_BASIC_ELEMENTS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const getEntryPerConceptMyPeopleGrid = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_MYPEOPLE_GRID });
    try {
        let response = await getEntryPerConceptMyPeopleApi();
        const { colaborators } = response.data;

        dispatch({
            type: actionTypes.GET_MYPEOPLE_GRID_FULFILLED,
            payload: {
                colaborators
            },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_MYPEOPLE_GRID_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};


export const getEntryPerConcept = (code) => async (dispatch) => {
    dispatch({ type: actionTypes.GET });
    try {
        let response = await getEntryPerConceptApi(`?entryStructure=${code}`);
        const { result, concepts, conceptsTypes, selects, quantityPerUsedEntryStructure } = response.data;
        dispatch({
            type: actionTypes.GET_FULFILLED,
            payload: { rows: result, concepts, conceptsTypes, selects, quantityPerUsedEntryStructure },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const createEntryPerConcept = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.CREATE });
    try {
        let response = await createEntryPerConceptApi(data);
        let quantityPerUsedEntryStructure = response.data && response.data.quantityPerUsedEntryStructure;
        let rows = response.data && response.data.result;
        dispatch({
            type: actionTypes.CREATE_FULFILLED,
            payload: { rows, quantityPerUsedEntryStructure },
        });
        dispatch(openSnackbarAction({ msg: 'Ingreso agregado con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo Tipo, Antiguedad Desde y Antiguedad Hasta", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};
export const modifyEntryPerConcept = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.MODIFY });
    try {
        let response = await modifyEntryPerConceptApi(data);
        let quantityPerUsedEntryStructure = response.data && response.data.quantityPerUsedEntryStructure;
        let rows = response.data && response.data.result;
        dispatch({
            type: actionTypes.MODIFY_FULFILLED,
            payload: { rows, quantityPerUsedEntryStructure },
        });
        dispatch(openSnackbarAction({ msg: 'Ingreso modificado con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo Tipo, Antiguedad Desde y Antiguedad Hasta", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const deleteEntryPerConcept = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE });
    try {
        let response = await deleteEntryPerConceptApi(data);
        let status = response.data && response.data.status;
        let quantityPerUsedEntryStructure = response.data && response.data.quantityPerUsedEntryStructure;
        let rows = response.data && response.data.result;

        if (status !== "OK") {
            dispatch({ type: actionTypes.DELETE_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.DELETE_FULFILLED,
            payload: { rows, quantityPerUsedEntryStructure },
        });

        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DELETE_REJECTED });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const downloadImportStructureAction = (dataToSend) => async (dispatch) => {
    dispatch({ type: actionTypes.DOWNLOAD });
    try {
        let response = await downloadImportStructureApi(dataToSend);
        let title = response.headers["x-content-namefile"];

        if (response.status !== 200) {
            dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.DOWNLOAD_FULFILLED,
        });

        downloadBlob(response.data, getFileExtension(title), getFileName(title));

        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const duplicateEntryPerConcept = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.DUPLICATE });
    try {
        let response = await duplicateEntryPerConceptApi(data);
        let quantityPerUsedEntryStructure = response.data && response.data.quantityPerUsedEntryStructure;
        let rows = response.data && response.data.result;
        dispatch({
            type: actionTypes.DUPLICATE_FULFILLED,
            payload: { rows, quantityPerUsedEntryStructure },
        });
        dispatch(openSnackbarAction({ msg: 'Ingreso replicado con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir valores con el mismo Tipo, Antiguedad Desde y Antiguedad Hasta", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};