import React from "react";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const NumberItem = ({ name, value, onClick, selected }) => {

  const color = useSelector(getAppColor);

  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        border: `1px solid ${color}`,
        borderRadius: 50,
        padding: 5,
        cursor: "pointer",
        margin: 5
      }}
    >
      <Typography variant="h6" gutterBottom style={{ color: selected ? color : `var(--mainText)`, margin: "0px 30px 0px 10px", fontSize: 18 }}>
        {name}
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "gray",
          padding: 10,
          borderRadius: "50%",
          height: 55,
          width: 55,
        }}
      >
        <Typography className="whiteText" variant="h6" gutterBottom style={{ margin: 0, fontSize: 18 }}>
          {value > 999 ? "+999" : value}
        </Typography>
      </div>
    </div>
  );
}

export default NumberItem;
