import * as actionTypes from "./actionTypes";

const initialState = {
  loadingBasicElements: false,
  loading: false,
  entryStructures: [],
  informedValueTypeTypes: [],
  columnTypes: [],
  rows: [],
  selects: {},
  concepts: [],
  conceptsTypes: [],
  quantityPerUsedEntryStructure: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_BASIC_ELEMENTS:
      return { ...state, loadingBasicElements: true };
    case actionTypes.GET_BASIC_ELEMENTS_FULFILLED:
      return {
        ...state,
        loadingBasicElements: false,
        entryStructures: action.payload.entryStructures,
        columnTypes: action.payload.columnTypes,
        informedValueTypeTypes: action.payload.informedValueTypeTypes,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };
    case actionTypes.GET_BASIC_ELEMENTS_REJECTED:
      return { ...state, loadingBasicElements: false };
    case actionTypes.GET_ROWS:
      return { ...state, loading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,
        concepts: action.payload.concepts,
        conceptsTypes: action.payload.conceptsTypes,
        selects: action.payload.selects,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
