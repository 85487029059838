import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadImportStructureAction, getAssetDiscountsBasicElementsFromServer, getAssetsDiscountsEntryByInstallmentsAction } from "../actions";
import { getLoading, getSelects, getData, getSocietyPeople, getQuantityPerUsedEntryStructure, getEntryStructures, getColaborators, getColumnTypes } from "../selectors";
import { getAppColor } from "src/app/selectors";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import { AddIcon } from "@icarius-icons/";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@material-ui/core";
import NumberItem from "./numberItem";
import { useLocation } from "react-router-dom";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";

const gridStyle = { height: 100, padding: "0px 10px" };

const AssetsDiscountsEntryByInstallments = ({ history }) => {
  const location = useLocation();
  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [selectedEntryStructureCode, setSelectedEntryStructureCode] = useState(location?.state?.structureKey || "");
  const entryStructures = useSelector(getEntryStructures);
  const quantityPerUsedEntryStructure = useSelector(getQuantityPerUsedEntryStructure);
  const usedEntryStructures = quantityPerUsedEntryStructure.map(structure => structure.code);
  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);
  const societyPeople = useSelector(getSocietyPeople);
  const colaborators = useSelector(getColaborators);
  const columnTypes = useSelector(getColumnTypes);
  const selects = useSelector(getSelects);
  const [newInstallmentData, setNewInstallmentData] = useState({});

  const getEntryStructure = () => {
    const entryStructure = entryStructures.find(entry => entry.code === selectedEntryStructureCode);
    return entryStructure || { periodicity: '' };
  }

  const handleSetUsers = (values) => {
    const colab = values[0];
    const fullColaboradorData = colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === colab);

    let data = {};
    data["Código de empleado"] = fullColaboradorData["CODIGO DE EMPLEADO"];
    data["Apellido y nombres"] = fullColaboradorData["APELLIDO Y NOMBRE"];
    data["Frecuencia del descuento"] = getEntryStructure().periodicity;
    data["Número de cuota de referencia"] = 1;
    data["Cuotas pagadas"] = 0;
    data["Valor pagado"] = 0.00;
    data["Cuotas pendientes"] = 0;
    data["Saldo pendiente"] = 0.00;
    
    setNewInstallmentData(data);
    setCreateIsOpen(true);
  }

  const onChangeEntryStructure = (entryStructure) => {
    setSelectedEntryStructureCode(entryStructure);
    setCreateIsOpen(false);
  }

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getAssetDiscountsBasicElementsFromServer("CU"));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])


  useEffect(() => {
    if (selectedEntryStructureCode !== "") {
      dispatch(getAssetsDiscountsEntryByInstallmentsAction(selectedEntryStructureCode))
    }
  }, [dispatch, selectedEntryStructureCode])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  const handleGoBack = () => {
    if (location?.state?.from) {
      history.push(paths.assetsDiscountsSummary);
    } else {
      history.push(paths.assetsAndDiscountsScreenSelector);
    }
  }

  const handleExportStructure = () => {
    if (selectedEntryStructureCode === "") {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una estructura", severity: "warning" }));
      return;
    }

    dispatch(downloadImportStructureAction({ structure: selectedEntryStructureCode, type: "CUO", empty: false }));
  }

  const structureButton = (
    <CustomIconButton
      title={"Exportar estructura de importación"}
      onClick={handleExportStructure}
      type={"download"}
    />
  )

  const goBackButton = (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />
  )

  return (
    <ABMLayout
      title={'Haberes y descuentos - Ingreso en cuotas'}
      isLoading={isLoading}
      showNoData={getDataIsEmpty()}
      noDataTitle={'No se encontraron ingresos en cuotas'}
      noDataCreateTitle={'Nuevo ingreso en cuotas'}
      noDataOnClick={() => setCreateIsOpen(true)}
      customItems={[goBackButton, structureButton]}
      useFullWidth
      useCustomItemsEnd
    >
      <Grid container item alignItems="center" xs={12} style={{ paddingLeft: 5 }}>
        {
          selectedEntryStructureCode !== "" &&
          <Grid container item alignItems="center" justify="flex-end" style={{ padding: "0px 0px" }} xs={12}>
            <Grid container item xs={12} justify="flex-end" >
              <Button
                startIcon={<AddIcon htmlColor={color} />}
                className="whiteText"
                onClick={() => setUserSelectionDialogIsOpen(true)}
                variant='contained'
                style={{
                  backgroundColor: 'var(--secondaryBackgroundColor)',
                  border: '1px solid var(--listAnnotation)',
                  marginRight: 5,
                }}
              >
                {"Colaborador"}
              </Button>
            </Grid>
          </Grid>
        }
        <Grid container item alignItems="center" xs={12}>
          <Grid container item alignItems="center" style={gridStyle} xs={12} md={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel>{"Estructura de ingreso"}</InputLabel>
              <Select
                value={selectedEntryStructureCode}
                onChange={(e) => onChangeEntryStructure(e.target.value)}
                margin={"none"}
              >
                {
                  entryStructures?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.code}
                      disabled={usedEntryStructures.includes(item.code)}
                      value={item.code}>
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" style={gridStyle} xs={12} md={3}>
            <TextField
              disabled
              fullWidth
              margin={"none"}
              label={"Periodicidad"}
              value={(selectedEntryStructureCode && Boolean(columnTypes.length)) ? columnTypes?.find(el => el.key === getEntryStructure()?.periodicity).value : ""}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        quantityPerUsedEntryStructure.length > 0 &&
        <Grid container item xs={12} style={{ margin: 10, marginTop: 0 }}>
          {
            quantityPerUsedEntryStructure.map((el, index) => (
              <NumberItem
                key={index}
                name={el.name}
                value={el.cant}
                selected={el.code === selectedEntryStructureCode}
                onClick={() => onChangeEntryStructure(el.code)}
              />
            ))
          }
        </Grid>
      }
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialog
          open={userSelectionDialogIsOpen}
          users={societyPeople}
          title={"Agregar colaborador"}
          selectedUsersTitle={"Colaborador seleccionado"}
          handleSubmit={(values) => handleSetUsers(values)}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
          singleSelection
        />
      }
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={newInstallmentData}
            closeNew={() => setCreateIsOpen(false)}
            selects={selects}
            selectedEntryStructureCode={selectedEntryStructureCode}
            entryStructures={entryStructures}
            colaborators={colaborators}
            isLoading={isLoading}
          />
        </Grid>
      }
      {
        !isLoading && data?.map((item, i) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={`CU-${i}`}>
              <DataItem
                data={item}
                color={color}
                selects={selects} selectedEntryStructureCode={selectedEntryStructureCode}
                entryStructures={entryStructures}
                colaborators={colaborators}
                isLoading={isLoading}
              />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default AssetsDiscountsEntryByInstallments;