import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
    entryByImportAPI,
} from "@icarius-connection/api";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";

const errorHandler = (e, dispatch) => {
    let errorString = "";
    switch (e.response?.data?.status) {
        case "INVALID_DATA":
        case "VALIDATION_ERROR":
            errorString = getLocalizedString("validationError");
            break;
        default:
            errorString = getErrorStringFromError(e);
            break;
    }
    dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const entryByImportAction = (dataToSend) => async (dispatch) => {
    dispatch({ type: actionTypes.CREATE });
    try {
        let formData = new FormData();
        formData.append('file', dataToSend.file);

        let response = await entryByImportAPI(formData);
        let title = response.headers["x-content-namefile"];
        let hasErrors = title && title.includes("Errores-");
        if (title) {
            title = title.replace("Errores-", "");
        }
        if (response.status !== 200) {
            dispatch({ type: actionTypes.CREATE_REJECTED });
            dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
            return;
        }

        dispatch({
            type: actionTypes.CREATE_FULFILLED,
        });

        if (title) {
            downloadBlob(response.data, getFileExtension(title), getFileName(title));
            dispatch(openSnackbarAction({
                msg: 'Importación finalizada, verifique el archivo para ver el resultado',
                severity: !hasErrors ? "success" : "warning",
                duration: null
            }));
            return response;
        }
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
        errorHandler(e, dispatch);
    }
};