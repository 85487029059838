import React, { useState } from "react";
import { CloseIcon } from "@icarius-icons";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import {
  Dialog, DialogTitle, DialogActions, DialogContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";


const ConfirmPrepaymentDialog = (props) => {
  const [installments, setInstallments] = useState("");

  const {
    handleConfirm,
    open,
    availableInstallments,
    handleClose,
  } = props;

  let installmentsSelect = [];
  for (let i = 1; i <= availableInstallments.length; i++) {
    installmentsSelect.push({ key: i, value: i === 1 ? "1 cuota" : `${i} cuotas` })
  }
  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      fullWidth
      scroll={"paper"}
      maxWidth={"sm"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {`Prepagar préstamo`}
        <DialogTitleDivider />
      </DialogTitle>
      <DialogContent>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="document-select-label">{"Cuotas a prepagar"}</InputLabel>
              <Select
                fullWidth
                value={installments}
                labelId="document-select-label"
                id="document-select"
                onChange={(e) => setInstallments(e.target.value)}
                margin={"none"}
              >
                {
                  installmentsSelect.map(subItem => (
                    <MenuItem
                      className={"whiteText"}
                      key={subItem.key}
                      value={subItem.key}>
                      {subItem.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography className="whiteText" style={{ fontSize: 15, marginTop: 5 }}>
              {'Si no se prepaga el total de cuotas, las cuotas que prepague serán siempre las últimas'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={"Cancelar"} />
        <ButtonDialogAction disabled={!Boolean(installments)} onClick={() => handleConfirm(installments)} isAccept text={"Aceptar"} />
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmPrepaymentDialog;