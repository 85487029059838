import * as actionTypes from "./actionTypes";

const initialState = {
  loadingBasicElements: false,
  loading: false,
  conceptSets: [],
  societyPeople: [],
  colaborators: [],
  dateFormat: "dd/mm/yyyy",
  currencyLocalization: "da",
  informedValueTypeTypes: [],
  columnTypesConceptSet: [],
  rows: [],
  associatedConcepts: [],
  quantityPerUsedConceptSet: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_BASIC_ELEMENTS:
      return { ...state, loadingBasicElements: true };
    case actionTypes.GET_BASIC_ELEMENTS_FULFILLED:
      return {
        ...state,
        loadingBasicElements: false,
        conceptSets: action.payload.conceptSets,
        societyPeople: action.payload.societyPeople,
        colaborators: action.payload.colaborators,
        currencyLocalization: action.payload.currency_localization,
        dateFormat: action.payload.date_format,
        columnTypesConceptSet: action.payload.columnTypesConceptSet,
        informedValueTypeTypes: action.payload.informedValueTypeTypes,
        quantityPerUsedConceptSet: action.payload.quantityPerUsedConceptSet,
      };
    case actionTypes.GET_BASIC_ELEMENTS_REJECTED:
      return { ...state, loadingBasicElements: false };
    case actionTypes.GET_ROWS:
      return { ...state, loading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,
        associatedConcepts: action.payload.associatedConcepts,
        quantityPerUsedConceptSet: action.payload.quantityPerUsedConceptSet,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, loading: false };
    case actionTypes.DELETE_ROWS:
      return { ...state, loading: true };
    case actionTypes.DELETE_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_ROWS_REJECTED:
      return { ...state, loading: false };

      case actionTypes.DOWNLOAD:
      return { ...state, loading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, loading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
