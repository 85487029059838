import React, { useRef, useEffect, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  getAssetDiscountsBasicElementsConceptSetFromServer,
  getAssetDiscountsGivenConceptSetFromServer,
  setAssetDiscountsEntryPerConceptsSetToServer,
  downloadImportStructureAction
} from "../actions";
import {
  getConceptSets,
  getLoading,
  getRows,
  getAssociatedConcepts,
  getSocietyPeople,
  getColaborators,
  getCurrencyLocalization,
  getDateFormat,
  getQuantityPerUsedConceptSet
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "./confirmationDialog";
import { getAppColor, getIsExportingGrid } from "src/app/selectors";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter"
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography
} from "@material-ui/core";
import SecondGridDialog from "./secondGridDialog";
import NumberItem from "./numberItem";
import { AddIcon, FileCopyIcon, VisibilityIcon } from "@icarius-icons/";
import { formatDate } from "@icarius-utils/date";
import { useLocation } from "react-router-dom";
import useAutomaticProcesses from "@icarius-common/automaticProcesses/useAutomaticProcesses";
import ResultGridDialog from "@icarius-common/automaticProcesses/components/resultGridDialog";
import PickTemplateDialog from "@icarius-pages/employeesProcesses/components/dialogs/pickTemplateDialog";
import SelectProcessDialog from "@icarius-common/automaticProcesses/components/selectProcessDialog";
import { openDialogAction } from "@icarius-common/dialog/actions";
import UserSelectionDialogWithReplica from "./userSelectionDialogWithReplica";
import { formatNumberExactDecimals, formatNumberOrReturnUndefined } from "@icarius-utils/format";

const gridStyle = { height: 100, padding: "0px 10px" };

const EntryPerConceptSet = ({ history }) => {

  const location = useLocation();

  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);
  const [selectedConceptSetCode, setSelectedConceptSetCode] = useState(location?.state?.structureKey || "");
  const [dataChanged, setDataChanged] = useState(false);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [gridReference, setGridReference] = useState(null);
  const [secondGridDialogIsOpen, setSecondGridDialogIsOpen] = useState(false);
  const [isAddingRows, setIsAddingRows] = useState(false);
  const [rowDataToReplicate, setRowDataToReplicate] = useState(null);
  const rowData = useSelector(getRows);
  const conceptSets = useSelector(getConceptSets);
  const associatedConcepts = useSelector(getAssociatedConcepts);
  const isLoading = useSelector(getLoading);
  const societyPeople = useSelector(getSocietyPeople);
  const colaborators = useSelector(getColaborators);
  const locale = useSelector(getCurrencyLocalization);
  const dateFormat = useSelector(getDateFormat);
  const quantityPerUsedConceptSet = useSelector(getQuantityPerUsedConceptSet);
  const color = useSelector(getAppColor);
  const isExportinGrid = useSelector(getIsExportingGrid);

  const {
    isExecution,
    automaticProcessesIsLoading,
    automaticProcessesIsEnabled,
    processList,
    resultData,
    resultDialogIsOpen,
    pickTemplateDialogIsOpen,
    processSelectionDialogIsOpen,
    executeProcess,
    handleExecute,
    viewResult,
    handleViewResult,
    handleCloseProcessDialogSelection,
    handleCloseResultDialog,
    handleOpenPickTemplateDialog,
    handleClosePickTemplateDialog,
    handleGenerateDocumentsGivenTemplate,
  } = useAutomaticProcesses();

  const gridRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssetDiscountsBasicElementsConceptSetFromServer());
  }, [dispatch])

  useEffect(() => {
    if (selectedConceptSetCode !== "") {
      dispatch(getAssetDiscountsGivenConceptSetFromServer(selectedConceptSetCode)).then(resp => {
        if (resp.statusText === "OK") {
          setShouldReloadGrid(true);
        }
      });
    }
  }, [dispatch, selectedConceptSetCode, gridReference])

  useEffect(() => {
    if (selectedConceptSetCode !== "" && gridRef) {
      setGridReference(gridRef.current.refs.dataGrid._instance);
    }
  }, [gridRef, selectedConceptSetCode])

  const isZero = value => {
    return value === "0" || value === 0.00 || value === "0:00" || value === "00:00" || value === 0 || parseFloat(value) === 0;
  }

  const handleSave = () => {
    let hasError = "";
    let hasDuplicates = "";
    let ds = gridReference.getDataSource();
    let filter = gridReference.getCombinedFilter();
    gridReference.saveEditData().done(resp => {
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          gridReference.getController("validating").validate(true).then(isValid => {

            if (isValid) {
              let formattedData = rowData.map(row => {
                let modifiedRow = { ...row };
                delete modifiedRow["Fecha de contratación"];
                delete modifiedRow["Fecha de egreso"];


                if (row["En fecha"]) {
                  modifiedRow["En fecha"] = formatDate(row["En fecha"]);
                }

                let valueModified = false;
                for (let [key, value] of Object.entries(modifiedRow)) {
                  if (key !== "Apellido y nombres" && key !== "Código de empleado") {
                    if (key !== "En fecha" && isZero(value)) {
                      let newValue = null;
                      modifiedRow[key] = newValue;
                      continue;
                    }
                    if (key !== "En fecha" && value) {
                      if (key.includes("(hhmm)")) {
                        let newKey = key.replace("(hhmm)", "(hh:mm)");
                        delete modifiedRow[key];
                        modifiedRow[newKey] = value;

                        let arr = value.split(':');
                        let dec = parseInt((arr[1] / 6) * 10, 10);

                        let newValue = parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);

                        modifiedRow["Cantidad"] = formatNumberExactDecimals(newValue, 5);
                        valueModified = true;
                      } else {
                        modifiedRow[key] = value.replace(/\./g, '').replace(/,/g, '.')
                        valueModified = true;
                      }
                    }
                  }
                }
                if (valueModified && row["En fecha"] === undefined) {
                  hasError = "Verifique que las celdas de En fecha esten completas";
                }

                return modifiedRow;
              });


              let arrCheckDuplicatedElements = [];
              formattedData.forEach(data => {
                const duplicated = arrCheckDuplicatedElements.find(el => el.date === data["En fecha"] && el.name === data["Código de empleado"]);
                if (duplicated) {
                  hasDuplicates = `Verifique que no haya más de un registro con la misma fecha para un colaborador (${duplicated.name})`;
                } else {
                  arrCheckDuplicatedElements.push({ name: data["Código de empleado"], date: data["En fecha"] });
                }
              });

              if (hasError) {
                dispatch(openSnackbarAction({ msg: hasError, severity: "error" }));
                return;
              }

              if (hasDuplicates) {
                dispatch(openSnackbarAction({ msg: hasDuplicates, severity: "error" }));
                return;
              }
              try {
                dispatch(setAssetDiscountsEntryPerConceptsSetToServer({ conceptSet: selectedConceptSetCode, items: formattedData }))
                  .then((resp) => {


                    if (resp?.status === 200) {
                      //limpiar el seleccionado
                      gridReference.deselectAll()

                      setShouldReloadGrid(true);

                      setDataChanged(false);
                    }
                  })
              } catch (e) {
                dispatch(openSnackbarAction({ msg: "Ocurrió un error en el servidor", severity: "error" }));

              }
            } else {
              dispatch(openSnackbarAction({ msg: "Verifique que las celdas tengan valores válidos", severity: "error" }));
            }
          })
        });
    })
  };


  const handleOpenDeleteDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Desea eliminar las filas seleccionadas?',
      onConfirm: () => deleteRowsLocally(selectedRows),
    }));
  }

  const deleteRowsLocally = (rowsToDelete) => {
    gridReference.cancelEditData();
    if (Array.isArray(rowsToDelete)) {
      const ds = gridReference.getDataSource();
      const filter = gridReference.getCombinedFilter();
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          rowsToDelete.forEach(row => {
            const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
            filteredRows.forEach(gridRow =>
              gridReference.getDataSource().store().remove(gridRow).then(() => {
                gridReference.refresh();
              })
            )
          });
        });
    }
    dispatch(openSnackbarAction({ msg: 'Valores eliminados con éxito', severity: "success" }));
  }

  const handleGoBack = () => {
    history.push(paths.assetsAndDiscountsScreenSelector);
  }

  const handleCheckGoBack = () => {
    if (dataChanged) {
      setConfirmationDialogIsOpen(true);
    } else {
      if (location?.state?.from) {
        history.push(paths.assetsDiscountsSummary);
      } else {
        handleGoBack();
      }
    }
  }

  const handleExportStructure = () => {
    if (selectedConceptSetCode === "") {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una estructura", severity: "warning" }));
      return;
    }

    dispatch(downloadImportStructureAction({ structure: selectedConceptSetCode, type: "S", empty: false }));
  }

  const handleExportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Haberes y Descuentos');

    exportDataGrid({
      component: gridReference,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.value) {
          const key = gridCell.column.dataField;
          if (gridCell.rowType === 'data') {
            if (key !== "Código de empleado" &&
              key !== "Apellido y nombres" &&
              key !== "En fecha" &&
              !(key.includes("(hhmm)") || key.includes("(hh:mm)")) &&
              gridCell.value !== "" &&
              gridCell.value !== null) {
              excelCell.value = Number(gridCell.value.replace(/\./g, '').replace(/,/g, '.'))
              excelCell.numFmt = '_(#,##0.00_);_((#,##0.00)';
            }
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Hab. y Desc. - Conjunto de conceptos.xlsx');
      });
    });
  }

  const handleValidateAndExecute = () => {
    let selectedRows = gridReference.getSelectedRowsData();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    const employeeCodes = selectedRows.map(item => item["Código de empleado"]);
    handleExecute(employeeCodes);
  }

  const executeItem = () => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={`Calcular${processList.length === 1 ? `: ${processList[0].value}` : ' proceso'}`}
      onClick={handleValidateAndExecute}
      type={"execute"}
    />
  )

  const handleValidateAndViewResult = () => {
    let selectedRows = gridReference.getSelectedRowsData();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
      return;
    }

    handleViewResult(selectedRows[0], selectedRows[0]["Código de empleado"]);
  }

  const viewResultItem = () => (
    automaticProcessesIsEnabled &&
    <CustomIconButton
      title={"Ver resultado"}
      onClick={handleValidateAndViewResult}
    >
      <VisibilityIcon />
    </CustomIconButton>
  )

  const saveItem = () => (
    <CustomIconButton
      title={"Guardar"}
      onClick={handleSave}
      type={"save"}
    />
  )

  const deleteItem = () => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={handleOpenDeleteDialog}
      type={"delete"}
    />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleCheckGoBack}
      type={"goBack"}
    />
  )

  const exportGrid = () => (
    <CustomIconButton
      title={"Exportar datos"}
      onClick={handleExportGrid}
      type={"excel"}
    />
  )

  const structureButton = () => (
    <CustomIconButton
      title={"Exportar estructura de importación"}
      onClick={handleExportStructure}
      type={"download"}
    />
  )

  const formattedData = rowData.map(originalRow => {
    let row = { ...originalRow };

    for (let [key, value] of Object.entries(row)) {
      if (key !== "Código de empleado" && key !== "Apellido y nombres" && key !== "En fecha" && value !== null) {
        if (!key.includes("(hh:mm)")) {
          if (value === null) {
            row[key] = null;
          } else {
            if (key.includes("(Cantidad)")) {
              row[key] = formatNumberOrReturnUndefined(value, 0, 5)
            } else {
              row[key] = formatNumberOrReturnUndefined(value, 0, 2)
            }
          }
        } else {
          delete row[key];
          let newKey = key.replace("(hh:mm)", "(hhmm)");
          row[newKey] = value;
        }
      }
    }

    return row;
  })
  const getColaboratorData = employeeCode => { return colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === employeeCode) }

  const generateColumns = () => {
    // Primero, tengo las columnas fijas
    let columns = [];
    columns.push({
      headerName: "Código de empleado",
      field: "Código de empleado",
      isObligatory: true,
      disabled: true,
      maxLength: 254,
      minLength: 0,
      isFixed: true,
    });

    columns.push({
      headerName: "Apellido y nombres",
      field: "Apellido y nombres",
      isObligatory: true,
      maxLength: 254,
      minLength: 0,
      isFixed: true,
      disabled: true
    });

    columns.push({
      headerName: "En fecha",
      field: "En fecha",
      cellEditor: "dateEditor",
      filter: "agTextColumnFilter",
      isDate: true,
      dataType: "date",
      isObligatory: true,
    });

    associatedConcepts.forEach(concept => {
      const dataObject = {
        headerName: concept.value,
        field: concept.value.includes("hh:mm") ? concept.value.replace(":", "") : concept.value,
        maxLength: 254,
        dataType: "string",
      };

      if (concept.typeValue === "V" || concept.typeValue === "B" || concept.typeValue === "P" || concept.typeValue === "C") {
        dataObject.isNumeric = true;
        dataObject.validationUnrequired = true;

        if (concept.typeValue !== "C") {
          dataObject.isValueEntryPerConceptSet = true;
        } else {
          dataObject.isQuantityEntryPerConceptSet = true;
        }

        if (concept.typeValue === "P") {
          dataObject.isPercentageEntryPerConceptSet = true;
        }
      }
      columns.push(dataObject);
    })

    return columns;
  }

  const handleSetUsers = (values, replicate) => {
    setIsAddingRows(true);

    values.forEach((colab, index) => {
      const fullColaboradorData = colaborators.find(colaborator => colaborator["CODIGO DE EMPLEADO"] === colab);

      const dataSource = gridReference.getDataSource();
      let data = {};
      if (replicate) {
        data = { ...rowDataToReplicate[0] }
      }

      data["Código de empleado"] = fullColaboradorData["CODIGO DE EMPLEADO"];
      data["Apellido y nombres"] = fullColaboradorData["APELLIDO Y NOMBRE"];

      dataSource.store().insert(data).then(function () {
        dataSource.reload();
        if (index + 1 === values.length) {
          setTimeout(() => setIsAddingRows(false), 100);
        }
      })
    });
  }

  const onChangeConceptSet = (conceptSet) => {
    setSelectedConceptSetCode(conceptSet)
    setRowDataToReplicate(null)
  }

  let customItems = [goBackButton];

  if (selectedConceptSetCode !== "") {
    customItems = [...customItems, saveItem, structureButton, exportGrid, executeItem, viewResultItem, deleteItem]
  }

  const usedconceptSets = quantityPerUsedConceptSet.map(structure => structure.code);

  const copyDataToReplicate = () => {
    if (rowDataToReplicate) {
      setRowDataToReplicate(null);
    } else {
      // Me fijo cuantas filas seleccionadas tengo
      let selectedRows = gridReference.getSelectedRowsData();

      // Si no hay seleccionado, pido seleccionar
      if (selectedRows.length === 0) {
        dispatch(openSnackbarAction({ msg: "Debe seleccionar una fila", severity: "warning" }));
        return;
      } else if (selectedRows.length > 1) {
        dispatch(openSnackbarAction({ msg: "Debe seleccionar una única fila", severity: "warning" }));
        return;
      }
      gridReference.saveEditData().done(resp => setRowDataToReplicate(selectedRows))

    }
  }

  return (
    <CommonPage
      title={'Haberes y descuentos - Por conjunto de conceptos'}
      menuItems={customItems}
      isLoading={isLoading || automaticProcessesIsLoading || isAddingRows}
      isNotGridPage
    >
      <Grid container item alignItems="center" xs={12} style={{ paddingLeft: 5 }}>
        {
          selectedConceptSetCode !== "" &&
          <Grid container item alignItems="center" justify="flex-end" style={{ padding: "0px 10px" }} xs={12}>
            <Grid container item xs={12} justify="flex-end" >
              <Button
                startIcon={<FileCopyIcon htmlColor={rowDataToReplicate ? "#FFFFFF" : color} />}
                className="whiteText"
                onClick={copyDataToReplicate}
                variant='contained'
                style={{
                  marginRight: 10,
                  backgroundColor: rowDataToReplicate ? 'red' : 'var(--secondaryBackgroundColor)',
                  border: '1px solid var(--listAnnotation)'
                }}
              >
                {rowDataToReplicate ? "Limpiar los datos copiados" : "Copiar datos para replicar"}
              </Button>
              <Button
                startIcon={<AddIcon htmlColor={color} />}
                className="whiteText"
                onClick={() => setUserSelectionDialogIsOpen(true)}
                variant='contained'
                style={{
                  marginRight: 10,
                  backgroundColor: 'var(--secondaryBackgroundColor)',
                  border: '1px solid var(--listAnnotation)',
                }}
              >
                {"Colaborador"}
              </Button>
              <Button
                startIcon={<AddIcon htmlColor={color} />}
                className="whiteText"
                onClick={() => setSecondGridDialogIsOpen(true)}
                variant='contained'
                style={{
                  marginRight: 20,
                  backgroundColor: 'var(--secondaryBackgroundColor)',
                  border: '1px solid var(--listAnnotation)'
                }}
              >
                {"Lista de colaboradores"}
              </Button>
            </Grid>
          </Grid>
        }
        <Grid container item alignItems="center" xs={12}>
          <Grid container item alignItems="center" style={gridStyle} xs={3} >
            <FormControl style={{ width: "100%" }}>
              <InputLabel>{"Conjunto de conceptos"}</InputLabel>
              <Select
                value={selectedConceptSetCode}
                onChange={(e) => onChangeConceptSet(e.target.value)}
                margin={"none"}
              >
                {
                  conceptSets && conceptSets.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.code}
                      disabled={usedconceptSets.includes(item.code)}
                      value={item.code}>
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" style={gridStyle} xs={3}>
            <TextField
              disabled
              fullWidth
              margin={"none"}
              label={"Periodicidad"}
              value={selectedConceptSetCode ? "En fecha específica" : ""}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        quantityPerUsedConceptSet.length > 0 &&
        <Grid container item xs={12} style={{ margin: 10, marginTop: 0 }}>
          {
            quantityPerUsedConceptSet.map((el, index) => (
              <NumberItem
                key={index}
                name={el.name}
                value={el.cant}
                selected={el.code === selectedConceptSetCode}
                onClick={() => onChangeConceptSet(el.code)}
              />
            ))
          }
        </Grid>}
      {
        selectedConceptSetCode !== "" &&
        <EditableGridv2
          setShouldReloadGrid={setShouldReloadGrid}
          shouldReloadGrid={shouldReloadGrid}
          setDataChanged={setDataChanged}
          ref={gridRef}
          gridName="entryPerConceptSet"
          rowData={formattedData}
          columns={generateColumns()}
          getColaboratorData={getColaboratorData}
          batchMode
        />
      }
      {
        selectedConceptSetCode === "" &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
            <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
              {"Seleccione un conjunto de conceptos"}
            </Typography>
          </Grid>
        </Grid>
      }
      {
        confirmationDialogIsOpen &&
        <ConfirmationDialog
          open={confirmationDialogIsOpen}
          handleConfirm={handleGoBack}
          handleClose={() => setConfirmationDialogIsOpen(false)}
        />
      }
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialogWithReplica
          open={userSelectionDialogIsOpen}
          users={societyPeople}
          title={"Agregar colaborador"}
          selectedUsersTitle={"Colaboradores seleccionados"}
          isDataReplicateDisabled={rowDataToReplicate === null}
          handleSubmit={(values, replicate) => handleSetUsers(values, replicate)}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
        />
      }
      {
        secondGridDialogIsOpen && !isLoading &&
        <SecondGridDialog
          open={secondGridDialogIsOpen}
          data={colaborators}
          dateFormat={dateFormat}
          locale={locale}
          isDataReplicateDisabled={rowDataToReplicate === null}
          handleConfirm={handleSetUsers}
          handleClose={() => setSecondGridDialogIsOpen(false)}
        />
      }
      {
        processSelectionDialogIsOpen &&
        <SelectProcessDialog
          open={processSelectionDialogIsOpen}
          title={isExecution ? "Selección de proceso a ejecutar" : "Selección de proceso a consultar"}
          processList={processList}
          handleClose={handleCloseProcessDialogSelection}
          handleExecute={isExecution ? executeProcess : viewResult}
        />
      }
      {
        resultDialogIsOpen && !(isLoading || automaticProcessesIsLoading) && !isExportinGrid &&
        <ResultGridDialog
          open={resultDialogIsOpen}
          templates={resultData.templates}
          process={resultData.process}
          employeeName={resultData.employee['Apellido y nombres']}
          handleClose={handleCloseResultDialog}
          handleOpenPickTemplateDialog={handleOpenPickTemplateDialog}
          handleGenerateDocumentsGivenTemplate={handleGenerateDocumentsGivenTemplate}
        />
      }
      {
        resultData?.templates?.length > 0 && pickTemplateDialogIsOpen && !(isLoading || automaticProcessesIsLoading) && !isExportinGrid &&
        <PickTemplateDialog
          open={pickTemplateDialogIsOpen}
          templates={resultData.templates}
          handleConfirm={handleGenerateDocumentsGivenTemplate}
          handleClose={handleClosePickTemplateDialog}
        />
      }
    </CommonPage>
  );
}

export default EntryPerConceptSet;
