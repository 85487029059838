import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const DELETE = NAME + "/DELETE";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";