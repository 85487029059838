import * as actionTypes from "./actionTypes";

const initialState = {
  loadingBasicElements: false,
  loading: false,
  entryStructures: [],
  societyPeople: [],
  colaborators: [],
  dateFormat: "dd/mm/yyyy",
  currencyLocalization: "da",
  informedValueTypeTypes: [],
  columnTypes: [],
  rows: [],
  selects: {},
  concepts: [],
  conceptsTypes: [],
  quantityPerUsedEntryStructure: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;
    case actionTypes.GET_BASIC_ELEMENTS:
      return { ...state, loadingBasicElements: true };
    case actionTypes.GET_BASIC_ELEMENTS_FULFILLED:
      return {
        ...state,
        loadingBasicElements: false,
        entryStructures: action.payload.entryStructures,
        currencyLocalization: action.payload.currency_localization,
        dateFormat: action.payload.date_format,
        columnTypes: action.payload.columnTypes,
        informedValueTypeTypes: action.payload.informedValueTypeTypes,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
        decimals: action.payload.decimals,
      };
    case actionTypes.GET_BASIC_ELEMENTS_REJECTED:
      return { ...state, loadingBasicElements: false };

    case actionTypes.GET:
      return { ...state, loading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,
        concepts: action.payload.concepts,
        conceptsTypes: action.payload.conceptsTypes,
        selects: action.payload.selects,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, loading: false };

    case actionTypes.GET_MYPEOPLE_GRID:
      return { ...state, loading: true };
    case actionTypes.GET_MYPEOPLE_GRID_FULFILLED:
      return {
        ...state,
        loading: false,
        colaborators: action.payload.colaborators,
        societyPeople: action.payload.colaborators.map(el => ({ key: el["CODIGO DE EMPLEADO"], name: el["APELLIDO Y NOMBRE"], "HORAS DIARIAS CONTRATO": el["HORAS DIARIAS CONTRATO"], FOTOGRAFIA: el["FOTOGRAFIA"] }))
      };
    case actionTypes.GET_MYPEOPLE_GRID_REJECTED:
      return { ...state, loading: false };

    case actionTypes.CREATE:
      return { ...state, loading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, loading: false };

    case actionTypes.MODIFY:
      return { ...state, loading: true };
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, loading: false };

    case actionTypes.DELETE:
      return { ...state, loading: true };
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };
    case actionTypes.DELETE_REJECTED:
      return { ...state, loading: false };

    case actionTypes.DOWNLOAD:
      return { ...state, loading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, loading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, loading: false };


    case actionTypes.DUPLICATE:
      return { ...state, loading: true };
    case actionTypes.DUPLICATE_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,
        quantityPerUsedEntryStructure: action.payload.quantityPerUsedEntryStructure,
      };
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
